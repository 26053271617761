import Api from "views/Api/Api.js";
import Settings from "views/Settings/Settings.js";
import Databases from "views/Databases//Databases.js";
import Functions from "views/Functions/Functions.js";

import Store from "views/Store/Store.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import CliLogin from "views/Dashboard/CliLogin.js";

import PricingPage from "views/Pages/PricingPage.js";
import AgentsPage from "views/Pages/AgentsPage.js";
import ConsolePage from "views/Pages/ConsolePage.js";
import ReactTables from "views/Tables/ReactTables.js";
import BillingTables from "views/Tables/BillingTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularTables from "views/Tables/RegularTables.js";
import DeliveryPage from "views/Pages/DeliveryPage.js";
// import TeamsPage from "views/Pages/TeamsPage.js";
import TripsPage from "views/Pages/TripsPage.js";
import BirdPage from "views/Pages/BirdPage.js";
import PixiePage from "views/Pages/PixiePage.js";
import DoodlePage  from "views/Pages/DoodlePage.js";
import ChipPage from "views/Pages/ChipPage.js";
import CreateAgentPage from "views/Pages/CreateAgentPage.js";
import Unsubscribe from "views/Pages/Unsubscribe.js";

import AutoGPTPage from "views/Pages/AutoGPTPage.js";


// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PeopleAlt from "@material-ui/icons/PeopleAlt"
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AndroidIcon from '@material-ui/icons/Android';

import DataUsageIcon from '@material-ui/icons/DataUsage';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import CodeIcon from "@material-ui/icons/Code";
import SettingsIcon from '@material-ui/icons/Settings';
import CloudIcon from '@material-ui/icons/Cloud';
import StorageIcon from '@material-ui/icons/Storage';
import BusinessIcon from '@material-ui/icons/Business';
import BuildIcon from '@material-ui/icons/Build';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  // {
  //   path: "/track",
  //   name: "Track",
  //   icon: LocationOnIcon,
  //   component: Store,
  //   layout: "/admin",
  // },
  {
    path: "/agents",
    name: "Agents",
    icon: AndroidIcon,
    component: AgentsPage,
    layout: "/admin",
  },
  {
    path: "/console",
    name: "Console",
    icon: CodeIcon,
    component: ConsolePage,
    layout: "/admin",
  },
  {
    path: "/bird",
    name: "Bird",
    component: BirdPage,
    layout: "/admin",
  },
  {
    path: "/pixie",
    name: "Pixie",
    component: PixiePage,
    layout: "/admin",
  },
  {
    path: "/doodle",
    name: "Doodle",
    component: DoodlePage,
    layout: "/admin",
  },
  {
    path: "/chip",
    name: "Chip",
    component: ChipPage,
    layout: "/admin",
  },
  {
    path: "/autogptcloud",
    name: "Auto GPT Cloud",
    component: AutoGPTPage,
    layout: "/admin",
  },
  // {
  //   collapse: true,
  //   name: "Track2",
  //   icon: LocationOnIcon,
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/deliveries",
  //       name: "Deliveries",
  //       mini: "Del",
  //       component: ReactTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/servicetrips",
  //       name: "Service Trips",
  //       mini: "Ser",
  //       component: ReactTables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/fieldtracking",
  //       name: "Field Tracking",
  //       mini: "FT",
  //       component: ReactTables,
  //       layout: "/admin",
  //     }
  //   ],
  // },

  // {
  //   path: "/alldeliveries",
  //   name: "All Deliveries",
  //   icon: FormatListBulletedIcon,
  //   component: ReactTables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/deliveryteam",
  //   name: "Delivery Team",
  //   icon: PeopleAlt,
  //   component: RegularTables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/database",
  //   name: "Storage & Compute",
  //   icon: StorageIcon,
  //   component: Databases,
  //   layout: "/admin",
  // },
  // {
  //   path: "/functions",
  //   name: "Functions",
  //   icon: BusinessIcon,
  //   component: Functions,
  //   layout: "/admin",
  // },
  {
    path: "/agentbuilder",
    name: "Create AI Agent",
    icon: BuildIcon,
    component: CreateAgentPage,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Billing & Settings",
    icon: SettingsIcon,
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/autogptconnect",
    name: "Connect Auto GPT",
    icon: SettingsIcon,
    component: Settings,
    layout: "/admin",
  },
  // {
  //   path: "/api",
  //   name: "Api & SDK",
  //   icon: CodeIcon,
  //   component: Api,
  //   layout: "/admin",
  // },
 


  // {
  //   path: "/store",
  //   name: "Storefront (β)",
  //   icon: StorefrontIcon,
  //   component: Store,
  //   layout: "/admin",
  // },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   icon: AttachMoneyIcon,
  //   component: BillingTables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/pricing",
  //   name: "Payment & Plans",
  //   icon: DataUsageIcon,
  //   component: PricingPage,
  //   layout: "/admin",
  // },
  {
    path: "/login-page",
    name: "Login Page",
    component: RegisterPage,
    layout: "/auth",
  },
  {
    path: "/clilogin",
    name: "Cli Login",
    component: CliLogin,
    layout: "/auth",
  },
  // {
  //   path: "/unsubscribe",
  //   name: "Unsubscribe",
  //   component: Unsubscribe,
  // }
];
export default dashRoutes;
