import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Heading from "components/Heading/Heading.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Firebase from "firebase";
import Table from "components/Table/Table.js";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HttpIcon from '@material-ui/icons/Http';
import CreateIcon from '@material-ui/icons/Create';
import {sampleProducts, laundryServices, sampleMenuItems} from './SampleProducts'
import { Business } from '@material-ui/icons/Business';
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import QRCode from "react-qr-code";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import MenuImageUpload from "components/CustomUpload/MenuImageUpload.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import DescriptionIcon from '@material-ui/icons/Description';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Tasks from "components/Tasks/Tasks.js";
import { widgetStories, bugs, website, server } from "variables/general.js";
import moment from "moment";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Switch from "@material-ui/core/Switch";
import Badge from "components/Badge/Badge.js";
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import LaunchIcon from '@material-ui/icons/Launch';
import Accordion from "components/Accordion/Accordion.js";

import CheckIcon from '@material-ui/icons/Check';
const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
};
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles(styles);
import base from "assets/img/storeimages/default.jpg";
import bags from "assets/img/storeimages/bags.jpeg";
import categories from "assets/img/storeimages/categories.jpeg";
import clothes from "assets/img/storeimages/clothes.jpeg";
import fashion from "assets/img/storeimages/fashion.jpeg";
import furniture from "assets/img/storeimages/furniture.jpeg";
import kitchen from "assets/img/storeimages/kitchen.jpeg";
import shoes from "assets/img/storeimages/shoes.jpeg";
import toys from "assets/img/storeimages/toys.jpeg";
import grocery from "assets/img/storeimages/grocery.jpg";
import restaurant from "assets/img/storeimages/restaurant.jpg";
import laundry from "assets/img/storeimages/laundry.jpg";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';

export default function Store() {
  const classes = useStyles();
  const [currentUserData, setCurrentUserData] = React.useState();
  const [storeName, setStoreName] = React.useState();
  const [storePath, setStorePath] = React.useState();
  const [storePhone, setStorePhone] = React.useState();
  const [selectedBizType, setSelectedBizType] = React.useState();
  const [branches, setBranches] = React.useState(['b1', 'b2', 'b3']);
  const [selectedTemplate, setSelectedTemplate] = React.useState();
  const [paymentPhoneNumber, setPaymentPhoneNumber] = React.useState();
  const [topCustomers, setTopCustomers] = React.useState();
  const [topLocations, setTopLocations] = React.useState();
  const [isStoreNameAvailable, setIsStoreNameAvailable] = React.useState();
  const [showProgress, setShowProgress] = React.useState();
  
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  React.useEffect(() => {
     const onChildAdd = Firebase.database()
       .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
       .on('value', snapshot => {
         const data = snapshot.val()
         if (data) {
           setCurrentUserData(data);
          //  setAutoScheduleEnabled(data.autoscheduleenabled)
         }
       });
     return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
   }, [])

  
  const [productList, setProductList] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  const [baseOrderList, setBaseOrderList] = React.useState([]);
  

  const [tableOrderList, setTableOrderList] = React.useState([]);
  const [baseTableOrderList, setBaseTableOrderList] = React.useState([]);
  const [onroadDeliveryOrderList, setOnroadDeliveryOrderList] = React.useState([]);
  const [deliveryPeopleList, setDeliveryPeopleList] = React.useState([]);
  const [offerRolloutList, setOfferRolloutList] = React.useState([]);

  React.useEffect(() => {
    if(orderList.length> 0 && orderList.find(k => k[4].includes("schedulebutton:orderid"))){
      setBR(true);
      setTimeout(function () {
        setBR(false);
      }, 6000);
    }
    
  },[orderList])

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webdeliveries/storeadded/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if(data){
          let customers = []
          let locations = []
          let finalArray = []
          let finalArray2 = []

          const keys = Object.keys(data);
          for (let index = keys.length-1; index > -1; index--) {
            const element = data[keys[index]];
            if (locations[element.addresslocation]){
              locations[element.addresslocation] = {
                count: locations[element.addresslocation].count + 1,
                total: keys.length
              }
            }else{
              locations[element.addresslocation] = {count: 1, total: keys.length}
            }

            if (customers[element.customerphone]){
              customers[element.customerphone] = {
                count: customers[element.customerphone].count + 1,
                total: keys.length
              }
            }else{
              customers[element.customerphone] = {count: 1, total: keys.length}
            }
          }
          const uniqLocations = Object.keys(locations);
          for (let index = 0; index < uniqLocations.length; index++) {
            const element = uniqLocations[index];
            if(locations[element].count > 2){
              finalArray.push([element, locations[element].count, `${parseInt((100) * locations[element].count / locations[element].total)}%`]);
            }
          }
          setTopLocations(finalArray);  

          const uniqCustomers = Object.keys(customers);
          for (let index = 0; index < uniqCustomers.length; index++) {
            const element = uniqCustomers[index];
            if(customers[element].count > 2){
            finalArray2.push([element, customers[element].count, `${parseInt((100) * customers[element].count / customers[element].total)}%`]);
            }
          }
          setTopCustomers(finalArray2);      
        }
      });
    return () => Firebase.database().ref(`/webdeliveries/storeadded/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g,",")}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if(data){
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length-1; index > -1; index--) {
            const element = data[keys[index]];
            result.push(element)
          }
          setDeliveryPeopleList(result)
        }

    });
    return () => Firebase.database().ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
    if(!currentUserData){
      return 
    }
    if((selectedBizType || currentUserData?.businesstype) == 'Store' || (selectedBizType || currentUserData?.businesstype) == 'Restaurant'){
      const onChildAdd = Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/offers/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data[keys[index]] && data[keys[index]].name && data[keys[index]].description){
              const element = Object.assign(data[keys[index]], {id: keys[index]})
              result.push([element.name, element.description, element.date])
            }
          }
          setOfferRolloutList(result)
        }
      });
    return () => Firebase.database().ref(`/ecommerce/${currentUserData.apikey}/offers/`).off('value', onChildAdd)
    }
    if((selectedBizType || currentUserData?.businesstype) == 'Laundry'){
      const onChildAdd = Firebase.database()
      .ref(`/serviceofferings/${currentUserData.apikey}/offers/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data[keys[index]] && data[keys[index]].name && data[keys[index]].description){
              result.push([element.name, element.description, element.date])
            }
          }
          setOfferRolloutList(result)
        }
      });

    return () => Firebase.database().ref(`/serviceofferings/${currentUserData.apikey}/offers/`).off('value', onChildAdd);
    }
    
  }, [currentUserData])

  React.useEffect(() => {
    if(!currentUserData){
      return 
    }
    if((selectedBizType || currentUserData?.businesstype) == 'Store' || (selectedBizType || currentUserData?.businesstype) == 'Restaurant'){
      const onChildAdd = Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/products/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data[keys[index]] && data[keys[index]].name && data[keys[index]].price){
              const element = Object.assign(data[keys[index]], {id: keys[index]})
              result.push([`input:${keys[index]}|name-${element.name}-|-sku:${keys[index]}`, `input:${keys[index]}|price-${element.price}`, `stockonoff:${element.stock}:productid:${keys[index]}:storekey:${currentUserData.apikey}`, `removetheproduct:${keys[index]}-|-${currentUserData.apikey}`])
            }
          }
          setProductList(result)
        }
      });
    return () => Firebase.database().ref(`/ecommerce/${currentUserData.apikey}/products/`).off('value', onChildAdd)
    }
    if((selectedBizType || currentUserData?.businesstype) == 'Laundry'){
      const onChildAdd = Firebase.database()
      .ref(`/serviceofferings/${currentUserData.apikey}/services/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data[keys[index]] && data[keys[index]].name && data[keys[index]].price){
              const element = Object.assign(data[keys[index]], {id: keys[index]})
              result.push([`input:${keys[index]}|name-${element.name}`, `input:${keys[index]}|price-${element.description}`, `removetheservice:${keys[index]}-|-${currentUserData.apikey}`])
            }
          }
          setProductList(result)
        }
      });

    return () => Firebase.database().ref(`/serviceofferings/${currentUserData.apikey}/services/`).off('value', onChildAdd);
    }
    
  }, [currentUserData])


  React.useEffect(() => {
    if(!currentUserData || (currentUserData && !currentUserData.onroaddelivery)){
      return 
    }
    //Store
    if(((selectedBizType || currentUserData?.businesstype) == 'Laundry')){
      const onChildAdd = Firebase.database()
      .ref(`/storeinstantlaundry/orders/${currentUserData.apikey}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data[keys[index]] && data[keys[index]].name && data[keys[index]].address && data[keys[index]].service){
              const element = Object.assign(data[keys[index]], {id: keys[index]})
              let doneButton = ""
              let timeString = ""
              if(element.done){
                doneButton = "storeinstantlaundrybutton:done"
                timeString = `starttime:${element.time}:endtime:${element.finishedtime}`
              }else{
                doneButton = `storeinstantlaundrybutton:${keys[index]}:apikey:${currentUserData.apikey}`   //${currentUserData.apikey}`
                timeString = `starttime:${element.time}`
              }
              result.push([timeString, element.name, element.phone, element.address, element.service, doneButton])
            }
          }
          setOnroadDeliveryOrderList(result)
        }
      });
    return () => Firebase.database().ref(`/storeinstantlaundry/orders/${currentUserData.apikey}/`).off('value', onChildAdd);
    }
  }, [currentUserData && currentUserData.onroaddelivery])


  React.useEffect(() => {
    if(!currentUserData){
      return 
    }
    //Store
    if(((selectedBizType || currentUserData?.businesstype) == 'Restaurant') && ((menuOnScan || currentUserData?.menuonscan) == true)  && (currentUserData?.tablecount > 0 && currentUserData?.tablecount < 100)){
      const onChildAdd = Firebase.database()
      .ref(`/tableorders/${currentUserData.apikey}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);

          for (let index = keys.length - 1; index > -1; index--) {
            if(data[keys[index]] && data[keys[index]].table && data[keys[index]].cart && data[keys[index]].time){
              const element = Object.assign(data[keys[index]], {id: keys[index]})
              const cart = data[keys[index]].cart
              let products = []
              for (const [key, value] of Object.entries(cart)) {
                if(value){
                  products.push(`${value.split("name:")[1].split("||id:")[0]}: ${value.split("||quantity:")[1].split("||totalCost:")[0]}`)
                }
              }
              const link = `https://table.onroad.app/${currentUserData.storepath}?orderid=${keys[index]}&table=${data[keys[index]].table}`
              let doneButton = ""
              let timeString = ""
              if(element.done){
                doneButton = "tableservicebutton:done"
                timeString = `starttime:${element.time}:endtime:${element.finishedtime}`
              }else{
                doneButton = `tableservicebutton:${keys[index]}:apikey:${currentUserData.apikey}`
                timeString = `starttime:${element.time}`
              }
              // with the link
              // result.push([`table:${element.table}${element.name ? `:name:${element.name}` : ""}`, timeString, `${products.join("<>")}-|-${link}`, doneButton])

              //without link
              result.push([`table:${element.table}${element.name ? `:name:${element.name}` : ""}`, timeString, `${products.join("<>")}-|-${link}`, doneButton])
            }
          }
          setBaseTableOrderList(result)
          setTableOrderList(result)
        }
      });
    return () => Firebase.database().ref(`/tableorders/${currentUserData.apikey}/`).off('value', onChildAdd);
    }
  }, [currentUserData])

  React.useEffect(() => {
    if(!currentUserData){
      return 
    }
    //Store
    if(((selectedBizType || currentUserData?.businesstype) == 'Store' || (selectedBizType || currentUserData?.businesstype) == 'Restaurant') && deliveryPeopleList.length > 0){
      const onChildAdd = Firebase.database()
      .ref(`/storeorders/${currentUserData.apikey}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);

          for (let index = keys.length - 1; index > -1; index--) {
            if(data[keys[index]] && data[keys[index]].name && data[keys[index]].phone && data[keys[index]].payment && data[keys[index]].address){
              const element = Object.assign(data[keys[index]], {id: keys[index]})
              const cart = data[keys[index]].cart
              let products = []
              for (const [key, value] of Object.entries(cart)) {
                if(value){
                  products.push(`${value.split("name:")[1].split("||id:")[0]}: ${value.split("||quantity:")[1].split("||totalCost:")[0]}`)
                }
              }
             // "name:Odonil  2 nos||id:-N-5H6_ziZHsI10_yEW_||price:100||quantity:2||totalCost:200||productimage:undefined"
              const link = `https://order.onroad.app/${currentUserData.storepath}?orderid=${keys[index]}`
              let scheduleButton = ""
              if(element.scheduled){
                scheduleButton = "schedulebutton:done"
              }else{
                  const deliveryperson = deliveryPeopleList[index % deliveryPeopleList.length]
                  scheduleButton = `schedulebutton:${keys[index]}:deliverypersonphone:${deliveryperson.phone}:deliverypersonname:${deliveryperson.name}:useremail:${user.email}:apikey:${currentUserData.apikey}:storetype:${(selectedBizType || currentUserData?.businesstype)}:distance:${element.distance || "Not Setup"}`
                  if(element.payment == 'pickup'){
                    scheduleButton = "schedulebutton:notrequired"
                  }
              }
              result.push([element.name, element.phone, `orderaddress:${element.address }:distance:${element.distance || "Not Setup"}${element.branchname ? `:branchname:${element.branchname}`: ""},deliverymethod ${element.payment}`, `${products.join("<>")}-|-${link}`, scheduleButton])
            }
          }
          setBaseOrderList(result)
          setOrderList(result)
        }
      });
    return () => Firebase.database().ref(`/storeorders/${currentUserData.apikey}/`).off('value', onChildAdd);
    }
    
    //Laundry
    if((selectedBizType || currentUserData?.businesstype) == 'Laundry' && deliveryPeopleList.length > 0){
      const onChildAdd = Firebase.database()
      .ref(`/laundryorders/${currentUserData.apikey}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);

          for (let index = keys.length - 1; index > -1; index--) {
            if(data[keys[index]] && data[keys[index]].name && data[keys[index]].phone && data[keys[index]].payment && data[keys[index]].address){
              const element = Object.assign(data[keys[index]], {id: keys[index]})
              const cart = data[keys[index]].cart
              let products = []
              for (const [key, value] of Object.entries(cart)) {
                if(value){
                  products.push(value)
                }
              }
             // "name:Odonil  2 nos||id:-N-5H6_ziZHsI10_yEW_||price:100||quantity:2||totalCost:200||productimage:undefined"
              let scheduleButton = ""
              if(element.scheduled){
                scheduleButton = "schedulebutton:done"
              }else{
                
                  const deliveryperson = deliveryPeopleList[index % deliveryPeopleList.length]
                  scheduleButton = `schedulebutton:${keys[index]}:deliverypersonphone:${deliveryperson.phone}:deliverypersonname:${deliveryperson.name}:useremail:${user.email}:apikey:${currentUserData.apikey}:storetype:${(selectedBizType || currentUserData?.businesstype)}:distance:${element.distance || "Not Setup"}`
              }
              result.push([element.name,element.phone, element.address, products.join(","), scheduleButton])
            }
          }
          setBaseOrderList(result)
          setOrderList(result)
        }
      });
    return () => Firebase.database().ref(`/laundryorders/${currentUserData.apikey}/`).off('value', onChildAdd);
    }


    
  }, [currentUserData, deliveryPeopleList])

  const storeNameChange = (e) => {
    if(e.target.value){
      // setShowProgress(true)
  
      Firebase.database()
      .ref(`/pathtoid/${e.target.value.replace(/\s/g , "-")}/`)
      .once('value', snapshot => {
        const data = snapshot.val()
        // setShowProgress(false)
        if(data){
          setIsStoreNameAvailable(false);
          setStoreName(null);
          setStorePath(null);
        }else{
          setIsStoreNameAvailable(true);
          setStoreName(e.target.value);
          setStorePath(e.target.value.replace(/\s/g , "-"));
        }
      })
    } 
  }

  const storePhoneChange = (e) => {
    // setStorePhone(`${currentUserData.countrycode}${e.target.value}`);
    setStorePhone(e.target.value)
  }

  const storeTableCountChange = (e) => {
    // setStorePhone(`${currentUserData.countrycode}${e.target.value}`);
    setTableCount(e.target.value)
    Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        snapshot.ref.update({
          tablecount: e.target.value
        })
      })
  }

  const handleChangeEnabled = () => {
    //console.log("===changed===")
  }

  const handleBizType = (val) => {
    setSelectedBizType(val);
    Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        snapshot.ref.update({
          businesstype: val,
          selectedtemplate: 'default',
          storename: storeName,
          storepath: storePath,
          storephone: `${currentUserData.countrycode}${storePhone}`
        })
      })
    Firebase.database()
    .ref(`/pathtoid/${storePath}/`)
    .set(currentUserData.apikey)
    if(val == 'Store'){
      sampleProducts.forEach(item => {
        Firebase.database()
        .ref(`/ecommerce/${currentUserData.apikey}/products/`).push({name: item.name, price: item.price, stock: item.stock, discount: item.discount, image: item.image})
      })
      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/people/`).push({name: storeName, phone: storePhone})
      
      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/storelocation/`).set(
        {addresslatlng: currentUserData.storeaddresslatlng,
         countrycode: currentUserData.countrycode,
         paymentmethod: currentUserData.countrycode == "+91" ? "Phonepay" : "Venmo"
       })
    }
    if(val == 'Restaurant'){
      sampleMenuItems.forEach(item => {
        Firebase.database()
        .ref(`/ecommerce/${currentUserData.apikey}/products/`).push({name: item.name, price: item.price, stock: item.stock, discount: item.discount, image: item.image})
      })
      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/people/`).push({name: storeName, phone: storePhone})

      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/storelocation/`).set(
        {addresslatlng: currentUserData.storeaddresslatlng || {lat: 37, lng: 95},
         countrycode: currentUserData.countrycode || "+1",
         paymentmethod: currentUserData.countrycode == "+91" ? "Phonepay" : "Venmo"
       })

    }
    if(val == "Laundry"){
      Firebase.database()
      .ref(`/serviceofferings/${currentUserData.apikey}/people/`).push({name: storeName, phone: storePhone})
      laundryServices.forEach(item => {
        Firebase.database()
        .ref(`/serviceofferings/${currentUserData.apikey}/services/`).push(item)
      })

      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/storelocation/`).set(
        {addresslatlng: currentUserData.storeaddresslatlng,
         countrycode: currentUserData.countrycode,
         paymentmethod: currentUserData.countrycode == "+91" ? "Phonepay" : "Venmo"
       })

    }
  }

  const [productName, setProductName] = React.useState();
  const [productDescription, setProductDescription] = React.useState();
  const [productDiscount, setProductDiscount] = React.useState();
  const [productImage, setProductImage] = React.useState();
  const [productPrice, setProductPrice] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [rollOutModal, setRollOutModal] = React.useState(false);
  const [rollOutName, setRollOutName] = React.useState();
  const [rollOutDescription, setRollOutDescription] = React.useState();
  const [rollOutTargetAudience, setRollOutTargetAudience] = React.useState("Store Wide");
  const [productId, setProductId] = React.useState();
  const [autoScheduleEnabled, setAutoScheduleEnabled] = React.useState();
  const [connectDomainModal, setConnectDomainModal] = React.useState();
  const [templatePickModal, setTemplatePickModal] = React.useState();
  const [domainName, setDomainName] = React.useState();
  const [domainDescription, setDomainDescription] = React.useState();
  const [domainTemplate, setDomainTemplate] = React.useState();
  const [branchAddress, setBranchAddress] = React.useState();
  const [branchAddressLocation, setBranchAddressLocation] = React.useState();
  const [branchAddressLatLng, setBranchAddressLatLng] = React.useState();
  const [addBranchModal, setAddBranchModal] = React.useState();
  const [branchName, setBranchName] = React.useState();
  const [branchIdForOrderFilter, setBranchIdForOrderFilter] = React.useState(null);
  const [menuOnScan, setMenuOnScan] = React.useState();
  const [tableCount, setTableCount] = React.useState();
  const [tableQRCodeModal, setTableQRCodeModal] = React.useState();

  const [onroadDelivery, setOnroadDelivery] = React.useState();
  

  const getBizTypeURL = () => {
    if(!currentUserData){
      return 'order'
    }
    // if(currentUserData.businesstype == "Restaurant"){
    //   return 'food'
    // }
    if(currentUserData.businesstype == "Laundry"){
      return 'laundry'
    }
    return 'order'
  }
  const availableTemplates = [
      {'default': base, 'link': `https://${getBizTypeURL()}.onroad.app/${currentUserData?.storepath}` },
      {'grocery': grocery, 'link': `https://onroadtemplate.web.app/`},
      {'restaurant': restaurant, 'link': `https://food.onroad.app/${currentUserData?.storepath}`},
      {'laundry': laundry, 'link': `https://laundry.onroad.app/Laundry-Basket`},
      {'toys': toys, 'link': `http://supro.reactstorefronts.com/home/home-simple`},
      {'kitchen': kitchen, 'link': `http://supro.reactstorefronts.com/home/home-boxed`},
      {'shoes': shoes, 'link': `http://supro.reactstorefronts.com/`},
      {'clothes':clothes, 'link': `http://supro.reactstorefronts.com/home/home-fullwidth` },
      {'bags': bags, 'link': `http://supro.reactstorefronts.com/home/home-best-selling`},
      {'fashion': fashion, 'link': `http://supro.reactstorefronts.com/home/home-classic`},
      {'furniture': furniture, 'link': `http://supro.reactstorefronts.com/home/home-moderm`},
      {'categories': categories, 'link': `http://supro.reactstorefronts.com/home/home-categories`}
    ]
  const handleOpen = () => {
    const fbRef = Firebase.database()
    .ref(`/ecommerce/${currentUserData.apikey}/products/`).push()
    setProductId(fbRef.key)
    setOpen(true)
  }

  const handleConnectToOwnDomain = () => {
    setConnectDomainModal(true)
  }

  const handleTemplatePickModal = () => {
    setTemplatePickModal(true)
  }

  const saveTemplate = () => {
    Firebase.database()
    .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
    .once('value', snapshot => {
      snapshot.ref.update({
        selectedtemplate: selectedTemplate,
      })
    })
    setTemplatePickModal(false);
  }

  const handleClose = () => {
    setRollOutModal(false);
    setOpen(false);
    setConnectDomainModal(false);
    setTemplatePickModal(false);
    setSelectedTemplate(false);
    setTableQRCodeModal(false);
    setAddBranchModal(false)
    setBranchName(null)
    setBranchAddress(null)
    setBranchAddressLatLng(null)
    setBranchAddressLocation(null);
  };

  const rollOutNameChange = (e) => {
    setRollOutName(e.target.value)
  }

  const domainNameChange = (e) => {
    setDomainName(e.target.value)
  }

  const domainTemplateChange = (e) => {
    setDomainTemplate(e.target.value)
  }

  const domainDescriptionChange = (e) => {
    setDomainDescription(e.target.value)
  }

  const handleDomainInterest = () => {
    Firebase.database()
    .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
    .once('value', snapshot => {
      snapshot.ref.update({
        wanttoconnectowndomain: true,
        customdomaindetails: {
          url: domainName.replace(/\./g,","),
          description: domainDescription
        }
      })
    })
    setConnectDomainModal(false);
  }
  

  const rollOutDescriptionChange = (e) => {
    setRollOutDescription(e.target.value)
  }

  const rollOutTargetAudienceChange = (e) => {

  }

  const updateOnroadDelivery = (val) => {
    if(val){
      Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        snapshot.ref.update({
          onroaddelivery: true,
        })
      })
      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/storeconfig/`).update({
        onroaddelivery: true,
      })   
      setOnroadDelivery(true);
    }else{
      Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        snapshot.ref.update({
          onroaddelivery: false
        })
      })
      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/storeconfig/`).update({
        onroaddelivery: false
      })   
      setOnroadDelivery(false);
    }

  } 

  const updateMenuOnScan = (val) => {
    if(val){
      Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        snapshot.ref.update({
          menuonscan: true,
          tablecount: 1
        })
      })
      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/storeconfig/`).update({
        menuonscan: true,
      })   
      setMenuOnScan(true);
    }else{
      Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        snapshot.ref.update({
          menuonscan: false,
          menuimages: null
        })
      })
      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/storeconfig/`).update({
        menuonscan: false,
        menuimages: null
      })   
      setMenuOnScan(false);
    }

  } 

  const productNameChange = (e) => {
    setProductName(e.target.value)
  }

  const productDescriptionChange = (e) => {
    setProductDescription(e.target.value)
  }
  

  const productPriceChange = (e) => {
    setProductPrice(e.target.value)
  }
  const productDiscountChange = (e) => {
    setProductDiscount(e.target.value)
  }

  const rollOutSave = () => {
    if((selectedBizType || currentUserData?.businesstype) == 'Store' || (selectedBizType || currentUserData?.businesstype) == 'Restaurant'){
      const addeddate =  moment().format("YYYY/MM/DD")
      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/offers/`)
      .push({
            id: "storeid",
            type: "storeadded",
            name: rollOutName,
            description: rollOutDescription,
            date: addeddate
          })
    }
    if((selectedBizType || currentUserData?.businesstype) == 'Laundry'){
      const addeddate =  moment().format("YYYY/MM/DD")

      Firebase.database()
          .ref(`/serviceofferings/${currentUserData.apikey}/offers/`).push(
            {
              id: "storeid",
              type: "storeadded",
              name: rollOutName,
              description: rollOutDescription,
              date: addeddate
            }
          )
    }
     
    setRollOutName(null);
    setRollOutDescription(null)
    setRollOutModal(false);
  }
  const handleSave = () => {
    if((selectedBizType || currentUserData?.businesstype) == 'Store' || (selectedBizType || currentUserData?.businesstype) == 'Restaurant'){
      const imagePath = encodeURIComponent(`onroadproductimages/${user.email.replace(/\./g, ",")}/${productId}`)

      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/products/`)
      .push({
            id: "storeid",
            type: "storeadded",
            stock: "yes",
            name: productName,
            price: productPrice,
            description: productDescription || "",
            discount: productDiscount,
            image:`https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/${imagePath}?alt=media&token=f0f46d6d-1778-4665-9e23-98405a9db445`
          })
    }
    if((selectedBizType || currentUserData?.businesstype) == 'Laundry'){
      Firebase.database()
          .ref(`/serviceofferings/${currentUserData.apikey}/services/`).push(
            {
              id: "storeid",
              type: "storeadded",
              name: productName,
              price: productPrice,
              description: productPrice
            }
          )
    }
     
    setProductName(null);
    setProductPrice(null)
    setOpen(false);
  }

  // const [registeredDeliveryPerson, setRegisteredDeliveryPerson] = React.useState([]);
  const requestStoreAccess = () => {
    Firebase.database()
    .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
    .once('value', snapshot => {
      snapshot.ref.update({
        storeaccessrequested: true
      })
    })
  }
  const requestScreen = () =>{
    return (
       <GridContainer justify = "space-between"
        style = {{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
         <h3>This feature is invite only</h3>
         <Button onClick={() => requestStoreAccess()} color="primary">
            Request For Access
         </Button>
       </GridContainer>
    )
  }
  const bizType = ["Store", "Restaurant","Laundry","Other"]
  const downloadQRCode = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    
  }
  const downloadTableQRCode = (tableId) => {
    const svg = document.getElementById(`tableQRCode${tableId}`);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = `tableQRCode${tableId}`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }
  const [br, setBR] = React.useState(false);
  const handleAutoScheduleChange = (event) => {
    setAutoScheduleEnabled(event.target.checked)
    Firebase.database()
    .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
    .once('value', snapshot => {
      snapshot.ref.update({
        autoscheduleenabled: event.target.checked
      })
    })
  }

  const getCoordinates = (address) => {
    geocodeByAddress(address.value.description)
      .then(results => getLatLng(results[0]))
      .then((data) => {
        // //console.log('LAT: ', data.lat);
        // //console.log('LNG: ', data.lng);
        setBranchAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setBranchAddressLocation(address.value.structured_formatting.secondary_text)
        }
        setBranchAddressLatLng(data)
      })
  };
  const branchNameChange = (e) => {
    setBranchName(e.target.value)
  }
  const handleBranchSave = async () => {
    const branchDetails = {
      name: branchName,
      address: branchAddress,
      location: branchAddressLocation,
      addresslatlng: branchAddressLatLng
    }
    setAddBranchModal(false)
    setBranchName(null)
    setBranchAddress(null)
    setBranchAddressLatLng(null)
    setBranchAddressLocation(null);
    if(!currentUserData.branches) {
      const branchDetails = {
        name: `Mainbranch: ${currentUserData?.storeaddress}`,
        address: currentUserData.storeaddress,
        location: currentUserData.storeaddresslocation,
        addresslatlng: currentUserData.storeaddresslatlng
      }
      const branchRef = await Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/branches/`).push(branchDetails)
      Firebase.database()
      .ref(`/ecommerce/${currentUserData.apikey}/branches/${branchRef.key}`).set(branchDetails)   
    }

    const branchRef = await Firebase.database()
    .ref(`/webadmin/${user.email.replace(/\./g,",")}/branches/`).push(branchDetails)
    Firebase.database()
    .ref(`/ecommerce/${currentUserData.apikey}/branches/${branchRef.key}`).set(branchDetails)   
  }

  const removeBranch = (branchID) => {
    if(!branchID){
      return;
    }
    if(currentUserData.branches && Object.keys(currentUserData.branches).length == 2) {
      const mainBranchID = Object.keys(currentUserData.branches).filter(bID => bID != branchID)[0]
      if(mainBranchID){
        Firebase.database()
        .ref(`/webadmin/${user.email.replace(/\./g,",")}/branches/${mainBranchID}`).set(null)
        Firebase.database()
        .ref(`/ecommerce/${currentUserData.apikey}/branches/${mainBranchID}`).set(null)
      }
      
    }
    Firebase.database()
    .ref(`/webadmin/${user.email.replace(/\./g,",")}/branches/${branchID}`).set(null)
    Firebase.database()
    .ref(`/ecommerce/${currentUserData.apikey}/branches/${branchID}`).set(null)
    
  }

  const handleBranchForOrders = (id) => {
    setBranchIdForOrderFilter(id)
    if(id == 'All'){
      setOrderList(baseOrderList)
    }else {
      setOrderList(baseOrderList.filter(order => {
        if(order[2].includes(':branchname:')){
          return order[2].split(":branchname:")[1].split(",deliverymethod")[0] == id
        }
      }))
    }
  }

  const getTableCount = () => {
    let result = []
    for (let index = 0; index < Number(currentUserData.tablecount); index++) {
      result.push(index);
    }
    return result;
  }

  const storeScreen = () => {
    return (
      <>
      <Snackbar
          place="br"
          color="info"
          icon={AddAlert}
          message="New order received, please click on schedule"
          open={br}
          closeNotification={() => setBR(false)}
          close
        />
      <br />
      <Dialog open={addBranchModal} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Branch Details</DialogTitle>
        
        <DialogContent style={{minHeight: "400px"}}>
          <DialogContentText>
              Branch Name
          </DialogContentText>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <CreateIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              placeholder: "Branch Name...",
              onBlur: branchNameChange
            }}
          />
          <br/>
          <DialogContentText>
            Branche Address
          </DialogContentText>
          <GooglePlacesAutocomplete
            selectProps={{
            onChange: (address) => getCoordinates(address),
          }}
            placeholder="Search Address "
            apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
          />
          <h4>{branchAddress}</h4>
          {branchAddressLatLng && (<img src={`https://maps.googleapis.com/maps/api/staticmap?center=${branchAddressLatLng.lat},${branchAddressLatLng.lng}&zoom=18&size=550x300&maptype=roadmap&markers=color:red%7Clabel:G%7C${branchAddressLatLng.lat},${branchAddressLatLng.lng}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`} title="" />)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleBranchSave} color="primary" disabled={!branchAddress || !branchName}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Add A {((selectedBizType || currentUserData?.businesstype) == 'Store') ? "Product" : (((selectedBizType || currentUserData?.businesstype) == 'Restaurant') ? "Menu Item" : "Service") }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter Name
          </DialogContentText>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <CreateIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              placeholder: "Name...",
              onBlur: productNameChange
            }}
          />
          <br/>
          <br/>
          <DialogContentText>
            Enter  {((selectedBizType || currentUserData?.businesstype) == 'Store' || (selectedBizType || currentUserData?.businesstype) == 'Restaurant') ? "Price" : "Description" }
          </DialogContentText>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <AttachMoneyIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              placeholder: "...",
              onBlur: productPriceChange
            }}
          />
           <br/>
          {((selectedBizType || currentUserData?.businesstype) == 'Store' || (selectedBizType || currentUserData?.businesstype) == 'Restaurant') ? 
            (
              <>
              {((selectedBizType || currentUserData?.businesstype) == 'Restaurant') && (<>
              
                <DialogContentText>
                  Enter Description
                </DialogContentText>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <CreateIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    placeholder: "Description...",
                    onBlur: productDescriptionChange
                  }}
                />
                <br/>
                    
              </>)  }
              
              <DialogContentText>
               Discount Percentage
              </DialogContentText>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <AttachMoneyIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: "% of discount",
                  onBlur: productDiscountChange
                }}
              />
              <small style={{color: "gray"}}>If its more than 50% discount, it would be deal of the day </small>
              <br />
              <br />
              <DialogContentText>
              Product Image
              </DialogContentText>
              <br />

              <ImageUpload
                addButtonProps={{
                  color: "rose",
                  round: true,
                }}
                changeButtonProps={{
                  color: "rose",
                  round: true,
                }}
                removeButtonProps={{
                  color: "danger",
                  round: true,
                }}
                imageID={() => {
                  if(productId){
                    return `${user.email.replace(/\./g, ",")}/${productId}`
                  }else{
                    const t = new Date().getTime()
                    setProductId(t)
                    return `${user.email.replace(/\./g, ",")}/${t}`
                  }
                }}
              />
              </>) : null}
          <br/>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={!productName || !productPrice}>
            Save The Product
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={rollOutModal} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Offer Rollout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter Name
          </DialogContentText>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <CreateIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              placeholder: "Name...",
              onBlur: rollOutNameChange
            }}
          />
          <br/>
          <br/>
          <DialogContentText>
            Enter Description
          </DialogContentText>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <AttachMoneyIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              placeholder: "...",
              onBlur: rollOutDescriptionChange
            }}
          />
           <br/>
          <DialogContentText>
            Target audience
          </DialogContentText>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <LocalOfferIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              value: rollOutTargetAudience,
              disabled: true,
              placeholder: "...",
            }}
          />
        </DialogContent>
         <small style={{textAlign: "center"}}>Currently you can only do store wide offers. <br/>if you need to roll out targeted locations or customers email info@onroad.app</small> 
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={rollOutSave} color="primary" disabled={!rollOutName || !rollOutDescription}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={connectDomainModal} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Connect Own Domain</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Domain URL
          </DialogContentText>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <HttpIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              placeholder: "Ex: google.com",
              onBlur: domainNameChange
            }}
          />
          <br/>
          <br/>
          <DialogContentText>
            Description
          </DialogContentText>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <DescriptionIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              placeholder: "What is your business...",
              onBlur: domainDescriptionChange
            }}
          />
          <br/>
          <br/>

          {/* <DialogContentText>
            What kind of template you interested in ?
          </DialogContentText>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <SettingsInputSvideoIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              placeholder: "Ecommerce or Service or Landingpage ..etc",
              onBlur: domainTemplateChange
            }}
          /> */}
        </DialogContent>
         <small style={{textAlign: "center"}}>We will contact you as soon as possible. <br/>You need to update IP address in your DNS directory email info@onroad.app if you need more details</small> 
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDomainInterest} color="primary" disabled={!domainName || !domainDescription}>
            Lets Schedule Call
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={templatePickModal} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Choose A Template</DialogTitle>
        <DialogContent>
          {availableTemplates.map((t) => 
            <div className="fileinput text-center" style={{padding: "10px"}}>
             <div className="thumbnail">
               <img src={t[Object.keys(t)[0]]} alt="..."  style={{ border: (selectedTemplate || currentUserData?.selectedtemplate) == Object.keys(t)[0] ? "2px solid red": null}} onClick={() => setSelectedTemplate(Object.keys(t)[0])} />
             </div>
             <div>
             {!(Object.keys(t)[0] == 'default' || Object.keys(t)[0] == 'restaurant' || Object.keys(t)[0] == 'laundry') && (<Badge color="rose" style={{fontSize: "8px", textTransform: "capitalize"}}>Premium</Badge>)}
             &nbsp;
               <span style={{fontSize: "12px", textTransform: "capitalize"}}>{Object.keys(t)[0]}</span>
               
               <a href={t[Object.keys(t)[1]]} target="_blank">
                 <LaunchIcon style={{paddingLeft: "5px", paddingTop: "5px", fontSize: "1rem"}} />
               </a>
             </div>
           </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveTemplate} color="primary" disabled={!selectedTemplate}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={tableQRCodeModal} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">QR Codes</DialogTitle>
        <DialogContent>
          <div>
          <h5>Generic</h5>
          <div className="fileinput text-center" style={{padding: "10px"}}>
             <div className="thumbnail">
                <QRCode size={160} id={`tableQRCodeGeneric`} value={`https://ordereasy.firebaseapp.com/${currentUserData?.storepath}?table=generic`} title={`generic`}/>
             </div>
             <div>
               <span style={{fontSize: "12px", textTransform: "capitalize"}}>{`Generic`}</span>
               <Button style={{left: "10px", fontSize: "11px", padding: "4px", textTransform: "capitalize" }}
                       color="primary"
                       size="sm"
                       className={classes.marginLeft}
                       onClick={()=>  downloadTableQRCode('Generic')}
                >
                        download
                      </Button>
             </div>
           </div>

          <h5>For Each Table</h5>
          {getTableCount().map((v, i) => 
            (<div className="fileinput text-center" style={{padding: "10px"}}>
             <div className="thumbnail">
                <QRCode size={160} id={`tableQRCode${i+1}`} value={`https://ordereasy.firebaseapp.com/${currentUserData?.storepath}?table=${i+1}`} title={`Table${i+1}`}/>
             </div>
             <div>
               <span style={{fontSize: "12px", textTransform: "capitalize"}}>{`Table ${i+1}`}</span>
               <Button style={{left: "10px", fontSize: "11px", padding: "4px", textTransform: "capitalize" }}
                       color="primary"
                       size="sm"
                       className={classes.marginLeft}
                       onClick={()=>  downloadTableQRCode(i+1)}
                >
                        download
                      </Button>
             </div>
           </div>)
          )}
          </div>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Heading
          textAlign="center"
          title="Store Details"
          // category={currentUserData?.apikey}
        />
      <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
        <Accordion
          collapses={[
            {
              title: (<h5 style={{paddingLeft: "15px"}}>Store Configuration</h5>),
              content: (
                <CardBody>
                <div className={classes.typo}>
                  <div className={classes.note}>Name</div>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          {/* <LocalGroceryStoreIcon className={classes.inputAdornmentIcon} /> */}
                        </InputAdornment>
                      ),
                      placeholder: "Name...",
                      // onChange: storeNameChange,
                      // onFocus: onFocus,
                      disabled: currentUserData && currentUserData.storename,
                      value: currentUserData?.storename,
                      onBlur: storeNameChange
                    }}
                  />
                  {!(selectedBizType || currentUserData?.businesstype) && (showProgress ? (<CustomLinearProgress />): (
                    isStoreNameAvailable ? (<span style={{color: "green"}}> <CheckIcon /> Available</span>) : (!currentUserData?.storename && (<span style={{color: "red"}}> Store Name Not Available</span>))
                  ))}
                </div>
                <div className={classes.typo}>
                  <div className={classes.note}>Phone</div>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          {/* <PhoneIcon className={classes.inputAdornmentIcon} /> */}
                          {currentUserData?.countryflag}
                          {/* {currentUserData?.countrycode} */}
                        </InputAdornment>
                      ),
                      placeholder: "10 Digit Phone Number...",
                      // type: "number",
                      disabled: currentUserData?.storephone && true,
                      value: currentUserData?.storephone,
                      onBlur: storePhoneChange
                    }}
                  />
                </div>
                <div className={classes.typo}>
                  <div className={classes.note} style={{maxWidth: "20%"}}>Business Type</div>
                  {(selectedBizType || currentUserData?.businesstype) ? (
                    <CustomInput
                    style={{paddingLeft: "20px"}}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                        </InputAdornment>
                      ),
                      placeholder: "Phone...",
                      disabled: true,
                      value: selectedBizType || currentUserData?.businesstype
                    }}
                  />
    
                  ) : (
                    <CustomDropdown
                      dropup
                      onClick={(val) => handleBizType(val)}
                      buttonText="Pick Business Type"
                      hoverColor="info"
                      dropPlacement="top"
                      buttonProps={{
                        round: true,
                        fullWidth: true,
                        style: { marginBottom: "0", color: (!storePath || !storeName) ? "gray": "" },
                        color: "info",
                        width: "50%"
                      }}
                      style={{width: "80%", paddingLeft: "20px"}}
                      dropdownList={(storePath && storeName) ? bizType : []}
                    />
                  )}
                  
                </div>
                
    
                <div className={classes.typo}>
                  <div className={classes.note} style={{maxWidth: "20%"}}>Onroad Delivery</div>
                  <div style={{width: "80%", position: "relative", top: `${(onroadDelivery || currentUserData?.onroaddelivery ) ? '11px' : '0px'}` }}>
                    <span style={{paddingLeft: "20px", textTransform: "capitalize"}}>
                    <Switch
                      checked={onroadDelivery || currentUserData?.onroaddelivery}
                      onChange={(event) => updateOnroadDelivery(event.target.checked)}
                      value={onroadDelivery || currentUserData?.onroaddelivery}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar,
                      }}
                    />
                    <small style={{color: "gray"}}>{(onroadDelivery || currentUserData?.onroaddelivery ) ? "Enabled" : "Disabled"}</small>
                    <br/>
                    <div style={{paddingleft: "25px"}}>
                      <small style={{color: "gray"}}>{(onroadDelivery || currentUserData?.onroaddelivery ) && "We will get you the business and take care deliveries"}</small>
                    </div>
                    </span>
                  </div>
    
                </div>
    
                <div className={classes.typo}>
                  <div className={classes.note} style={{maxWidth: "20%"}}> Physical Branches</div>
                  <p style={{paddingLeft: "20px"}}>{`Mainbranch: ${currentUserData?.storeaddress}`}
                  {currentUserData && currentUserData?.branches && Object.keys(currentUserData.branches).filter(bid => currentUserData.branches[bid]?.name != `Mainbranch: ${currentUserData?.storeaddress}`).map((branchId) => 
                  (
                      <div style={{padding: "1px"}}>
                      <Badge id="2" color="success">{currentUserData.branches[branchId]?.name}  <Button simple style={{padding: "0px", height: "10px"}} onClick={()=>{removeBranch(branchId)}}><Close className={classes.icon} style={{fontSize: "10px", color: "red"}}/></Button></Badge>
                      </div>
                    ))}
                    {(selectedBizType || currentUserData?.businesstype) != 'Restaurant' && (<IconButton
                      aria-label="Add"
                      className={classes.tableActionButton}
                      onClick={()=> setAddBranchModal(true)}
                    >
                      <Add className={classes.icon} />
                    </IconButton>)}
                  </p>
                    
                  {/* {(branches || currentUserData?.branches) ? (
                    <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                        </InputAdornment>
                      ),
                      placeholder: "Phone...",
                      disabled: true,
                      value: selectedBizType || currentUserData?.businesstype
                    }}
                  />
                  ) : (
                    <CustomDropdown
                      dropup
                      onClick={(val) => handleBizType(val)}
                      buttonText="Pick Business Type"
                      hoverColor="info"
                      dropPlacement="top"
                      buttonProps={{
                        round: true,
                        fullWidth: true,
                        style: { marginBottom: "0" },
                        color: "info",
                        width: "50%"
                      }}
                      style={{width: "80%"}}
                      dropdownList={bizType}
                    />
                  )} */}
                  
                </div>
                <div className={classes.typo}>
                  <div className={classes.note} style={{maxWidth: "20%"}}> Virtual Branches</div>
                  <div className={classes.marginLeft} style={{ fontWeight: "700", color: "#bf4b4b", paddingLeft: "20px"}}> <WhatshotIcon /> <span style={{position: "relative", bottom: "6px"}}>Coming Soon</span></div>
                    {/* {currentUserData && currentUserData?.virtualBranches && Object.keys(currentUserData.virtualBranches).map((branchId) => (
                      <div style={{padding: "1px"}}>
                      <Badge id="2" color="success">{currentUserData.virtualBranches[branchId]?.name}  <Button simple style={{padding: "0px", height: "10px"}} onClick={()=>{removeVirtualBranches(branchId)}}><Close className={classes.icon} style={{fontSize: "10px", color: "red"}}/></Button></Badge>
                      </div>
                    ))}
                    <IconButton
                      aria-label="Add"
                      className={classes.tableActionButton}
                      onClick={()=> setAddVirtualBranchModal(true)}
                    >
                    <Add className={classes.icon} />
                  </IconButton> */}
                </div>
                <div className={classes.typo}>
                  <div className={classes.note}>Template</div>
                  <Button style={{left: "10px"}} color="primary" size="sm" className={classes.marginLeft} onClick={()=>  setTemplatePickModal(true)} disabled={!(bizType || currentUserData?.businesstype) || !(storePath || currentUserData?.storepath)  || !(storeName || currentUserData?.storename) }>
                    Change Store Design
                  </Button>
                  <span style={{paddingLeft: "20px", textTransform: "capitalize"}}>
                    {selectedTemplate || currentUserData?.selectedtemplate || "Default"} {" "}
                  </span>
                </div>
                {(selectedBizType || currentUserData?.businesstype) == 'Restaurant' && (<><div className={classes.typo}>
                  <div className={classes.note} style={{maxWidth: "20%"}}> QR Code Menu</div>
                   <div  style={{position: "relative", bottom: "6px"}}>
                    <Switch
                      checked={menuOnScan || currentUserData?.menuonscan}
                      onChange={(event) => updateMenuOnScan(event.target.checked)}
                      value={menuOnScan || currentUserData?.menuonscan}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar,
                      }}
                    />
                    <small style={{color: "gray"}}>{(menuOnScan || currentUserData?.menuonscan ) ? "Enabled" : "Disabled"}</small>
                    </div>
                </div>
                {((menuOnScan || currentUserData?.menuonscan) == true) && (
                <>
                <GridContainer justify = "space-between"
                  style = {{
                      color: "grey",
                      justifyContent: "center",
                      textAlign: "center"
                    }}>
                    <GridItem xs={6} sm={3} md={3} >
                      {((menuOnScan || currentUserData?.menuonscan) == true) && (<><MenuImageUpload
                        addButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true,
                        }}
                        existingImage={currentUserData?.menuimages?.menuimage1}
                        imageID={() => {
                          return `${user.email.replace(/\./g, ",")}/${(storePath || currentUserData?.storepath)}-1`
                        }}
                        imageNumber={1}
                        email={user?.email}
                        apikey={currentUserData?.apikey}
                      />
                      </>
                      )}
                    </GridItem>
                    <GridItem xs={6} sm={3} md={3}>
                    {((menuOnScan || currentUserData?.menuonscan) == true) && (<><MenuImageUpload
                        addButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true,
                        }}
                        existingImage={currentUserData?.menuimages?.menuimage2}
                        imageID={() => {
                          return `${user.email.replace(/\./g, ",")}/${(storePath || currentUserData?.storepath)}-2`
                        }}
                        imageNumber={2}
                        email={user?.email}
                        apikey={currentUserData?.apikey}
                      />
                      </>
                      )}
                    </GridItem>
                    <GridItem xs={6} sm={3} md={3}>
                    {((menuOnScan || currentUserData?.menuonscan) == true) && (<><MenuImageUpload
                        addButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true,
                        }}
                        existingImage={currentUserData?.menuimages?.menuimage3}
                        imageID={() => {
                          return `${user.email.replace(/\./g, ",")}/${(storePath || currentUserData?.storepath)}-3`
                        }}
                        imageNumber={3}
                        email={user?.email}
                        apikey={currentUserData?.apikey}
                      />
                      </>
                      )}
                    </GridItem>
                    <GridItem xs={6} sm={3} md={3}>
                    {((menuOnScan || currentUserData?.menuonscan) == true) && (<><MenuImageUpload
                        addButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true,
                        }}
                        existingImage={currentUserData?.menuimages?.menuimage4}
                        imageID={() => {
                          return `${user.email.replace(/\./g, ",")}/${(storePath || currentUserData?.storepath)}-4`
                        }}
                        imageNumber={4}
                        email={user?.email}
                        apikey={currentUserData?.apikey}
                      />
                      </>
                      )}
                    </GridItem>
                </GridContainer>
                <br />
                </>
                )} 
                </>)}
    
                {(selectedBizType || currentUserData?.businesstype) == 'Restaurant' && ((menuOnScan || currentUserData?.menuonscan) == true) && (<>
                <div className={classes.typo}>
                  <div className={classes.note} style={{maxWidth: "20%"}}>Number Of Tables</div>
                  <div style={{width: "80%", position: "relative", top: "11px"}}>
                    <span style={{paddingLeft: "20px", textTransform: "capitalize"}}>
                    <CustomInput
                      formControlProps={{
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        endAdornment: (
                          <Button style={{left: "10px", fontSize: "11px", padding: "4px", textTransform: "capitalize" }} color="primary" size="sm" className={classes.marginLeft} onClick={()=>setTableQRCodeModal(true)} disabled={!(currentUserData?.tablecount > 0 && currentUserData?.tablecount < 100)}>
                            View Table QR Codes
                          </Button>
                        ),
                        type: "number",
                        value: currentUserData?.tablecount,
                        onChange: storeTableCountChange
                      }}
                    />
                  </span>
                  </div>
                  
                </div>
                </>)}
              </CardBody>
              )
            }]}
            />  
        </Card>
      </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
          <Accordion
            collapses={[
                  {
                    title: (<h5 style={{paddingLeft: "15px"}}>Website</h5>),
                    content: (
                    <CardBody>
                                {currentUserData?.businesstype ? (
              <div style={{textAlign: "center", marginTop: "8%"}}>
                {currentUserData?.connecteddomain ? (<>
                      <div>
                        <a href={`https://${currentUserData?.connecteddomain?.replace(",", ".")}`} target="_blank" textAlign="center">{`https://${currentUserData?.connecteddomain?.replace(",", ".")}`} <LaunchIcon style={{fontSize: "12px"}} /></a>
                      </div>
                </>) : (<><Button
                  color="transparent"
                  style={{color: "red", fontSize: "8px", paddingRight: "3px", fontWeight: "800"}}
                  className={classes.buttonLink}
                  onClick={handleConnectToOwnDomain}
                  disabled={currentUserData && currentUserData.wanttoconnectowndomain}
                >
                 Connect My Own Domain &nbsp; &nbsp;&nbsp;{" "}{currentUserData?.wanttoconnectowndomain && <Badge color="success" style={{marginLeft: "10px", fontSize: "4px"}}>In Review</Badge>}

                </Button>
                <div>
                  <a href={`https://${getBizTypeURL()}.onroad.app/${currentUserData?.storepath}`} target="_blank" textAlign="center">{`https://${getBizTypeURL()}.onroad.app/${currentUserData?.storepath}`} <LaunchIcon style={{fontSize: "12px"}} /></a>
                </div>
                </>)}
                <div>
                  <QRCode id="QRCode" value={currentUserData?.connecteddomain ? `https://${currentUserData?.connecteddomain?.replace(",",".")}` : `https://${getBizTypeURL()}.onroad.app/${currentUserData?.storepath}`} />
                  <p style={{fontSize: "10px"}}>Print above QR code and paste on the wall</p>
                  <Button size="sm" onClick={() => downloadQRCode()} color="primary">Download </Button>
                </div>
                </div>
              ) : null}
                </CardBody>
              )        
                  }
                ]}
              />
              
          </Card>
        </GridItem>
      </GridContainer>

      <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            headerColor="success"
            tabs={[
              ...((selectedBizType || currentUserData?.businesstype) == 'Restaurant' && ((menuOnScan || currentUserData?.menuonscan) == true)  && (currentUserData?.tablecount > 0 && currentUserData?.tablecount < 100) ? [{
                tabName: "Table Orders",
                tabIcon: WhatshotIcon,
                tabContent: (
                 <>
                  {/* <Card>
                    <CardBody>
                      <div className={classes.typo}>
                      <div className={classes.note}>Payment Phone Number</div>
                        <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                            </InputAdornment>
                          ),
                          placeholder: "Phone...",
                          disabled: true,
                          value: paymentPhoneNumber || currentUserData?.storephone
                          }}
                        />
                      </div>
                      <div className={classes.typo}>
                      <div className={classes.note}>Auto Schedule Orders {`< 10`} Miles</div> 
                      <Switch
                        checked={false}//autoScheduleEnabled}
                        // onChange={(event) => handleAutoScheduleChange(event)}
                        // value={autoScheduleEnabled}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                      <small style={{color: "gray"}}>{autoScheduleEnabled ? "Enabled" : "Not Enabled"}</small>
                      <br />
                      <small style={{color: "gray"}}>Send email to info@onroad.app to enable this feature</small>
                      </div>
                    </CardBody>
                  </Card> */}
                  {currentUserData?.branches && (<div className={classes.typo} style={{ display: "flex", paddingLeft: "80%"}}>
                      <p style={{ paddingTop: "7px", paddingRight: "5px"}}>Filter By</p>
                        <CustomDropdown
                          dropup
                          onClick={(val) => handleBranchForOrders(val)}
                          buttonText={branchIdForOrderFilter ? branchIdForOrderFilter :  `All`}
                          hoverColor="info"
                          dropPlacement="top"
                          buttonProps={{
                            round: true,
                            fullWidth: true,
                            style: { marginBottom: "0", width: "100px", height: "20px", textAlign: "right", overflow: "hidden"},
                            color: "info"
                          }}
                          style={{textAlign: "right"}}
                          dropdownList={Object.keys(currentUserData.branches).map(bId => currentUserData.branches[bId].name).concat("All")}
                        />
                  </div>)}
                 {
                  tableOrderList.length > 0 ? (<Table
                      tableHeaderColor="primary"
                      tableData={tableOrderList}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                  />) : (<Heading
                        textAlign = "center"
                        title = "No Orders Yet"
                        />)
                  }
                 </>
                ),
              }] : []),
              ...(((onroadDelivery || currentUserData?.onroaddelivery ) == true) ? [{
                tabName: "Onroad Delivery",
                tabIcon: WhatshotIcon,
                tabContent: (
                 <>
                  {currentUserData?.branches && (<div className={classes.typo} style={{ display: "flex", paddingLeft: "80%"}}>
                      <p style={{ paddingTop: "7px", paddingRight: "5px"}}>Filter By</p>
                        <CustomDropdown
                          dropup
                          onClick={(val) => handleBranchForOrders(val)}
                          buttonText={branchIdForOrderFilter ? branchIdForOrderFilter :  `All`}
                          hoverColor="info"
                          dropPlacement="top"
                          buttonProps={{
                            round: true,
                            fullWidth: true,
                            style: { marginBottom: "0", width: "100px", height: "20px", textAlign: "right", overflow: "hidden"},
                            color: "info"
                          }}
                          style={{textAlign: "right"}}
                          dropdownList={Object.keys(currentUserData.branches).map(bId => currentUserData.branches[bId].name).concat("All")}
                        />
                  </div>)}
                 {
                  onroadDeliveryOrderList.length > 0 ? (<Table
                      tableHeaderColor="primary"
                      tableData={onroadDeliveryOrderList}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                  />) : (<Heading
                        textAlign = "center"
                        title = "No Orders Yet"
                        />)
                  }
                 </>
                ),
              }] : []),
              {
                tabName: "Online Orders",
                tabIcon: WhatshotIcon,
                tabContent: (
                 <>
                  <Card>
                    <CardBody>
                      <div className={classes.typo}>
                      <div className={classes.note}>Payment Phone Number</div>
                        <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                            </InputAdornment>
                          ),
                          placeholder: "Phone...",
                          disabled: true,
                          value: paymentPhoneNumber || currentUserData?.storephone
                          }}
                        />
                      </div>
                      <div className={classes.typo}>
                      <div className={classes.note}>Auto Schedule Orders {`< 10`} Miles</div> 
                      <Switch
                        checked={false}//autoScheduleEnabled}
                        // onChange={(event) => handleAutoScheduleChange(event)}
                        // value={autoScheduleEnabled}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                      <small style={{color: "gray"}}>{autoScheduleEnabled ? "Enabled" : "Not Enabled"}</small>
                      <br />
                      <small style={{color: "gray"}}>Send email to info@onroad.app to enable this feature</small>
                      </div>
                    </CardBody>
                  </Card>
                  {currentUserData?.branches && (<div className={classes.typo} style={{ display: "flex", paddingLeft: "80%"}}>
                      <p style={{ paddingTop: "7px", paddingRight: "5px"}}>Filter By</p>
                        <CustomDropdown
                          dropup
                          onClick={(val) => handleBranchForOrders(val)}
                          buttonText={branchIdForOrderFilter ? branchIdForOrderFilter :  `All`}
                          hoverColor="info"
                          dropPlacement="top"
                          buttonProps={{
                            round: true,
                            fullWidth: true,
                            style: { marginBottom: "0", width: "100px", height: "20px", textAlign: "right", overflow: "hidden"},
                            color: "info"
                          }}
                          style={{textAlign: "right"}}
                          dropdownList={Object.keys(currentUserData.branches).map(bId => currentUserData.branches[bId].name).concat("All")}
                        />
                  </div>)}
                 {
                  orderList.length > 0 ? (<Table
                      tableHeaderColor="primary"
                      tableData={orderList}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                  />) : (<Heading
                        textAlign = "center"
                        title = "No Orders Yet"
                        />)
                  }
                 </>
                ),
              },
              {
                tabName: "Offer Rollouts",
                tabIcon: LocalOfferIcon,
                tabContent: (
                  <>
                  <Card>
                    
                    <CardHeader color="rose" icon>
                      <CardIcon color="rose" style={{float: "right", padding: "10px"}}>
                        <Button  onClick={() => setRollOutModal(true)}
                        disabled={!currentUserData || !(currentUserData && currentUserData.storepath)}
                        style = {{
                            background: "transparent",
                            boxShadow: "none"
                          }}>
                          Add Offer
                        </Button>
                      </CardIcon>    
                    </CardHeader>
                    {offerRolloutList.length > 0 ? (<Heading
                      textAlign="center"
                      title="Rolled Out Offers"
                      // category={currentUserData?.apikey}
                    />) : null}
                    <CardBody>
                      {
                        offerRolloutList.length > 0 ? (<Table
                          tableHeaderColor="primary"
                          tableHead = {["Name", "Description", "Date"]}
                          tableData={offerRolloutList}
                          coloredColls={[3]}
                          colorsColls={["primary"]}
                      />) : (<Heading
                            textAlign = "center"
                            title = "No Rollouts Yet"
                            />)
                      }
                    </CardBody>
                  </Card>
                  <Card>
                  {topLocations && topCustomers && (<GridContainer justify="space-between">
                    
                    <GridItem xs={12} sm={12} md={6}>
                      <Heading textAlign = "center" title = "Top Locations"/>
                      <Table
                        tableHead = {["Location", "Total Orders", "Percentage"]}
                        tableData={topLocations}
                      />
                    </GridItem>
                   
                    <GridItem xs={12} sm={12} md={6}>
                      <Heading textAlign = "center" title = "Top Customers"/>

                      <Table
                        tableHead = {["Phone", "Total Orders", "Percentage"]}
                        tableData={topCustomers}
                      />
                    </GridItem>
                  </GridContainer>)}
                  </Card>
                  {/* {
                    offerRolloutList.length > 0 ? (<Table
                      tableHeaderColor="primary"
                      tableHead = {["Offer", "Description"]}
                      tableData={offerRolloutList}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                  />) : (<Heading
                        textAlign = "center"
                        title = "No Offer Rollouts Yet"
                        />)
                  } */}
                  </>
                ),
              },
              {
                tabName: (selectedBizType || currentUserData?.businesstype) == 'Store' ? "Inventory" : ((selectedBizType || currentUserData?.businesstype) == 'Restaurant' ? "Menu" : "Service"),
                tabIcon: AddToPhotosIcon,
                tabContent: (
                  <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose" style={{float: "right", padding: "10px"}}>
                      <Button  onClick={handleOpen}
                      disabled={!currentUserData || !(currentUserData && currentUserData.storepath)}
                      style = {{
                          background: "transparent",
                          boxShadow: "none"
                        }}>
                        Add A {((selectedBizType || currentUserData?.businesstype) == 'Store') ? "Product" : (((selectedBizType || currentUserData?.businesstype) == 'Restaurant') ? "Menu Item" : "Service") }
                      </Button>
                    </CardIcon>    
                  </CardHeader>
                <CardBody>
                  {
                    productList.length > 0 ? (<Table
                      tableHeaderColor="primary"
                      tableHead = {((selectedBizType || currentUserData?.businesstype) == 'Store' || (selectedBizType || currentUserData?.businesstype) == 'Restaurant') ? ["Name",  "Price", "Stock", "Delete"] : ["Name", "Service", "Delete"]}
                      tableData={productList}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                  />) : (<Heading
                        textAlign = "center"
                        title = "No Products Yet"
                        />)
                  }
                </CardBody>
                  </Card>
                ),
              },
            ]}
          >
          </CustomTabs>
      </GridItem>
      </>
    )
  }
  return (
    <div>
      {currentUserData?.storeaccessrequested ? storeScreen() : requestScreen()}
    </div>
  );
}
