import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddIcon from "@material-ui/icons/Add";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
import Build from "@material-ui/icons/Build";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import Firebase from 'firebase';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }

  const [currentUserData, setCurrentUserData] = React.useState();
  
  const google = window.google;
  const service = new google.maps.DistanceMatrixService();

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])


  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webdeliveries/storeadded/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if(data){
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length-1; index > -1; index--) {
            const element = data[keys[index]];
            result.push({
                  id: keys[index],
                  address: element.address,
                  customerPhone: element.customerphone,
                  liveTrackURL: element.livetrackurl,
                  deliveryGuyPhone: `${element.deliveryguyname} ${element.deliveryguyphone}`,
                  deliveryStatus: element.deliverystatus,
                  distance: element.distance,
                  duration: element.duration
                })
          }
          setDeliveryData(result);
        }

      });
    return () => Firebase.database().ref(`/webdeliveries/storeadded/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])
  const [deliveryData, setDeliveryData] = React.useState([]);
  const classes = useStyles();
  const [deliveryOpen, setDeliveryOpen] = React.useState(false);
  const [address, setAddress] = React.useState();
  const [addressLatLng, setAddressLatLng] = React.useState();
  const [addressLocation, setAddressLocation] = React.useState();
  const [selectedDeliveryPerson, setSelectedDeliveryPerson] = React.useState();
  const [customerPhone, setCustomerPhone] = React.useState();
  const [registeredDeliveryPerson, setRegisteredDeliveryPerson] = React.useState([]);
  const [distanceMatrixData, setDistanceMatrixData] = React.useState();
  
  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            if (element && element.verificationstatus === "done"){
              result.push(`${element.name} Ph: ${element.phone}`)
            }
          }
          setRegisteredDeliveryPerson(result);
        }
      });
    return () => Firebase.database().ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])
  const getCoordinates = (address) => {
    geocodeByAddress(address.value.description)
      .then(results => getLatLng(results[0]))
      .then((data) => {
        // //console.log('LAT: ', data.lat);
        // //console.log('LNG: ', data.lng);
        setAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setAddressLocation(address.value.structured_formatting.secondary_text)
        }
        setAddressLatLng(data)

        const request = {
          origins: [currentUserData.storeaddresslatlng],
          destinations: [data],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        };
      
        // get distance matrix response
        service.getDistanceMatrix(request).then((response) => {
          if(response && response.rows && response.rows.length > 0 && response.rows[0].elements.length > 0){
            setDistanceMatrixData(response.rows[0].elements[0])
          }
        })
      })
  };
  const deliveryHandleOpen = () => {
    setDeliveryOpen(true);
  };

  const deliveryHandleClose = () => {
    setDeliveryOpen(false);
  };

  
  const generateLiveTrackURL = () => {
    const deliveryPersonNumber = selectedDeliveryPerson.split("Ph: ")[1]
    const ciphertext = encodeURIComponent(`${deliveryPersonNumber},${customerPhone}`);
    return `https://livetracking.onroad.app/?cid=${ciphertext}`;
  }
  const getPhoneFromDisplayName = (displayName) => {
    return displayName.split("Ph: ")[1]
  }
  const getNameFromDisplayName = (displayName) => {
    return displayName.split("Ph: ")[0]
  }
  const deliveryHandleSave = () => {
    const ref = Firebase.database()
      .ref(`/webdeliveries/storeadded/${user.email.replace(/\./g, ",")}`)
      .push({
            id: user.email.replace(/\./g, ","),
            address: address,
            latlng: addressLatLng,
            customerphone: customerPhone,
            addresslocation: addressLocation,
            livetrackurl: generateLiveTrackURL(),
            deliveryguyphone: getPhoneFromDisplayName(selectedDeliveryPerson),
            deliveryguyname: getNameFromDisplayName(selectedDeliveryPerson),
            deliverystatus: "Not Delivered",
            distance: (distanceMatrixData && distanceMatrixData.distance) ? distanceMatrixData.distance.text : "Not Ready Yet",
            cost: "Pricing Not Setup Yet"
          })
    Firebase.database()
      .ref(`/scheduledjobs/${getPhoneFromDisplayName(selectedDeliveryPerson)}/${ref.key}`)
      .set({
        storeid: user.email.replace(/\./g, ","),
        orderid: ref.key,
        address: address,
        lat: addressLatLng.lat,
        lng: addressLatLng.lng,
        phone: customerPhone,
        deliverystatus: "Not Delivered"
      })
    Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        let val = snapshot.val()
        snapshot.ref.update({
          totaldeliveries: parseInt(val.totaldeliveries) + 1,
          usedcredits: parseInt(val.usedcredits) + 1
        })
      })

    Firebase.database()
      .ref(
        `/webdeliveryteam/storeadded/${user.email.replace(/\./g, ",")}/${getPhoneFromDisplayName(selectedDeliveryPerson)}/`,
      )
      .once('value', snapshot => {
        let val = snapshot.val(); 
        let obj = {}
        if(val){
          if(val.scheduleddeliveryjobs){
            obj["scheduleddeliveryjobs"] = parseInt(val.scheduleddeliveryjobs) + 1
          }
          if(val.lastupdated){
            const isSameDay = moment(val.lastupdated).isSame(moment().format("YYYY/MM/DD"));
            if(isSameDay){
              obj["todaysdeliveries"] =  parseInt(val.scheduleddeliveryjobs) + 1
            }else{
              obj["lastupdated"] = moment().format("YYYY/MM/DD")
              obj["todaysdeliveries"] = 1
            }
          }
          snapshot.ref.update(obj);
        }
      });
    setAddress(null);
    setAddressLatLng(null)
    setCustomerPhone(null)
    setAddressLocation(null)
    setSelectedDeliveryPerson(null)
    setDeliveryOpen(false);
  }
  const handleClick = (val) => {
    setSelectedDeliveryPerson(val);
  }
  const customerPhoneChange = (e) => {
    setCustomerPhone(`${currentUserData.countrycode}${e.target.value}`);
  }
  return (
    <div>
      <Dialog open={deliveryOpen} onClose={deliveryHandleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Add A Delivery</DialogTitle>
        <DialogContent>
          <h4>{address}</h4>
          {addressLatLng && (<img src={`https://maps.googleapis.com/maps/api/staticmap?center=${addressLatLng.lat},${addressLatLng.lng}&zoom=18&size=550x300&maptype=roadmap&markers=color:red%7Clabel:G%7C${addressLatLng.lat},${addressLatLng.lng}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`} title="" />)}

          <DialogContentText>
            Enter Address
          </DialogContentText>
          <GooglePlacesAutocomplete
            selectProps={{
            onChange: (address) => getCoordinates(address),
          }}
            placeholder="Search Address "
            apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
          />
          &nbsp;
          <h4>Customer Phone</h4>
          <CustomInput
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses,
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <Phone className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              type: "number",
              placeholder: "10 Digit Phone...",
              onChange: customerPhoneChange
            }}
          />
          {/* <p style={{color: "grey"}}>Please make sure delivery person installed onroad mobile app</p> */}
            <br/>
            <h4> Delivery Person : <b>{selectedDeliveryPerson}</b></h4>
            &nbsp;
            <CustomDropdown
                dropup
                onClick={(val) => handleClick(val)}
                buttonText="Pick Deliver Person"
                hoverColor="info"
                dropPlacement="top"
                buttonProps={{
                  round: true,
                  fullWidth: true,
                  style: { marginBottom: "0" },
                  color: "info",
                  width: "80%"
                }}
                style={{width: "80%"}}
                dropdownList={registeredDeliveryPerson}
              />
              <p>{registeredDeliveryPerson.length == 0 ? "Please register atleast on delivery person" : null}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={deliveryHandleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deliveryHandleSave} color="primary" disabled={registeredDeliveryPerson.length == 0 || !address || !addressLatLng || !customerPhone || customerPhone.replace(currentUserData.countrycode,'').length != 10}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary" style={{float: "right", padding: "10px"}}>
              <Button  onClick={deliveryHandleOpen}
              style = {{
                  background: "transparent",
                  boxShadow: "none"
                }}>
                Add A Delivery
              </Button>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "Address",
                  accessor: "address",
                  className: "center"
                },
                {
                  Header: "Customer Phone",
                  accessor: "customerPhone",
                },
                {
                  Header: "Livetrack URL",
                  accessor: "liveTrackURL",
                  Cell: e =><a href={e.value} target="_blank"> {e.value} </a>
                },
                {
                  Header: "Delivery Person Phone",
                  accessor: "deliveryGuyPhone",
                },
                {
                  Header: "Distance",
                  accessor: "distance",
                },
                {
                  Header: "Delivery Status",
                  accessor: "deliveryStatus",
                }
              ]}
              data={deliveryData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  </div>
  );
}
