

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export default function CreateAgentPage() { 

    return (
        <>
          <Card>
            <CardBody>
             
            
            <GridContainer justify="space-between"
                style={{
                color: "grey",
                justifyContent: "center",
                height: "200px",
                display: "inline-block",
                textAlign: "center"
                }}>
                <h3>Our agent builder isn't available to everyone just yet. We're testing and validating with a few users</h3>
                <br />
                <h5>
                Join the waitlist, and we'll review your use case. If it's compelling and in demand, we'll invite you: &nbsp;
                <a href="https://forms.gle/SyEnELJmsqWmdxfB8" target="_blank" style={{ display: 'inline-flex', alignItems: 'center' }}>
                    Join Waitlist
                    <OpenInNewIcon style={{ fontSize: '1rem', marginLeft: '4px' }} />
                        </a> 
                        &nbsp;
                </h5>
            </GridContainer>
    
            </CardBody>
  
          </Card>
        </>
      );

}
