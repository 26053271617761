import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AddIcon from "@material-ui/icons/Add";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

import {
  NavLink
} from "react-router-dom";
import Danger from "components/Typography/Danger.js";
import Warning from "@material-ui/icons/Warning";

import ReactTable from "components/ReactTable/ReactTable.js";

import GoogleMapReact from 'google-map-react';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import Firebase from 'firebase';
// Firebase.initializeApp(firebaseConfig);

// import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

var delayFactor = 0;

const useStyles = makeStyles(styles);

export default function BillingTables() {
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  const google = window.google;
  const directionsService = new google.maps.DirectionsService();


  const [tableData, setTableData] = React.useState([]);
  const classes = useStyles();

  const [registeredDeliveryPerson, setRegisteredDeliveryPerson] = React.useState([]);
  const [currentUserData, setCurrentUserData] = React.useState();

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            if (element && element.verificationstatus === "done"){
              result.push(`${element.name} Ph: ${element.phone}`)
            }
          }
          setRegisteredDeliveryPerson(result);
        }
      });
    return () => Firebase.database().ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            if (element && element.verificationstatus === "done"){
              result.push(`${element.name} Ph: ${element.phone}`)
            }
          }
          setRegisteredDeliveryPerson(result);
        }
      });
    return () => Firebase.database().ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  const generateLiveTrackURL = (deliveryPersonNumber, customerPhone) => {
    const ciphertext = encodeURIComponent(`${deliveryPersonNumber},${customerPhone}`);
    return `https://livetracking.onroad.app/?cid=${ciphertext}`;
  }

  const calculateDistance = async (source, destination) => {
    return "Pricing Not Setup"
    // return new Promise(resolve => directionsService.route(
    //   {
    //     destination: {
    //       lat: destination.lat,
    //       lng: destination.lng,
    //     },
    //     origin: {
    //       lat: source.lat,
    //       lng: source.lng,
    //     },
    //     travelMode: "DRIVING",
    //   },
    //   (result, status) => {
    //     if (status == "OK") {
    //       resolve({distance: result.routes[0].legs[0].distance.text, duration: result.routes[0].legs[0].duration.text})
    //     }else if (status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
    //       delayFactor++;
    //       setTimeout(function () {
    //         calculateDistance(source, destination);
    //       }, delayFactor * 1000);
    //     }
    //   })
    // );
        
    // var axios = require('axios');

    // var config = {
    //   method: 'get',
    //   url: 'https://maps.googleapis.com/maps/api/distancematrix/json?origins=849VCWC8%2BR9&destinations=San%20Francisco&key=YOUR_API_KEY',
    //   headers: { }
    // };

    // axios(config)
    // .then(function (response) {
    //   //console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   //console.log(error);
    // });
  }

  const calculateCost = (distance) => {
    // if(currentUserData && currentUserData.billing){
    //   return 50 * 10;
    // }
    return "Pricing Not Setup"
  }

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webdeliveries/storeadded/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if(data){
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length-1; index > -1; index--) {
            const element = data[keys[index]];
            result.push({
                  id: keys[index],
                  address: element.address,
                  customerPhone: element.customerphone,
                  liveTrackURL: element.livetrackurl,
                  deliveryGuyPhone: `${element.deliveryguyname} ${element.deliveryguyphone}`,
                  deliveryStatus: element.deliverystatus,
                  distance: element.distance || "Not Ready Yet",
                  cost: element.cost || "Pricing Not Setup Yet",
                })
          }
          setTableData(result)
        }

      });
    return () => Firebase.database().ref(`/webdeliveries/storeadded/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  // React.useEffect(() => {
  //   // if(registeredDeliveryPersonPhone.length > 0){
  //     let finalDeliveries = finalDeliveries || []
  //     for (let index = 0; index < registeredDeliveryPerson.length; index++) {
  //       const fullNameWithPhone = registeredDeliveryPerson[index];
  //       const phone = registeredDeliveryPerson[index].split("Ph: ")[1]
    
  //       Firebase.database()
  //       .ref(`/historyoftrips/${phone}`)
  //       .once('value', async (snapshot) => {
  //         const newData = snapshot.val()
  //         let result = []
  //         if(newData){
  //           const keys = Object.keys(newData);
  //           for (let index = keys.length-1; index > -1; index--) {
  //             const element = newData[keys[index]];
  //             // const directionResult =  calculateDistance(element.addresslatlng, element.deliveryguylatlng);
  //             // const cost = calculateCost(directionResult.distance);
  //             result.push({
  //               id: keys[index],
  //               address: element.address,
  //               customerPhone: element.customerphone,
  //               liveTrackURL: generateLiveTrackURL(phone, element.customerphone),
  //               deliveryGuyPhone: fullNameWithPhone,
  //               deliveryStatus: "delivered",
  //               distance: element.distance || "Not Ready Yet",
  //               cost: element.cost || "Pricing Not Setup Yet",
  //             })
  //           }
  //           // finalDeliveries.concat(result)
  //           setTableData(a => [...a, ...result]) // prepend to React State
  //         }
  //       });
  //     }
     
  // }, [registeredDeliveryPerson.length])

  const deliveryList = () => {
    return (<ReactTable
      columns={[
        {
          Header: "Address",
          accessor: "address",
          className: "center"
        },
        {
          Header: "Customer Phone",
          accessor: "customerPhone",
        },
        {
          Header: "Livetrack URL",
          accessor: "liveTrackURL",
          Cell: e =><a href={e.value} target="_blank"> {e.value} </a>
        },
        {
          Header: "Delivery Person",
          accessor: "deliveryGuyPhone",
        },
        {
          Header: "Delivery Status",
          accessor: "deliveryStatus",
        },
        {
          Header: "Distance",
          accessor: "distance",
        },
        {
          Header: "Cost",
          accessor: "cost",
        }
      ]}
      data={tableData}
    />)
  }
  const noDeliveries = () =>{
    return (
       <GridContainer justify = "space-between"
        style = {{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
         <h3>There are no deliveries yet</h3>
       </GridContainer>
    )
  }
  const handlePayment = () =>{
    window.open("https://buy.stripe.com/8wMcQNaavf5K66kcMR", '_blank');
  }
  return (
    <div>
    <GridContainer>
    <GridItem xs={12} sm={6} md={6} lg={8}>
        <Card>
          <CardHeader>
            <h3 className={classes.cardTitle}>
            Your Pricing Model
            </h3>
            
          </CardHeader>
          <CardBody>
            <b>{currentUserData?.currentplan?.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})}</b>
            <br/>
            <b>Each delivery cost: {currentUserData?.currentplan == "pay as you go" ? "$ " + currentUserData?.perdeliverycost : "Unlimited"}</b>
            <br/>
            <b>Your current offer: {currentUserData?.discount || 0} % Discount</b>
          </CardBody>
         
          {/* <CardFooter stats>
            <div className={classes.stats} style = {{
                  width: "100%",
                  textAlign: "center"
                }}>
              <Button color="danger" onClick={handlePayment()} style={{textAlign: "center"}}>
                Pay
              </Button>
            </div>
          </CardFooter> */}
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader>
            <h2 className={classes.cardTitle} style={{textAlign: "center", padding: 0, margin: 0}}>
            ${currentUserData?.totalCost || 0}
            </h2>
            <div style={{textAlign: "center"}}>
              <small>
                Total Cost
              </small>
            </div>
            
          </CardHeader>
  
          <CardFooter stats>
            <div className={classes.stats} style = {{
                  width: "100%",
                  textAlign: "center"
                }}>
              <Button color="danger" onClick={() => handlePayment()} style={{textAlign: "center"}}>
                Pay
              </Button>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          {/* <CardHeader color="primary" icon>
            <CardIcon color="primary" style={{float: "right", padding: "10px"}}>
              <Button  onClick={handleOpen}
              style = {{
                  background: "transparent",
                  boxShadow: "none"
                }}>
                Add A Delivery
              </Button>
            </CardIcon>
          </CardHeader> */}
          <CardBody>
              { tableData.length > 0 ? deliveryList() : noDeliveries()}
            
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  </div>
  );
}
