import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Heading from "components/Heading/Heading.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Firebase from "firebase";
import Table from "components/Table/Table.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Phone from "@material-ui/icons/Phone";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PersonIcon from "@material-ui/icons/Person";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import Snackbar from "components/Snackbar/Snackbar.js";
import Divider from "@material-ui/core/Divider";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import InfoIcon from '@material-ui/icons/Info';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomTabs from "components/CustomTabs/CustomTabs.js";

var axios = require('axios');

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import GooglePlacesAutocomplete, {
	geocodeByAddress,
  getLatLng,
  geocodeByPlaceId
} from 'react-google-places-autocomplete';
const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  tabRoot: {
    minHeight: '30px', // Set the minimum height for the tabs
    '& .MuiTab-wrapper': { // Target the label wrapper inside tabs
      flexDirection: 'row', // Set to row if you want icons (if used) and text to be in a row
      fontSize: '0.75rem', // Adjust font size to fit the height
    },
  },
};

const useStyles = makeStyles(styles);

export default function Api() {
  const classes = useStyles();
  const [currentUserData, setCurrentUserData] = React.useState();
  const [customerPhone, setCustomerPhone] = React.useState();
  const [customerName, setCustomerName] = React.useState();
  const [buyingcredits, setBuyingcredits] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [autoCompleteAddress, setAutoCompleteAddress] = React.useState();
  const [address, setAddress] = React.useState();
  const [addressLatLng, setAddressLatLng] = React.useState();
  const [addressLocation, setAddressLocation] = React.useState();
  const [addressMainText, setAddressMainText] = React.useState();
  const [postalCode, setPostalCode] = React.useState();
  const [paymentError, setPaymentError] = React.useState(null);
  const [paymentProcessing, setPaymentProcessing] = React.useState();
  const [tl, setTL] = React.useState(false);
  const [creditError, setCreditError] = React.useState(false); // New state for managing credit input validation
  const [activeTab, setActiveTab] = React.useState(0);

  const stripe = useStripe();
  const elements = useElements();

  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  
  React.useEffect(() => {
     const onChildAdd = Firebase.database()
       .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
       .on('value', snapshot => {
         const data = snapshot.val()
         if (data) {
           setCurrentUserData(data);
         }
       });
     return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
   }, [])  
  const [data, setData] = React.useState([]);


  React.useEffect(() => {
    if (currentUserData && currentUserData.method === 'subscription') { 
      setActiveTab(1);
    }
  }, [currentUserData]);
  const saveCard = async () => {
    try {
        if(!buyingcredits ){
          setPaymentError("Please Enter A Valid Credit Value, Min 100")
          setBuyingcredits(null)
          return ;
        }
        if(buyingcredits < 100 ){
          setPaymentError("Please Enter A Valid Credit Value, Min 100")
          setBuyingcredits(null)
          return ;
        }
      
        if (isNaN(buyingcredits)) {
          setPaymentError("Please Enter A Valid Credit Value, Min 100");
          setBuyingcredits(null)
          return;
        } 
    
        const cardElement = elements.getElement(CardElement);

        if (!cardElement || !cardElement._complete) {
          setPaymentError("Please enter card details");
          setBuyingcredits(null)
          return;
        }
        setPaymentError(null);
        setPaymentProcessing(true)
        
        const amount = Math.ceil(buyingcredits/10);
        
        var config = {
          method: 'post',
          url: 'https://us-central1-gptconsole.cloudfunctions.net/stripeCreatePaymentIntentV2',
          headers: { 
            'Content-Type': 'application/json',
            'id': amount * 100
          }
        };
    
        // console.log("-----req-----", config)
        const resp = await axios(config)
        // console.log("-----resp-----", resp)

        if (!resp || (resp && !resp.data)) {
          if (!tl) {
            setTL(true);
            setTimeout(function () {
              setTL(false);
            }, 6000);
          }
          setPaymentError("Something Went Wrong, Please try again. You are not charged")
          setPaymentProcessing(false)
          return;
        }
  
        const {paymentIntent, error} = await stripe
        .confirmCardPayment(resp.data.client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: user.email,
            },
          },
        })
        if (error) {
          if (!tl) {
            setTL(true);
            setTimeout(function () {
              setTL(false);
            }, 6000);
          }
          setPaymentProcessing(false);
          setPaymentError(error.message)
        }
      if (paymentIntent && paymentIntent.status === "succeeded") {
          const totalcredits = Number(currentUserData.availablecredits || 0) + Number(buyingcredits)

          Firebase.database()
          .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
          .once('value', snapshot => {
            snapshot.ref.update({
              isbillingenabled: true,
              availablecredits:totalcredits
            })
          }); 
        setPaymentProcessing(false)
        setBuyingcredits(null)
        setOpen(false)
          // setOrderPlaced(true);
        }
         
      } catch (error) {
        if (!tl) {
          setTL(true);
          setTimeout(function () {
            setTL(false);
          }, 6000);
        }
        setBuyingcredits(null)
        setPaymentError(true)
        setPaymentProcessing(false)

        console.error(error);
      }    
  }

  const createStripeSubscription = async (email, paymentMethodId) => {

    let myHeaders = new Headers();
    myHeaders.append("Authorization", currentUserData.apikey);
    myHeaders.append("Content-Type", "application/json");
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email: email,
        payment_method_id: paymentMethodId,
      }),
      redirect: 'follow'
    };
  
    //// console.log("=====calling====", activeBuildId);
  
      const response = await fetch("https://us-central1-gptconsole.cloudfunctions.net/createStripeSubscription", requestOptions);
      const subscription = await response.json();
    return subscription;
  };

  const cancelStripeSubscription = async (email, subscriptionId) => {

    let myHeaders = new Headers();
    myHeaders.append("Authorization", currentUserData.apikey);
    myHeaders.append("Content-Type", "application/json");
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email: email,
        subscriptionId: subscriptionId,
      }),
      redirect: 'follow'
    };
  
    //// console.log("=====calling====", activeBuildId);
  
      const response = await fetch("https://us-central1-gptconsole.cloudfunctions.net/cancelStripeSubscription", requestOptions);
      const subscription = await response.json();
    return subscription;
  };


  const saveSubscription = async () => {
    try {
  
    
      event.preventDefault();
      setPaymentProcessing(true);
    
      const cardElement = elements.getElement(CardElement);
    
      const {paymentMethod, error: methodError} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {name: user.email},
      });
    
      // console.log("----paymentMethod--", paymentMethod);

      if (methodError) {
        // console.log("---errror===", methodError);
        setPaymentError(methodError.message);
        setPaymentProcessing(false);
      } else {
        const subscription = await createStripeSubscription(user.email, paymentMethod.id);
        // console.log("--subscription----", subscription);
        if (subscription && subscription.status === "active") {
          Firebase.database()
          .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
          .once('value', snapshot => {
            snapshot.ref.update({
              isbillingenabled: true,
              method: "subscription",
              subscription: subscription
            })
          }); 
        setPaymentProcessing(false)
        setBuyingcredits(null)
        setOpen(false)
          // setOrderPlaced(true);
        }
        // Handle subscription response
        setPaymentProcessing(false);
      }

      
         
      } catch (error) {
        if (!tl) {
          setTL(true);
          setTimeout(function () {
            setTL(false);
          }, 6000);
        }
        setBuyingcredits(null)
        setPaymentError(true)
        setPaymentProcessing(false)

        console.error(error);
      }    
  }
  
  const cancelSubscription = async () => {
    try {
  
      setPaymentProcessing(true);      
        const subscription = await cancelStripeSubscription(user.email, currentUserData.subscription.id);
         Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}/method`).set(null)
        setPaymentProcessing(false)
        setBuyingcredits(null)
        setOpen(false)
        setPaymentError(null)
        setPaymentProcessing(false);
         
      } catch (error) {
        if (!tl) {
          setTL(true);
          setTimeout(function () {
            setTL(false);
          }, 6000);
        }
        setBuyingcredits(null)
        setPaymentError(true)
        setPaymentProcessing(false)

        console.error(error);
      }    
  }

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/api/routeoptimization/${user.email.replace(/\./g,",")}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            result.push([element.isscheduled.toString(), element.routeoptimizationresult, element.totaldeliveries, element.totaldeliverypersons])
          }
          setData(result);
        }

      });
    return () => Firebase.database().ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);
  }, [])  
  
  const customerPhoneChange = (e) => {
    setCustomerPhone(`${currentUserData.countrycode}${e.target.value}`);
  }

  const customerNameChange = (e) => {
    setCustomerName(`${e.target.value}`);
  }
  const creditsToBuyChange = (e) => {
    setPaymentError(null)
    const value = parseInt(e.target.value, 10);
    setCreditError(value < 100);
    setBuyingcredits(value)
  }

  const handleClose = () => {
    setBuyingcredits(null)
    setPaymentError(null)
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true)
  }

  const getCoordinates = async (address, placeId) => {

    geocodeByAddress(address.value.description)
      .then(async (data) => {
        const [place] = await geocodeByPlaceId(address.value.place_id);
      const { long_name: postalCode = '' } =
        place.address_components.find(c => c.types.includes('postal_code')) || {};
        setPostalCode(postalCode)
        // //// console.log('LAT: ', data.lat);
        // //// console.log('LNG: ', data.lng);
        setAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setAddressLocation(address.value.structured_formatting.secondary_text)
          setAddressMainText(address.value.structured_formatting.main_text)
        }

      })
  };

  const authorLines = [
    "Free Trial: 100 Credits on Sign-Up",
    "Flexi-Plan: $1 for 10 Credits, Top-Up Anytime",
    "Or $100/month for unlimited access to all agents"
  ];

  const payAsYouGoContent = (
    <>
        <DialogTitle id="form-dialog-title">Buy credits </DialogTitle>
        <DialogContent style={{ minHeight: "400px" }}>
        <h4>Number of Credits</h4>
        <CustomInput
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses,
          }}
          inputProps={{
            type: "number",
            style: { fontSize: '24px', textAlign: "center" },
            placeholder: "Enter number of credits,Min 100...",
            onChange: (e) => {
              if (e.target.value <= 0) {
                e.target.value = null;
              }
              creditsToBuyChange(e);
            }
          }}
          />
        {creditError && <p style={{ color: 'red' }}>Minimum purchase is 100 credits.</p>}

        <br />
          &nbsp;
      
          <br />
          &nbsp;	
          <div style={{backgroundColor: "#f3f5f9", paddingLeft: "7%",  paddingTop: "20px", backgroundClip: "border-box", paddingRight: "20px"} }>
             <GridContainer>
                <GridItem xs={1} sm={1} md={1}>
                <InfoIcon />
                </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                  
                    <span>Conversion Rate: </span>
                    <p>
                    10 Credits = $1
                  
                    </p>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  ${buyingcredits/10}
                </GridItem>
                <br />
               
                <Divider style={{border: "0", clear:"both", display:"block", width: "96%", height: "1px"}} />
                <br />                
                <GridItem xs={1} sm={1} md={1}>
                  <LocalOfferIcon />
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                    <span>Total cost</span>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                <span style={{ fontWeight: "900" }}>${buyingcredits / 10}</span>
                  
                </GridItem>                
              </GridContainer>
          </div>
          <br />
          &nbsp;
      
          <br />
          <CardElement options={{
                  // style: {
                  //   base: inputStyle,
                  // },
                }}
          />
        
        </DialogContent>
        <DialogActions>
        {paymentProcessing && <CircularProgress />}
          <Button onClick={handleClose} color="primary" disabled={paymentProcessing}>
            Cancel
          </Button>
          
          <Button onClick={()=> {saveCard()}} color="primary" disabled={!buyingcredits || paymentProcessing}>
           Confirm Purchase
          </Button>
        </DialogActions>
    </>
  );

  const unlimitedAccessContent = (
    <>
      <DialogTitle id="form-dialog-title">Unlimited Access Subscription</DialogTitle>
      <DialogContent>
        <div style={{ backgroundColor: "#f3f5f9", padding: "20px 7%", backgroundClip: "border-box" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <InfoIcon style={{ marginRight: "10px" }} />
                <p>Get unlimited access to all agents</p>
              </div>
            </GridItem>
  
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <InfoIcon style={{ marginRight: "10px" }} />
                <p>2 hours of senior developer consulting per month</p>
              </div>
            </GridItem>
  
            <Divider style={{ border: "0", width: "100%", height: "1px", marginBottom: "20px" }} />
  
            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center" }}>
              <LocalOfferIcon style={{ marginRight: "10px" }} />
              <span>Total cost</span>
              <span style={{ marginLeft: "auto", fontWeight: "900" }}>$100/month</span>
            </GridItem>
          </GridContainer>
        </div>
        <br />
        {!(currentUserData && currentUserData.method === 'subscription') && (
          <CardElement options={{}} />
        )}
      </DialogContent>
      <br />
      {currentUserData && currentUserData.method === 'subscription' ? (
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={paymentProcessing}>
            Close
          </Button>
          <Button onClick={() => { cancelSubscription() }} color="danger" disabled={paymentProcessing}>
            Cancel Subscription
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          {paymentProcessing && <CircularProgress />}
          <Button onClick={handleClose} color="primary" disabled={paymentProcessing}>
            Close
          </Button>
          <Button onClick={() => { saveSubscription() }} color="primary" disabled={paymentProcessing}>
            Confirm Purchase
          </Button>
        </DialogActions>
      )}
    </>
  );
  
  
  const getTabName = (baseName, isCurrent) => {
    return (
      <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        {baseName}
        {isCurrent && (
          <span style={{ fontSize: '12px', fontWeight: 'bold', color: 'yellow' }}>
            (Current)
          </span>
        )}
      </span>
    );
  };
  
  
  // Continue with the rest of your code as before...
  
  // Determine if the user has "unlimited" method
  const isUnlimitedAccessCurrent = currentUserData?.method === 'subscription';
  const tabs = [
    {
      tabName: getTabName("Pay As You Go", !isUnlimitedAccessCurrent),
      // tabIcon: ChatIcon,
      tabContent: payAsYouGoContent,
    },
    {
      tabName: getTabName("Unlimited Access", isUnlimitedAccessCurrent),
      // tabIcon: SettingsIcon,
      tabContent: unlimitedAccessContent,
    },
  ];
  return (
    <div>
       <Snackbar
        place="tc"
        color="rose"
        message="Oops! There was an issue processing your payment. Please check your payment details and try again. If the problem persists, consider using a different payment method or contacting our customer service for assistance"
        open={tl}
        closeNotification={() => setTL(false)}
        close
      />
       <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Purchase Options</DialogTitle>
      <DialogContent style={{ minHeight: "400px" }}>
        <CustomTabs
            headerColor="success"
            value={activeTab}
          tabs={tabs}
        />
      </DialogContent>
      {/* <DialogActions>
        {paymentProcessing && <CircularProgress />}
        <Button onClick={handleClose} color="primary" disabled={paymentProcessing}>
          Cancel
        </Button>
      </DialogActions> */}
      {paymentError && <p style={{ color: "red", textAlign: "center" }}>{paymentError}</p>}
    </Dialog>
      {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Buy credits </DialogTitle>
        <DialogContent style={{ minHeight: "400px" }}>
        <h4>Number of Credits</h4>
        <CustomInput
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses,
          }}
          inputProps={{
            type: "number",
            style: { fontSize: '24px', textAlign: "center" },
            placeholder: "Enter number of credits,Min 100...",
            onChange: (e) => {
              if (e.target.value <= 0) {
                e.target.value = null;
              }
              creditsToBuyChange(e);
            }
          }}
          />
        {creditError && <p style={{ color: 'red' }}>Minimum purchase is 100 credits.</p>}

        <br />
          &nbsp;
      
          <br />
          &nbsp;	
          <div style={{backgroundColor: "#f3f5f9", paddingLeft: "7%",  paddingTop: "20px", backgroundClip: "border-box", paddingRight: "20px"} }>
             <GridContainer>
                <GridItem xs={1} sm={1} md={1}>
                <InfoIcon />
                </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                  
                    <span>Conversion Rate: </span>
                    <p>
                    10 Credits = $1
                  
                    </p>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  ${buyingcredits/10}
                </GridItem>
                <br />
               
                <Divider style={{border: "0", clear:"both", display:"block", width: "96%", height: "1px"}} />
                <br />                
                <GridItem xs={1} sm={1} md={1}>
                  <LocalOfferIcon />
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                    <span>Total cost</span>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                <span style={{ fontWeight: "900" }}>${buyingcredits / 10}</span>
                  
                </GridItem>                
              </GridContainer>
          </div>
          <br />
          &nbsp;
      
          <br />
          <CardElement options={{
                  // style: {
                  //   base: inputStyle,
                  // },
                }}
          />
        
        </DialogContent>
        <DialogActions>
        {paymentProcessing && <CircularProgress />}
          <Button onClick={handleClose} color="primary" disabled={paymentProcessing}>
            Cancel
          </Button>
          
          <Button onClick={()=> {saveCard()}} color="primary" disabled={!buyingcredits || paymentProcessing}>
           Confirm Purchase
          </Button>
        </DialogActions>
        {paymentError ? (<p style={{color: "red", textAlign: "center"}}>{paymentError}</p>) : null}
      </Dialog> */}
      <Heading
        textAlign="center"
        title="Settings"
        
      />
      <Card>
        <CardBody>
          <div className={classes.typo}>
            <div className={classes.note}>Plans</div>
            <Quote
              style={{fontSize: "10px"}}
              author={authorLines.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < authorLines.length - 1 && <br />}
                </React.Fragment>
              ))}
            />

          </div>
          <div className={classes.typo}>
            <div className={classes.note}>Credits</div>
            <div style={{ position: "relative" }}>
              <Card style={{marginBottom: "0px", width: "45%"}}>
                <CardBody>
                  <b>Available Credits: {currentUserData?.method ? "Unlimited" :  currentUserData?.availablecredits}</b>
                </CardBody>
                {/* <CardBody>
                  **** **** **** {currentUserData?.billing?.card?.last4}, {currentUserData?.billing?.card?.exp_month}/{currentUserData?.billing?.card?.exp_year}
                  <br />
                  Default
                </CardBody> */}
              </Card>
               </div>

            {/* <Quote
              style={{fontSize: "10px"}}
              
              author = "Onroad Database"
            /> */}
          </div>
          <div className={classes.typo}>
            <div className={classes.note}>Plans</div>
            <div style={{ position: "relative" }}>
              <Card style={{marginBottom: "0px", width: "45%"}}>
                <CardBody>
                  Current Plan: <b>{currentUserData?.method ? `${currentUserData?.method}: Unlimited Access` : "Flexi-Plan: $1 for 10 Credits, Top-Up Anytime"} </b> 
                </CardBody>
                
              </Card>
              <Button 
                size="sm" 
                onClick={handleOpen} 
                color="primary" 
                style={{
                  fontWeight: 'bold',
                  backgroundColor: "#1976d2", // Use a color that matches your theme.
                  color: "#ffffff",
                  marginTop: "10px",
                  marginBottom: "10px",
                  boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
                  transition: "all 0.3s ease-out",
                  '&:hover': {
                    backgroundColor: "#115293", // Darken button on hover for better interaction feedback
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                  }
                }}
              >
                Choose Your Plan
              </Button>

              <br  />
              <span style={{fontSize: "8px", textAlign: "center"}}>Powered By Stripe SSL Protected PCI Compliant and Encrypted </span>
            </div>

          </div>
          <div className={classes.typo}>
            <div className={classes.note}>Usage</div>
            <div style={{ position: "relative" }}>
             
              {/* <b>AugotGPT Cloud</b>
              <br />
              {" "} - Usage: {currentUserData?.usage?.autogpt?.hours} hours 
              <br />
              {" "} - Cost: ${0.022 * (currentUserData?.usage?.autogpt?.hours || 0)}
              <br /> */}
              <b>Doodle: {1 * (currentUserData?.usage?.doodle?.operations || 0)}</b><span style={{ fontSize: "8px", textAlign: "center" }}>{ " "}Credits</span>
              <br />
              <b>Bird: {1 * (currentUserData?.usage?.bird?.operations || 0)}</b><span style={{ fontSize: "8px", textAlign: "center" }}>{" "}Credits</span>
              {/* <br />
              {" "} - Usage: {currentUserData?.usage?.bird?.operations || 0} Tasks
              <br />
              {" "} - Credits: {1 * (currentUserData?.usage?.bird?.operations || 0)} */}
              <br />
              <b>Pixie: {10 * (currentUserData?.usage?.pixie?.operations || 0)}</b><span style={{ fontSize: "8px", textAlign: "center" }}>{" "}Credits</span>
              <br />
              <b>Chip: {10 * (currentUserData?.usage?.chip?.operations || 0)}</b><span style={{ fontSize: "8px", textAlign: "center" }}>{" "}Credits</span>
              <br />
              <b>Console: {1 * (currentUserData?.usage?.console?.operations || 0)}</b><span style={{ fontSize: "8px", textAlign: "center" }}>{ " "}Credits</span>
              {/* <br />
              {" "} - Usage: {currentUserData?.usage?.pixie?.operations || 0} Tasks
              <br />
              {" "} - Cost: {10 * (currentUserData?.usage?.pixie?.operations || 0)} */}
              <br />
              <span style={{fontSize: "12px", textAlign: "center"}}>Doodle: 1Credit/Task, Bird: 1Credit/Task, Pixie: 10 Credits/Task, Chip: 10 Credits/Task, Console: 1Credit/Prompt </span>
            </div>

            {/* <Quote
              style={{fontSize: "10px"}}
              
              author = "Onroad Database"
            /> */}
          </div>
          

          <div className={classes.typo}>
            <div className={classes.note}>Profile</div>
            <div style={{ position: "relative" }}>
              <span style={{fontSize: "15px", textAlign: "center"}}>Email: {user?.email}, API Key: {currentUserData?.apikey}</span>
            </div>
          </div>
          <div className={classes.typo}>
            <div className={classes.note}>help?</div>
            <Danger>
              Please send an email to info@gptconsole.ai. For more details
            </Danger>
          </div>
        </CardBody>
      </Card>
      
    </div>
  );
}
