import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import ArrowForward from '@material-ui/icons/ArrowForward';
import TwitterIcon from '@material-ui/icons/Twitter';
import CodeIcon from '@material-ui/icons/Code';
import WebIcon from '@material-ui/icons/Web';
import ExtensionIcon from '@material-ui/icons/Extension';
import BrushIcon from '@material-ui/icons/Brush';

import MemoryIcon from '@material-ui/icons/Memory';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
  NavLink
} from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar.js";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import Firebase from 'firebase';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles(styles);
const serverTimestamp = Firebase.database.ServerValue.TIMESTAMP;

export default function AgentsPage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }

  const [currentUserData, setCurrentUserData] = React.useState();
  const [tr, setTR] = React.useState(false);
  
  React.useEffect(() => {
    // console.log("=======user.email-----", user.email.replace(/\./g, ","))
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        // console.log("======data===", data)
        if (data) {
          // console.log("======setting data===", data)
          setCurrentUserData(data);
        }        
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
        <Snackbar
          place="tr"
          color="danger"
          message="Your plan changed, we will send you email on next steps"
          open={tr}
          closeNotification={() => setTR(false)}
          close
        />
          {/* <h2 className={classes.title}>Free Credits: 1000</h2> */}
          <h5 className={classes.description} color="rose">
            Autonomous Agents Available For You
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised={true}>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>
              <a href="https://doodlecollective.gptconsole.ai" target="_blank" class="mt-2 inline-block bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                  Doodle <OpenInNewIcon style={{ fontSize: '10px', marginLeft: '4px' }} />
                </a>
              </h6>
              <div className={classes.icon}>
                <BrushIcon className={classes.iconRose} />
              </div>
              <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                Art Is Art
              </h4>
              <p className={classes.cardDescription}>
                Agent that creates art from your text
              </p>
              <NavLink to={"/admin/doodle"} style={{color: "white"}}>
              <Button round color="rose" >
                  Doodle &nbsp; <ArrowForward />
                </Button>
              </NavLink>
              <p style={{fontSize: "10px"}}>Artists, Beginners, learners, and every curious soul—step into the world of creativity!</p>
            </CardBody>
          </Card>
        </GridItem>
        
      {currentUserData?.agents?.pixie && (<GridItem xs={12} sm={12} md={3}>
          <Card pricing raised={true}>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>
                
                <a href="https://landingpages.gptconsole.ai" target="_blank" class="mt-2 inline-block bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                Pixie <OpenInNewIcon style={{ fontSize: '10px', marginLeft: '4px' }} />
                </a>
              </h6>
              <div className={classes.icon}>
                <WebIcon className={classes.iconRose} />
              </div>
              <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                Auto Web Architect
              </h4>
              <p className={classes.cardDescription}>
                Autonomous Web Developer Building Apps for You
              </p>
              <NavLink to={"/admin/pixie"} style={{color: "white"}}>
              <Button round color="rose" >
                  Pixie &nbsp; <ArrowForward />
                </Button>
              </NavLink>
              <p style={{fontSize: "10px"}}>Dashboards, AI-Applications, Landing pages for now</p>
            </CardBody>
          </Card>
      </GridItem>)}
      {currentUserData?.agents?.chip && (<GridItem xs={12} sm={12} md={3}>
          <Card pricing raised={true}>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>
              <a href="https://chip.gptconsole.ai" target="_blank" class="mt-2 inline-block bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                Chip <OpenInNewIcon style={{ fontSize: '10px', marginLeft: '4px' }} />
                </a>
              </h6>
              <div className={classes.icon}>
                <MemoryIcon className={classes.iconRose} />
              </div>
              <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                Code Companion
              </h4>
              <p className={classes.cardDescription}>
              Learns your entire codebase & answers your questions
              </p>
              <NavLink to={"/admin/chip"} style={{color: "white"}}>
            <Button round color="rose">
                  Chip &nbsp; <ArrowForward />
              </Button>
              </NavLink>
              {/* <NavLink to={"/admin/chip"} style={{color: "white"}}>
                <Button round color="rose" disabled>
                  Chip &nbsp; <ArrowForward />
                </Button>
              </NavLink> */}
            <p style={{fontSize: "10px"}}>You must connect to your github repo</p>
            </CardBody>
          </Card>
        </GridItem>)}

      </GridContainer>
      <GridContainer justify="center">
      {currentUserData?.agents?.bird && (<GridItem xs={12} sm={12} md={3}>
          <Card pricing raised={true}>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Bird</h6>
              <div className={classes.icon}>
                <TwitterIcon className={classes.iconRose} />
              </div>
              <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                Tweet Maestro
              </h4>
              <p className={classes.cardDescription}>
                Autonomous agent tweeting and replying for you.
              </p>
              <NavLink to={"/admin/bird"} style={{color: "white"}}>
              <Button round color="rose" >
                  Bird &nbsp; <ArrowForward />
                </Button>
              </NavLink>
              <p style={{fontSize: "10px"}}>Currently command line only</p>
            </CardBody>
          </Card>
        </GridItem>)}
      {currentUserData?.agents?.bird && (<GridItem xs={12} sm={12} md={3}>
        <Card pricing raised={true}>
          <CardBody pricing>
            <h6 className={classes.cardCategory}>Console</h6>
            <div className={classes.icon}>
                <CodeIcon className={classes.iconRose} />
            </div>
            <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
              Command Line Genius
            </h4>
            <p className={classes.cardDescription}>
            Access LLMs directly via command line
            </p>
            <NavLink to={"/admin/console"} style={{color: "white"}}>
            <Button round color="rose" >
                Console &nbsp; <ArrowForward />
              </Button>
              </NavLink>
              <p style={{fontSize: "10px"}}>Currently Command line only</p>

          </CardBody>
        </Card>
        </GridItem>)}
        {currentUserData?.agents?.chip && (<GridItem xs={12} sm={12} md={3}>
        <Card pricing raised={true}>
          <CardBody pricing>
            <h6 className={classes.cardCategory}>A-Pie</h6>
            <div className={classes.icon}>
              <ExtensionIcon className={classes.iconRose} />
            </div>
            <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
            Instant App Builder
            </h4>
            <p className={classes.cardDescription}>
            Generates fully functional app by stitching together relevant APIs
            </p>
            {/* <NavLink to={"/admin/chip"} style={{color: "white"}}>
          <Button round color="rose">
                Chip &nbsp; <ArrowForward />
            </Button>
            </NavLink> */}
            {/* <NavLink to={"#"} style={{color: "white"}}> */}
              <Button round color="rose" disabled>
                A-Pie &nbsp; <ArrowForward />
              </Button>
            {/* </NavLink> */}
          <p style={{fontSize: "10px", color: "red"}}>Arriving Soon! Check back next week.</p>
          </CardBody>
        </Card>
        </GridItem>)}
        <br />
       
       
        <div style={{ width: "100%", textAlign: 'center', marginTop: '20px' }}>
        <div><small>Want to feature your agent on our platform? Join our <a href="https://forms.gle/SyEnELJmsqWmdxfB8">Waitlist</a> now!</small></div>
        <div><small>Stay tuned! We're adding more innovative agents—both open-source and commercial—from developers like you.</small></div>
      </div>
        {/* {currentUserData?.agents?.autogpt && (<GridItem xs={12} sm={12} md={3}>
        <Card pricing raised={true}>
          <CardBody pricing>
            <h6 className={classes.cardCategory}>AutoGPT Cloud</h6>
            <div className={classes.icon}>
              <CloudIcon className={classes.iconRose} />
            </div>
            <h4 className={`${classes.cardTitle} ${classes.marginTop30}`}>
              Ready To Use AutoGPT
            </h4>
            <p className={classes.cardDescription}>
              Preinstalled AutoGPT instance at your service
            </p>
            
              <NavLink to={"/admin/autogptcloud"} style={{color: "white"}}>
                <Button round color="rose">
                AutoGPTCloud &nbsp; <ArrowForward />
                </Button>
            </NavLink>
          </CardBody>
        </Card>
      </GridItem>)}   */}
{/* 
        <div style={{width: "55%"}}><small>Coming soon: autonomous agents that create YouTube videos from descriptions, create websites from UI images, review your GitHub pull requests, and tackle 90% of dev work from Jira/Trello ticket descriptions. Stay tuned!</small></div> */}

      </GridContainer>
    </div>
  );
}
