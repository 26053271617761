
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useState, useEffect, useRef } from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components

// @material-ui/icons
// import InfoOutline from "@material-ui/icons/InfoOutline";
// core components

import TimerIcon from '@material-ui/icons/Timer';
import ChatIcon from '@material-ui/icons/Chat';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { NavLink } from 'react-router-dom';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Typography } from '@material-ui/core';


import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";

import Snackbar from "components/Snackbar/Snackbar.js";

import ClampLines from 'react-clamp-lines';
import CircularProgress from '@material-ui/core/CircularProgress';
import ApiDocumentationTabs from "./ApiDocumentationTabs.js";
// import InfoOutline from "@material-ui/icons/InfoOutline";
// @material-ui/core components

import { Grid, Tabs, Tab, Box, Paper } from '@material-ui/core';

// Icons
import CodeIcon from '@material-ui/icons/Code';
import CloudIcon from '@material-ui/icons/Cloud';
import ComputerIcon from '@material-ui/icons/Computer';

import {
  useStripe,
  useElements
} from '@stripe/react-stripe-js';




// import { cardTitle } from "assets/jss/material-dashboard-pro-js";
// core components
import Heading from "components/Heading/Heading.js";
import Firebase from "firebase";
const SMBase64 = require('smbase64')
let base64 = new SMBase64()

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);




export default function DoodlePage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
 
  const [currentUserData, setCurrentUserData] = useState();
  const [activeTab, setActiveTab] = useState(0);

  const elements = useElements();
  const stripe = useStripe();


  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  function toTitleCase(str) {
    return str.toLowerCase().replace(/(^|\s)\S/g, (c) => c.toUpperCase());
  }
  function truncate(str) {
    return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
  }
  
  // Helper function to capitalize the first letter of each sentence.
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  const agentChat = () => {
 
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [remixImage, setRemixImage] = useState();
    const [remixCode, setRemixCode] = useState();
    const [generatingGIF, setGeneratingGIF] = useState();
    const [remixOriginalPrompt, setRemixOriginalPrompt] = useState();
    const [generatingResponseInProgress, setGeneratingResponseInProgress] = useState(false);
    const [startingNewProject, setStartingNewProject] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const textFieldDivRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState(false);

  
    const handleCopyToClipboard = (url) => {
      navigator.clipboard.writeText(url)
        .then(() => {
          setSnackbarOpen(true); // Open the snackbar on successful copy
          setTimeout(() => setSnackbarOpen(false), 3000); // Automatically close after 3 seconds
        })
        .catch(err => {
          console.error('Failed to copy:', err);
        });
    }


    useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // The empty div is visible, indicating the user has scrolled to the bottom
              setShowButton(false); // Hide the button
            } else {
              // The div is not visible, user is not at the bottom
              setShowButton(true); // Show the button
            }
          });
        },
        {
          root: null, // assuming the viewport is the scroll container
          rootMargin: '0px',
          threshold: 1.0, // Adjust this value based on how much of the div must be visible to consider it intersecting
        }
      );

      if (textFieldDivRef.current) {
        observer.observe(textFieldDivRef.current);
      }

      return () => {
        if (textFieldDivRef.current) {
          observer.disconnect();
        }
      };
    }, []);

    useEffect(() => {
      if (user.email) { 
        
        const onChildAdd = Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`)
        .limitToLast(30)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            const messagesArray = Object.entries(data).map(([key, value]) => ({ key, ...value }));
            //const lastStartIndex = messagesArray.map((e, i) => e.prompt === "Start New Project" ? i : -1).lastIndexOf(Math.max(...messagesArray.map((e, i) => e.prompt === "Start New Project" ? i : -1)));
            
            let lastStartIndex = -1;
            for (let i = 0; i < messagesArray.length; i++) {
                if (messagesArray[i].prompt.trim().toLowerCase() === "start new project") {
                    lastStartIndex = i;
                }
            }
           // const lastStartIndex = messagesArray.map((e, i) => e.prompt.trim().toLowerCase() === "start new project" ? i : -1).lastIndexOf(Math.max(...messagesArray.map((e, i) => e.prompt.trim().toLowerCase() === "start new project" ? i : -1)));

            // Filter out the messages after the last "Start New Project"
            const filteredMessages = messagesArray.slice(lastStartIndex + 1);
            // Convert the filtered messages back to an object
            let newMessages;
            if (messagesArray.length == 1) {
              newMessages = messagesArray.reduce((acc, current) => {
                acc[current.key] = current;
                //delete acc[current.key].key; // Remove the key from the value object
                return acc;
              }, {});
            } else {
              newMessages = filteredMessages.reduce((acc, current) => {
                acc[current.key] = current;
                //delete acc[current.key].key; // Remove the key from the value object
                return acc;
              }, {});
              
            }
            //// console.log("===newMessages====", newMessages)

            //// // console.log("====newMessages===>", newMessages)
            setMessages(newMessages);
            // //// // console.log("====data===>", data)
          }
        });
        return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`).off('value', onChildAdd);  
      }
    }, [user.email])

    function normalizeLineBreaks(str) {
      return str.replace(/\n\n/g, '\n');
    }

    const handleInputChange = (e) => {
      const value = e.target.value;
      if (value.length > 5000) {
          setError(true);
      } else {
          setError(false);
      }
      setInputText(value);
  };


    const NoCreditsMessage = () => {
      return (
        <>
          <br />
          <p style={{textAlign: "center", padding: "5px"}}>You currently have no credits available. Please {" "}
            <NavLink to="/admin/settings">
            <Button style={{
              color: 'inherit', // Use the current text color
              backgroundColor: 'transparent', // Ensure the button has a transparent background
              boxShadow: 'none', // No shadow for a flat appearance
              textTransform: 'none', // Prevent uppercase text transformation
              padding: 0, // Remove padding to mimic inline text
              minWidth: 0, // Allow the button to shrink to the size of its content
            }}>
              Purchase credits
              </Button>
              
            </NavLink>
            {" "} to continue using our services.</p>
        </>
      );
    };



    function formatRecentMessages(messages) {
      const keys = Object.keys(messages).reverse(); // Assuming the latest keys are at the end
      const numberOfMessages = keys.length;
      let formattedString = '';
    
      for (let i = 0; i < Math.min(numberOfMessages, 3); i++) {
        const message = messages[keys[i]];
        const order = i + 1;
        const title = `${order}. ${order === 1 ? "Most Recent (last)" :
                       order === 2 ? "Second Most Recent (last-1)" :
                       "Third Most Recent (last-2)"}`;
    
        formattedString += `${title}\n   Prompt: "${message.prompt}"\n   Response: "${message.code}"\n\n`;
      }
    
      return formattedString.trim();
    }


    

    const handleSend = async () => {
      try {
        
        setGeneratingResponseInProgress(true);
        
       
          let myHeaders = new Headers();
          myHeaders.append("Authorization", currentUserData.apikey);
          myHeaders.append("Content-Type", "application/json");
        let previous = ""
        if (remixImage) {
          previous = `Most Recent (last): \n
                      Prompt: "${remixOriginalPrompt}"\n
                      Response: "${remixCode}"\n\n`;
        } else {
          formatRecentMessages(messages)
          }
          
        
          let raw = JSON.stringify({ email: user.email, prompt: inputText, previousString: previous });
          let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
        
        const a = await fetch("https://us-central1-gptconsole.cloudfunctions.net/generateDoodle", requestOptions)
        
          let result = await a.json()
          setInputText('')
          setGeneratingResponseInProgress(false);
        setRemixImage(null)
        setRemixCode(null)
        setRemixOriginalPrompt(null)
       
        ////////// // console.log("====result2===>", a2)
      } catch (e) { 
        // // console.log("====error===>", e)
        setInputText('')
        setGeneratingResponseInProgress(false);
        setRemixImage(null)
        setRemixCode(null)
        setRemixOriginalPrompt(null)

      }
    };

    const handleNewProject = async () => {
      try {
        setMessages([]);
        setStartingNewProject(true);
        await Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`).push({ 
          prompt: "Start New Project",
        });
        setStartingNewProject(false);
      } catch (e) {
        setMessages([]);
  
        setStartingNewProject(false);
      }
    }

    function extractCanvasDimensions(html) {
      // This regex is more flexible with whitespace and also looks for possible other parameters or spaces
      const setupFunctionMatch = html.match(/createCanvas\s*\(\s*(\d+)\s*,\s*(\d+)/);
      if (setupFunctionMatch && setupFunctionMatch.length >= 3) {
          const width = parseInt(setupFunctionMatch[1], 10); // Ensure the captured string is converted to integer
          const height = parseInt(setupFunctionMatch[2], 10); // Same as above
          return { width, height };
      } else {
          return { width: 500, height: 500 }; // Default size if no match found
      }
    }
        
    const handleDownload = async (url, code) => {
      if(generatingGIF) return;
      try {
        setGeneratingGIF(true)
        const dimensions = extractCanvasDimensions(code);

        let myHeaders = new Headers();
        myHeaders.append("Authorization", currentUserData.apikey);
        myHeaders.append("Content-Type", "application/json");
      
      
        let raw = JSON.stringify({ email: user.email, url: url, width: dimensions.width, height: dimensions.height});
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        const a = await fetch("https://us-central1-gptconsole.cloudfunctions.net/generateGIF", requestOptions)
        let result = await a.json()
        if (result.url) {
          // Open the URL in a new tab
          window.open(result.url, '_blank');
          setGeneratingGIF(false)

        } else {
          setGeneratingGIF(false)

        }
        setGeneratingGIF(false)

      } catch (e) {
        setGeneratingGIF(false)
      }
    }
    return (<>
       <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.5);
            }
            100% {
              transform: scale(1);
            }
          }

          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          
          .fadeInUp {
            animation: fadeInUp 1s ease-out forwards;
          }

          .buttonOutline {
            min-width: '300px';
            marginRight: '20px';
            justifyContent: "flex-start";
            boxShadow: 'none';
            textTransform: 'none';
            border: '1px solid rgba(0, 0, 0, 0.23)';
            paddingLeft: 8;
            paddingRight: 8;
            color: "grey";
            backgroundColor: 'transparent';
          }
          
          .buttonOutline:hover {
            border-color: black; /* Change outline to black on hover */
          }
          
        `}
      </style>

      <Card> 
      <Snackbar
              place="tr" // top-right
              color="success"
              message="URL copied to clipboard!"
              open={snackbarOpen} 
              closeNotification={() => setSnackbarOpen(false)}
            />
      {messages && Object.keys(messages).length > 0 ? (
        <CardHeader color="primary" icon>
          <CardIcon color="rose" style={{ float: "right", padding: "0px",  background: "gray",
                boxShadow: "none", }}>
            <Button 
              onClick={() => handleNewProject()}
                size="sm"
                color="gray"
              style={{
                background: "gray",
                boxShadow: "none",
                padding: "5px",
                fontSize: "12px",
                fontWeight: "600",
                color: "white",
              }}
                disabled={generatingResponseInProgress}
              >
              Start Fresh
              </Button>
              
            </CardIcon>
        </CardHeader>
      ): ""}
        <CardBody>
          {startingNewProject && (
            <div style={{
              display: 'inline-block',
              margin: '10px',
              width: '20px',
              height: '20px',
              backgroundColor: 'black',
              borderRadius: '50%',
              animation: 'pulse 1.5s infinite ease-in-out'
            }}></div>
          ) }
          {!startingNewProject && messages && Object.keys(messages).length == 0 && (<GridContainer
              justify="space-between"
              style={{
                color: "grey",
                justifyContent: "center",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              <h3>
              {"What vision can I bring to life for you?"}
            </h3>
            <p>
            <small>Doodle transforms your ideas into art</small>
            <br />
            <small>Experience the beauty of functional art, interact with your creation, and share</small>
              </p>
          </GridContainer>)}
          
          <div  style={{ width: '100%', marginTop: '20px' }}>
            {messages && Object.entries(messages).filter(([key, chatMessage]) => !chatMessage.resp).map(([key, chatMessage], index) => (
            <div key={key}>
             
              <div style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap' }}>
                <div style={{ fontWeight: 'bold' }}>You</div>
                {/* <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                  {chatMessage.prompt}
                  </div> */}
          <div style={{ 
                    padding: '10px', 
                    paddingRight: '60px',
                background: '#f0f0f0', 
                borderRadius: '15px', 
                position: 'relative' // Add relative positioning for absolute positioned children
              }}>
                    <div style={{
                      fontSize: '1rem',
                      fontWeight: '400',
                      position: 'absolute', // Position the text button absolutely
                      top: '10px', // Position it 10px from the top
                      right: '10px', // Position it 10px from the right
                      color: 'blue', // Set the text color to blue
                      cursor: 'pointer' // Change cursor to pointer to indicate it's clickable
                    }}
                      onClick={() => {
                        setRemixImage(chatMessage.image)
                        setRemixCode(chatMessage.code)
                        setRemixOriginalPrompt(chatMessage.prompt)
                        setInputText(chatMessage.prompt)
                        textFieldDivRef.current.scrollIntoView({ behavior: 'smooth' })
                        setShowButton(false)
                      }}
            >
            Remix
                    </div>
                    <ClampLines
                        text={chatMessage?.prompt || ""}
                        id="custom-clamp-id"
                        lines={3}
                        ellipsis="..."
                        moreText="Show more"
                        lessText="Show less"
                        innerElement="div"
                        className="custom-clamp-lines"
                    />
         
        </div>
              </div>

              <br />
                <div style={{ fontWeight: 'bold' }}>Doodle</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <a href={chatMessage.url} target="_blank" style={{ color: "grey", display: 'inline-flex', alignItems: 'center' }}>
                    {chatMessage.url}
                    <OpenInNewIcon style={{ color: "grey", fontSize: '1rem', marginLeft: '4px' }} />
                  </a>
                  <a href="#"
                    style={{ color: "grey", opacity: 0, transition: 'opacity 0.3s' }}
                    className="copy-link"
                    onMouseEnter={e => e.currentTarget.style.opacity = 1}
                    onMouseLeave={e => e.currentTarget.style.opacity = 0}
                    onClick={() => handleCopyToClipboard(chatMessage.url)}
                  >
                    {" "}Copy Link
                  </a>
                </div>
                {!chatMessage?.public && (<a href="#" 
                  style={{ display: 'block', color: 'grey', textDecoration: 'none' }}
                  onClick={(e) => {
                    // Insert your logic to make the post public here
                    //console.log("Post made public", key);
                    Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/${key}/public`).set(true);
                    Firebase.database().ref(`/publicdoodles/`).push({
                      prompt: chatMessage.prompt,
                      code: chatMessage.code,
                      url: chatMessage.url,
                      email: user.email,
                      author: currentUserData?.name,
                    });
                    
                    // Optionally, hide the link after action
                    e.currentTarget.style.display = 'none';
                  }}
                >
                  Share on Homepage
                </a>)}
              </div>

                  <iframe
                    src={chatMessage.url}
                    style={{
                      width: '100%', // Full-width iframe
                      border: 'none', // Remove border
                      borderRadius: '12px', // Consistent border radius with the container
                      height: '600px', // Fixed height for better control over layout
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Slightly more pronounced shadow for the iframe
                    }}
                    title="Doodle Preview"
                />
                
                  <a href="#" 
                    onClick={() => handleDownload(chatMessage.url, chatMessage.code)} // Replace with actual download logic
                    style={{
                      display: 'block', // Block display to take the full width underneath the iframe
                      textAlign: 'right', // Align text to the right
                     
                      color: 'grey', // Stylish, subdued link color
                      textDecoration: 'none', // No underline for a cleaner look
                     
                    }}
                  
                >
                  {generatingGIF && <CircularProgress size={15} style={{ color: 'grey', verticalAlign: 'middle' }} />}
                    Download Gif
                  </a>
            
                </div>
            ))}
            </div>
          
            {generatingResponseInProgress && (<div style={{
                      display: 'inline-block',
                      margin: '10px',
                      width: '20px',
                      height: '20px',
                      backgroundColor: 'black',
                      borderRadius: '50%',
                      animation: 'pulse 1.5s infinite ease-in-out'
            }}></div>)}
              

        
        
          

        {!generatingResponseInProgress && (!messages || messages && Object.keys(messages).length == 0) && (<div >
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('Stick figures running towards each other to hug, hand-drawn style.');
          }}
        >
         Stick figures running towards each other to hug, hand-drawn style.
          </Button>
          <br />
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('A pirate ship sailing on a rainbow-colored ocean with dolphin crewmates');
          }}
        >
          A pirate ship sailing on a rainbow-colored ocean with dolphin crewmates
          </Button>
          <br />
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('Animate a bouncy ball changing colors as it hops around a playground');
          }}
        >
                       
                       Animate a bouncy ball changing colors as it hops around a playground
          </Button>
              <br />
               
              
          </div>)}
          
          {/* <div style={{ textAlign: 'center' }}>
              <small>It's merely a doodle, no need to broodle</small>
          </div> */}
          <div style={{ width: '100%', marginTop: '40px' }}></div>
          {!startingNewProject && (<div>
              <TextField
                ref={textFieldDivRef}
              fullWidth
              multiline
              rows={remixImage ? 10 : 2}
              rowsMax={9}
              variant="outlined"
              value={inputText}
              onChange={handleInputChange}
              onKeyUp={(e) => {
                if (e.key === 'ArrowUp' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
              InputProps={{
                startAdornment: remixImage ? (
                  <InputAdornment position="start">
                    <img src={remixImage} alt="Remix" style={{ width: 200, height: 'auto', marginRight: 5 }} />
                  </InputAdornment>
                ) : null,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      onClick={handleSend}
                      disabled={error || generatingResponseInProgress || (!currentUserData?.method && currentUserData?.availablecredits < 10) || inputText.trim() === ''} 
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={error}
              helperText={error ? 'Maximum characters exceeded (5000)' : ''}
              FormHelperTextProps={{
                  style: {
                      color: 'red', // This will make the text red
                      position: 'absolute', // Position absolutely
                      bottom: '-20px' // Position below the text field
                  }
              }}              style={{ borderRadius: 20 }} // Rounded corners
              disabled={generatingResponseInProgress || (!currentUserData?.method && currentUserData?.availablecredits < 10)}    
              />
              {!currentUserData?.method && currentUserData?.availablecredits < 10 && <NoCreditsMessage />}

          </div>)}
      </CardBody>
   
      </Card>
      {showButton && (
        <IconButton
            onClick={() => {
              textFieldDivRef.current.scrollIntoView({ behavior: 'smooth' })
              setShowButton(false)
            }}
          style={{
            position: 'fixed',
            bottom: '10%', // Adjust according to your needs
            right: '40%', // Adjust according to your needs
            zIndex: 1000, // Make sure it's above other items
            backgroundColor: 'gray', // Set background color to gray
            borderRadius: '50%', // Make it round
            color: 'white', // Optional: change the icon color to white for contrast
            padding: '10px', // Optional: adjust padding to ensure the round shape is visible if needed
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)' // Optional: add a slight shadow for better visibility
          }}
        >
          <ArrowDownwardIcon />
        </IconButton>
      )}
     
      </>
  );

  }

  const historicalGenerations = () => { 
    const [allCreations, setAllCreations] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {
      if (user.email) { 
        
        const onChildAdd = Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`)
        .limitToLast(20)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            const messagesArray = Object.entries(data).filter(([key, value]) => value.prompt !== "Start New Project").map(([key, value]) => ({ key, ...value })).reverse(); // Continue mapping as before

            setAllCreations(messagesArray);           
          }
        });
        return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`).off('value', onChildAdd);  
      }
    }, [user.email])

    const noLifeCycleEvents = () => {
      return (
        <GridContainer justify="space-between"
          style={{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
          <h3>No art pieces have been found today. The doodle agent has not been used yet</h3>
    
        </GridContainer>
      )
    }

    // const [loading, setLoading] = useState(false);
    // const [lastVisible, setLastVisible] = useState(null);

  //   const fetchMessages = (lastVisible) => {
  //     console.log("Fetching messages...", lastVisible);
  //     setLoading(true);
  
  //     // let query = Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`)
  //     //                               .limitToLast(10);
  
  //     // if (lastVisible) {
  //     //     query = query.startAfter(lastVisible); // Fetch entries after the last visible entry from previous fetch
  //     // }

  //     let query = Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`)
                              
                              

  //     if (lastVisible) {
  //         console.log("Querying after key:", lastVisible);
  //         query = query.startAfter(lastVisible);
  //     }

  
  //     query.on('value', snapshot => {
  //       const data = snapshot.val();
  //       console.log("Received data:", data);  // Log the raw data received from the query
    
  //       if (data) {
  //           const messagesArray = Object.entries(data)
  //               .map(([key, value]) => ({ key, ...value }))
  //               .filter(msg => msg.prompt !== "Start New Project")
  //               .reverse();
    
  //           console.log("Processed messages:", messagesArray);  // Debug output to inspect processed data
    
  //           if (messagesArray.length > 0) {
  //               const newLastVisible = messagesArray[messagesArray.length-1].key;
  //               console.log("New lastVisible key:", newLastVisible);  // Ensure this key is correct
  //               setLastVisible(newLastVisible);
  //               setMessages(prev => [...prev, ...messagesArray]);
  //           } else {
  //               console.log("No new messages to add.");
  //           }
  //           setLoading(false);
  //       } else {
  //           console.log("No data returned for query.");
  //           setLoading(false);
  //       }
  //   });
    
  
  //     return () => query.off('value');
  // };
  
//   function fetchMessages(lastVisible) {
//     setLoading(true); // Assume setLoading manages the loading state

//     // Start the base query
//     let query = Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`)
//                                   .orderByKey()
//                                   .limitToFirst(10);

//     // If there is a lastVisible key, adjust the query to start after this key and fetch the next 10 items
//     if (lastVisible) {
//         console.log("Querying after key:", lastVisible);
//         query = query.startAfter(lastVisible);
//     }

//     // Listen for the value event
//     query.once('value', snapshot => {
//         const data = snapshot.val();
//         console.log("Received data:", data);

//         if (data) {
//             const messagesArray = Object.entries(data)
//                 .map(([key, value]) => ({ key, ...value }))
//                 .filter(msg => msg.prompt !== "Start New Project")
//                 .reverse();

//             console.log("Processed messages:", messagesArray);

//             if (messagesArray.length > 0) {
//                 const newLastVisible = messagesArray[0].key;
//                 console.log("New lastVisible key:", newLastVisible);
//                 setLastVisible(newLastVisible);
//                 setMessages(prev => [...prev, ...messagesArray]);
//             } else {
//                 console.log("No new messages to add.");
//             }
//             setLoading(false);
//         } else {
//             console.log("No data returned for query.");
//             setLoading(false);
//         }
//     });
// }
    // Function to fetch initial or additional messages
  //   const fetchMessages = (lastVisible) => {
  //     console.log("Fetching messages...");
  //     setLoading(true);
  //     let query = Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`)
  //                       .limitToLast(10);
      
  //     if (lastVisible) {
  //         query = query.startAt(lastVisible);
  //     }
  
  //     query.on('value', snapshot => {
  //         const data = snapshot.val();
  //         if (data) {
  //             // Convert the snapshot data to an array and filter out entries with "Start New Project"
  //             const messagesArray = Object.entries(data)
  //                 .map(([key, value]) => ({ key, ...value }))
  //                 .filter(msg => msg.prompt !== "Start New Project")
  //                 .reverse();  // Reverse the order of messages for display purposes
  
  //             if (messagesArray.length > 0) {
  //                 const newLastVisible = messagesArray[0].key; // Adjust to get the key of the first message after reversal
  //                 setLastVisible(newLastVisible);
  //                 // Concatenate new messages to the existing ones
  //                 setMessages(prev => [...messagesArray, ...prev]); // Note the change here to maintain the correct order after reversal
  //             }
  //             setLoading(false);
  //         }
  //     });
  
  //     return () => query.off('value');
  // };
  

    // useEffect(() => {
    //     if (user.email) { 
    //         fetchMessages();
    //     }

    //     // Cleanup on unmount
    //     return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/`).off();
    // }, [user.email]);

  //   useEffect(() => {
  //     const handleScroll = () => {
  //         // Calculate how far the user has scrolled
  //         const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  //         const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
  //         const clientHeight = document.documentElement.clientHeight || window.innerHeight;
  //         const reachedBottom = scrollTop + clientHeight >= scrollHeight - 10; // Consider near bottom with a threshold of 10px
  
  //         console.log("Scroll Top:", scrollTop, "Client Height:", clientHeight, "Scroll Height:", scrollHeight, "Reached Bottom:", reachedBottom);
  
  //         if (reachedBottom && !loading) {
  //             console.log("Fetching more messages...");
  //             fetchMessages(lastVisible);
  //         }
  //     };
  
  //     // Attach the event listener
  //     window.addEventListener('scroll', handleScroll, { passive: true });
  
  //     // Clean up the event listener
  //     return () => window.removeEventListener('scroll', handleScroll);
  // }, [loading, lastVisible]); // Ensure dependencies are correctly listed
  


    
    const handleCopyToClipboard = (url) => {
      navigator.clipboard.writeText(url)
        .then(() => {
          setSnackbarOpen(true); // Open the snackbar on successful copy
          setTimeout(() => setSnackbarOpen(false), 3000); // Automatically close after 3 seconds
        })
        .catch(err => {
          console.error('Failed to copy:', err);
        });
    }

    return (
      <>
        <Card>
          <CardBody>
            {/* <Typography variant="h4" gutterBottom>
            Gallery of Wonders
            </Typography> */}
            
            <Snackbar
              place="tr" // top-right
              color="success"
              message="URL is copied to clipboard!, You can share it in your social media!"
              open={snackbarOpen} 
              closeNotification={() => setSnackbarOpen(false)}
            />
          <GridContainer>

              {allCreations && allCreations.length> 0 ? allCreations.map((creation, index) => (
                <GridItem xs={12} sm={12} md={4}>
                <Card product className={classes.cardHover}>
                  <CardHeader image className={classes.cardHeaderHover}>
                     
                  <a href={creation.url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={creation.image}
                      loading="lazy"
                      style={{
                        width: '100%', // Full-width image
                        border: 'none', // Remove border
                        borderRadius: '12px', // Consistent border radius with the container
                        height: '200px', // Fixed height for better control over layout
                        objectFit: 'cover', // Ensures the image covers the area
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Slightly more pronounced shadow for the image
                      }}
                      alt="Doodle Preview"
                    />
                  </a>

                      {/* <iframe
                        src={creation.url}
                        loading="lazy"
                        style={{
                          width: '100%', // Full-width iframe
                          border: 'none', // Remove border
                          borderRadius: '12px', // Consistent border radius with the container
                          height: '200px', // Fixed height for better control over layout
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Slightly more pronounced shadow for the iframe
                        }}
                        title="Doodle Preview"
                      /> */}
                      {/* <a href={creation.url} target="_blank" >
                        {creation.url} 
                        <OpenInNewIcon style={{ fontSize: '1rem', marginLeft: '4px' }} />
                      </a> */}
                      {/* <a href={creation.url} target="_blank" style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          width: '100%',
                          height: '100%',
                          zIndex: '10',
                          cursor: 'pointer', // Makes the div behave like a link
                          textDecoration: 'none', // Remove link underlining
                          backgroundColor: 'transparent', // Ensure the overlay is invisible
                          border: 'none', // No border for the overlay
                          outline: 'none', // No focus outline
                        }}>
                          <span style={{ visibility: 'hidden' }}>Open in new tab</span>
                        </a> */}
                      {/* <a href="#" 
                        style={{ color: "grey", opacity: 0, transition: 'opacity 0.3s' }} // Use opacity for smoother transitions
                        className="copy-link"
                        onMouseEnter={e => e.currentTarget.style.opacity = 1} // Show on hover
                        onMouseLeave={e => e.currentTarget.style.opacity = 0} // Hide when not hovered
                        onClick={() => handleCopyToClipboard(creation.url)}
                      >
                                          {" "}Copy Link
                      </a> */}
                  </CardHeader>
                    <CardBody>
                    
                      
                    <p className={classes.cardProductDesciprion}>
                    <ClampLines
                        text={creation?.prompt || ""}
                        id="custom-clamp-id"
                        lines={2}
                        ellipsis="..."
                        moreText="Show more"
                        lessText="Show less"
                        innerElement="div"
                        className="custom-clamp-lines"
                        />
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
  <a href="#" 
    style={{
      background: '#9c27b0', // Light grey background
      color: 'white', // Dark text color for contrast
      fontWeight: '600',
      paddingLeft: '8px',
      paddingRight: '8px',
      borderRadius: '13px',
      borderRadius: '16px', // Fully rounded edges
      textDecoration: 'none', // No underline
      transition: 'background-color 0.3s', // Smooth transition for hover effects
      fontSize: '10px',
      cursor: 'pointer' // Pointer cursor on hover
    }}
    onClick={() => handleCopyToClipboard(creation.url)}
  >
    Share
  </a>

  {!creation?.public && (
    <a href="#" 
      style={{
        background: '#4caf50',
        color: 'white',
        fontWeight: '600',
        paddingLeft: '8px',
        paddingRight: '8px',
        borderRadius: '13px',
        textDecoration: 'none',
        transition: 'background-color 0.3s',
        cursor: 'pointer',
        fontSize: '10px',
      }}
      onClick={(e) => {
        //console.log("Post made public", creation);

        Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/doodle/weblogs/${creation.key}/public`).set(true);

        Firebase.database().ref(`/publicdoodles/`).push({
          prompt: creation.prompt,
          code: creation.code,
          url: creation.url,
          email: user.email,
          author: currentUserData?.name,
        });
        e.currentTarget.style.display = 'none';
      }}
    >
      Share on Homepage
    </a>
  )}
</div>

                      
                      
                  </CardBody>
                </Card>
                </GridItem>
                

                
              )) : noLifeCycleEvents()}

            </GridContainer>
          </CardBody>

        </Card>
      </>
    );
  }

  const developerSettings = () => {
   
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    tabContent: {
      padding: theme.spacing(2),
    },
    tabLabel: {
      fontSize: '0.8rem', // Smaller font size
    },
    root2: {
      padding: theme.spacing(2),
      backgroundColor: '#e0e0e0', // Grey background
      color: '#000000', // Black font color
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    button: {
      marginLeft: theme.spacing(2),
    }
    
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`api-tabpanel-${index}`}
        aria-labelledby={`api-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `api-tab-${index}`,
      'aria-controls': `api-tabpanel-${index}`,
    };
  }

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    
  
  
    // Function to copy API key to clipboard
    const handleCopy = () => {
      navigator.clipboard.writeText(currentUserData?.apikey)
    };
  return (
    <div className={classes.root}>
      <div className={classes.root2}>
      <Typography variant="body1">
          Your API Key: {currentUserData?.apikey}
        </Typography>
       
      <Button variant="contained" color="primary" onClick={handleCopy} className={classes.button}>
        Copy
        </Button>
        
        </div>
      <div square>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="API documentation tabs"
        variant="fullWidth" // Ensures tabs take up equal width
      >
        <Tab label="CURL" className={classes.tabLabel} {...a11yProps(0)} />
        <Tab label="JavaScript" className={classes.tabLabel} {...a11yProps(1)} />
        <Tab label="Python" className={classes.tabLabel} {...a11yProps(2)} />
      </Tabs>
    </div>
      <TabPanel value={value} index={0}>
      <Typography variant="body2" component="div">
    <pre>
      {`curl --location 'https://api.gptconsole.ai' \\
      --header 'Authorization: ${currentUserData?.apikey}' \\
      --header 'Content-Type: application/json' \\
      --data-raw '{"email": ${user.email}, "prompt": "cute puppy being happy face"}'`}
    </pre>
  </Typography>

      </TabPanel>
      
      <TabPanel value={value} index={1}>
        <Typography variant="body2" component="pre">
          {`
        
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "${currentUserData?.apikey}");
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({ email: "${user.email}", prompt: "chipmunk with nuts in mouth"});
      
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch('https://api.gptconsole.ai', requestOptions)
          .then(response => response.json())
          .then(result => console.log(result))
          .catch(error => console.error(error));`
          
          }
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Typography variant="body2" component="pre">
      {`import http.client
        import json

        conn = http.client.HTTPSConnection('api.gptconsole.ai')
        payload = json.dumps({
          'email': "${user.email}",
          'prompt': 'chipmunk with nuts in mouth'
        })
        headers = {
          'Authorization': "${currentUserData?.apikey}",
          'Content-Type': 'application/json'
        }

        conn.request('POST', '/', payload, headers)
        res = conn.getresponse()
        data = res.read()
        print(data.decode('utf-8'))`}
    </Typography>
      </TabPanel>


    </div>
  );
    
    
  }
  const agentSettings = () => {
    const doodleConfig = {
      lifespan: "Indefinite",
      capabilities: {
        staticImages: true,
        animatedGifs: false,
        styleTransfer: true
      },
      goal: "Create visually appealing artworks",
      techStack: {
        programmingLanguage: "JavaScript",
        libraries: ["p5.js", "three.js"]
      },
      imageGenerationMethod: "Algorithmic pattern creation, neural networks"
    };
    


    return (
      <>
        <Card>  
          <CardBody>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <div style={{ padding: "30px", fontSize: "15px" }}>Lifespan</div>
                <div style={{ padding: "30px", fontSize: "15px" }}>Capabilities</div>
                <div style={{ padding: "30px", fontSize: "15px" }}>Goal</div>
                <div style={{ padding: "30px", fontSize: "15px" }}>Tech Stack</div>
                <div style={{ padding: "30px", fontSize: "15px" }}>Image Generation Method</div>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <div style={{ padding: "30px", fontSize: "15px" }}>{doodleConfig.lifespan}</div>
                <div style={{ padding: "30px", fontSize: "15px" }}>
                  Static Images: {doodleConfig.capabilities.staticImages ? "Enabled" : "Disabled"},
                  Animated GIFs: {doodleConfig.capabilities.animatedGifs ? "Enabled" : "Disabled"},
                  Style Transfer: {doodleConfig.capabilities.styleTransfer ? "Enabled" : "Disabled"}
                </div>
                <div style={{ padding: "30px", fontSize: "15px" }}>{doodleConfig.goal}</div>
                <div style={{ padding: "30px", fontSize: "15px" }}>{doodleConfig.techStack.programmingLanguage}, {doodleConfig.techStack.libraries.join(", ")}</div>
                <div style={{ padding: "30px", fontSize: "15px" }}>{doodleConfig.imageGenerationMethod}</div>
              </GridItem>
            </GridContainer>
            
          </CardBody>

          
        </Card>
      </>
    );    
  };


  const timeCardScreen = () => {
    return (
      <>
        <div style={{ alignItems: "center", justifyContent: "center", padding: "0px", marginTop: "-50px" }}>
          <Heading
            textAlign="center"
            title="Doodle"
            className={classes.buttonLink}
            style={{ padding: "0px", margin: "0px", margin: "0px!important" }}
          // category={currentUserData?.apikey}
          />
        </div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              headerColor="success"
              // customFunction={customFunctionDP}
              tabs={[
                {
                  tabName: "Doodle Chat",
                  tabIcon: ChatIcon,
                  tabContent: (
                    <>
                      {agentChat()}
                    </>
                  ),
                },
                {
                  tabName: "Your Creations",
                  tabIcon: TimerIcon,
                  tabContent: (
                    <>
                      {historicalGenerations()}
                    </>
                  ),
                },
                {
                  tabName: "Developer",
                  tabIcon: SettingsIcon,
                  tabContent: (
                    <>
                      {developerSettings()}
                    </>
                  ),
                },
                {
                  tabName: "Agent Configuration",
                  tabIcon: SettingsIcon,
                  tabContent: (
                    <>
                      {agentSettings()}
                    </>
                  ),
                },
                // {
                //   tabName: "Task Details",
                //   tabIcon: DashboardIcon,
                //   tabContent: (
                //     <>
                //       {agentTaskDetails()}
                //     </>
                //   ),
                // },
              ]}
            >
            </CustomTabs>
          </GridItem>
        </GridContainer>
      </>
    )
  }
  return (
    <div>
      {timeCardScreen()}
    </div>
  );
}
