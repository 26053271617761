import React from "react";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import { NavLink, useLocation } from "react-router-dom";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Snackbar from "components/Snackbar/Snackbar.js";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import PinDropIcon from '@material-ui/icons/PinDrop';
import BuildIcon from '@material-ui/icons/Build';
import RoomIcon from '@material-ui/icons/Room';
import AndroidIcon from '@material-ui/icons/Android';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import Code from "@material-ui/icons/Code";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import Group from "@material-ui/icons/Group";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import BusinessIcon from '@material-ui/icons/Business';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import CryptoJS from 'crypto-js'

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const useStyles = makeStyles(styles);
import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  IfFirebaseUnAuthed,
  IfFirebaseAuthed,
  FirebaseAuthConsumer,
  IfFirebaseAuthedAnd
} from "react-firebase-auth-provider";

const config = {
  apiKey: "AIzaSyBjFBb39m0ii_nbnSNt-xtS8TmZoAwO45U",
  authDomain: "gptconsole.firebaseapp.com",
  databaseURL: "https://gptconsole-default-rtdb.firebaseio.com",
  projectId: "gptconsole",
  storageBucket: "gptconsole.appspot.com",
  messagingSenderId: "730500216840",
  appId: "1:730500216840:web:af0f001f83f5ac051ff9bd",
  measurementId: "G-V9C0SLHQLC"
}


export default function RegisterPage() {
  const [isRegister, setIsRegister] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState(false);
  const [organizationName, setOrganizationName] = React.useState();
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [currentUser, setCurrentUser] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [emailVerificaitonSent, setEmailVerificaitonSent] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState();
  const [countryFlag, setCountryFlag] = React.useState();
  const [locationData, setLocationData] = React.useState();
  const [phone, setPhone] = React.useState();

  const location = useLocation();
  const code = location.state && location.state.code;
  console.log("register page   Code: ", code);

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
            // const logintype = params.get('logintype');

            ////// console.log("====logintype====", params);
  const generateKey = (lowerCaseEmail) => {
    const key = 'OnroadApiKey';
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
  
    const enc = CryptoJS.AES.encrypt(lowerCaseEmail, keyutf, {
      iv: iv
    });
    const encString = enc.toString()
                   .replace(/\./g,'p3Ri')
                   .replace(/\$/g,'D0La')
                   .replace(/\[/g,'53Ft')
                   .replace(/\]/g,'R18t')
                   .replace(/\#/g,'H3Sh')
                   .replace(/\//g,'S1Sh');
    return encString
  }
  
  const organizationNameChange = (e) => {
    setOrganizationName(e.target.value)
  }
  const emailChange = (e) => {
    setEmail(e.target.value)
  }
  const phoneChange = (e) => {
    setPhone(`${countryCode}${e.target.value}`)
  }
  const passwordChange = (e) => {
    setPassword(e.target.value)
  }
  const resendEmailVerificationLink = () => {
    currentUser.sendEmailVerification();
    // setResendEmailVerificaitonSent(true)
    setEmailVerificaitonSent(true);
  }
  const generateApiKey = () =>{
    return 
  }
  const registerUser = () => {
      
     firebase.auth().createUserWithEmailAndPassword(email, password)
       .then((userCredential) => {
         
         var user = userCredential.user;
         setCurrentUser(user);
         user.updateProfile({
           displayName: organizationName
         }).then(function () {
             const queryEmail = email.toLowerCase().replace(/\./g, ",");
             const lowerCaseEmail = email.toLowerCase();
             let ref = firebase.database()
               .ref(`/webadmin/${queryEmail}`);
                ref.set({
                agents: {                 
                  autogpt: true, 
                  bird: true,
                  chip: true, 
                  pixie: true
                 },
                 email: email,
                 name: organizationName,
                 phone: phone || " ",
                 locationdata: locationData || " ",
                 countrycode: countryCode || " ",
                 countryflag: countryFlag || " ",
                 initialcredits: 100,
                 availablecredits: 100,
                 usedcredits: 0,
                 apikey: generateKey(lowerCaseEmail),
                 currentplan: "pay as you go",
                 apicost: 1
               })
              
               var actionCodeSettings = {
                url: 'https://agent.gptconsole.ai/',
                handleCodeInApp: true,
              };
              //// console.log("====sending email verification====")
              user.sendEmailVerification()
                .then(function() {
                  // console.log("Verification email sent.");
                })
                .catch(function(error) {
                  //// console.log("Error occurred while sending verification email.");
                });
          }, function (error) {
            // An error happened.
          });
       })
       .catch((error) => {
         var errorCode = error.code;
         var errorMessage = error.message;
         setErrorMessage(errorMessage);
         // ..
       });
    
    
    
     
    //// console.log("register user", organizationName, email, password)
  }
  const loginUser = async () => {
    if (forgotPassword) {
      await sendResetPasswordLink();
      setResetPasswordSuccess(true);

      setTimeout(() => {
        setResetPasswordSuccess(false);
      }, 5000);  // 5000ms = 5 seconds      
      setForgotPassword(false);
      return
    }
    //// console.log("-----------loggininng=====", email)

    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        // Signed in
        var user = userCredential.user;
        // //// console.log("-----------user", user.uid)
        
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setErrorMessage(errorMessage);
      });

  }
  const sendResetPasswordLink = async () => { 
    if (!email) { 
      setErrorMessage("Please enter email address")
      return
    }
    const result = await firebase.auth().sendPasswordResetEmail(email)
    return;
  }
  const classes = useStyles();


  const trackOrLocation = () => {
    
      return (
        <>
          
         <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title="Autonomous Agents"
                    description="They are self-governing digital entities capable of performing tasks independently. With GPTConsole, you can create these smart operatives, revolutionizing your approach to problem-solving and task management"
                    icon={AndroidIcon}
                    iconColor="rose"
                  />
                  <InfoArea
                    title="Mechanism of Agents"
                    description = "They operate based on pre-defined parameters and behaviors, acting and reacting to scenarios in real-time. From data analysis to predictive modeling, our agents adapt and learn, becoming smarter with every task"
                    icon={BuildIcon}
                    iconColor="primary"
                  />
                  <InfoArea
                    title="Living with Agents"
                    description=" They provide you with the power to build workflows, enhance productivity, and stay ahead in this rapidly evolving digital world. With GPTConsole, the future of work isn't just an idea - it's a reality you can experience today"
                    icon={SupervisedUserCircleIcon}
                    iconColor="info"
                  />
                </GridItem>
        </>
      )
    }
  const loginForm = () => {
    return (
      <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>{isRegister ? "Register" : "Login"}</h2>
            <CardBody>
              <GridContainer justify="center">
                {trackOrLocation()}
                  <GridItem xs={12} sm={8} md={5}>
                  <div className={classes.center} style={{paddingBottom: "30px"}}>
                    <Button  round color="google" onClick={signInWithGoogle}>
                      <i className="fab fa-google" /> Login with Google
                    </Button>
                      {` `}
                      <br />
                    <Button round color="apple" onClick={signInWithApple}>
                      <i className="fab fa-apple" /> Login with Apple
                    </Button>
                    {` `}
                    </div>
                    <br />
                    <div className={classes.center} >
                    <small>Or use your email to create an account</small>
                    </div>
                    
                    <br />
                    {resetPasswordSuccess && (
                      <SnackbarContent
                      message={
                        'Passsword reset link sent to your email address. Please check your inbox.'
                      }
                      color="success"
                    />)}
                  <form className={classes.form}>
                    {isRegister ? (<CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                          <SupervisedUserCircleIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Name...",
                        onChange: organizationNameChange
                      }}
                    />): null}
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Email...",
                        onChange: emailChange
                      }}
                    />
                    {!forgotPassword && (<CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        placeholder: "Password...",
                        type: "password",
                        onChange: passwordChange
                      }}
                    />)}
                    {/* {isRegister && (<CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                          {countryFlag} {" "} {countryCode}
                          </InputAdornment>
                        ),
                        placeholder: "10 Digit Phone Number(Optional)",
                        onChange: phoneChange
                      }}
                    />)} */}
                    {errorMessage ? (<p style={{color: "red", textAlign: "center"}}>{errorMessage}</p>) : null}
                    <div style={{textAlign: "center", padding: 10}}>
                      {isRegister ? (<span>
                          I already have account {" "}
                          <Button color="transparent" style={{color: "blue"}}onClick={()=>{
                            setIsRegister(false)
                          }}>Login</Button>.
                      </span>) :
                      (!forgotPassword && (<span>
                          I don't have account {" "}
                            <Button color="transparent" style={{color: "blue"}} onClick={()=>{
                            // getCountryCode()
                            setIsRegister(true)
                          }}>Register</Button>.
                      </span>))
                      }
                    </div>
                    {!isRegister ? (<div className={classes.center}>
                      <Button round color="primary" onClick={() => {
                        isRegister ? registerUser() : loginUser()
                      }} disabled={isRegister ? (!organizationName || !email || !password || !phone || phone.length != (10 + countryCode.length)) : (forgotPassword ? (!email) : (!email || !password))}>
                        {isRegister ? "Register" : (forgotPassword ? "Send Password Reset Link" : "Login")}
                        </Button>
                        <div style={{ color: "gray" }}><small><Button color="transparent" style={{color: "#d4167f", padding: "0px", fontSize: "8px"}} onClick={()=>{
                            setForgotPassword(true)
                        }}>Forgot Password </Button> &nbsp;
                        Or Reach us at info@gptconsole.ai </small></div>
                      </div>) : (<div className={classes.center}>
                          <Button round color="primary" onClick={() => {registerUser()}} disabled={ !email || !password }>Register
                          </Button>
                          </div>)}
                    </form>
                   
                  
                    
                  </GridItem>
                  
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
    );
  }
  const loginAgain = () => {
    window.location.reload();
  }
  const emailVerifyScreen = () => {
    // if (resendEmailVerificaitonSent) {
    //   return (<Redirect from="/admin" to="/auth/login-page" />)
    // }
    return (
    <div className={classes.container}>        
        <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Email Verification</h2>
            <CardBody>
              <div className={classes.center}>
                  <h3> Click the link sent to your email to verify. Please allow approximately 1 to 10 minutes for the email to arrive. </h3>
                  {emailVerificaitonSent ? null : <Button round color="primary" onClick={() => { resendEmailVerificationLink() }} disabled={emailVerificaitonSent}>Resend Confirmation</Button>}
                  {emailVerificaitonSent ? null: <div><small>Already verified? </small><a href="#" onClick={loginAgain}>Login Now</a></div>}
                  <br />
                  {emailVerificaitonSent ? <small>Verification Email Sent. Please check your inbox.</small> : null}
                  <br />
                  {emailVerificaitonSent ? <a href="#" onClick={loginAgain}>Login</a> : null}
                </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
    );
  }

  const handleUserLogin = (user) => {
    if (!user) return;
    if (user && !user.email) return;
    const queryEmail = user.email.toLowerCase().replace(/\./g, ",");
    const ref = firebase.database().ref(`/webadmin/${queryEmail}`);
    ref.once('value').then((snapshot) => {
      if (!snapshot.exists()) {
        const lowerCaseEmail = user.email.toLowerCase();
        ref.set({
          agents: {                 
            autogpt: true, 
            bird: true,
            chip: true, 
            pixie: true
          },
          email: user.email,
          name: user.displayName || "Unknown",
          phone: " ",
          locationdata: " ",
          countrycode: " ",
          countryflag: " ",
          initialcredits: 100,
          availablecredits: 100,
          usedcredits: 0,
          apikey: generateKey(lowerCaseEmail),
          currentplan: "pay as you go",
          apicost: 1
        });
      }
    });

    setCurrentUser(user);
  };

  const signInWithGoogle = () => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(googleAuthProvider)
    .then((result) => {
      handleUserLogin(result.user);
    })
    .catch((error) => {
      setErrorMessage(error.message);
    });
  };

  const signInWithApple = () => {
    const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');
    appleAuthProvider.addScope('email');
    appleAuthProvider.addScope('name');
    firebase.auth().signInWithPopup(appleAuthProvider)
    .then((result) => {
      handleUserLogin(result.user);
    })
      .catch((error) => {
      console.log("error----->", error);
      setErrorMessage(error.message);
    });
  };
  return (
    <FirebaseAuthProvider {...config} firebase={firebase}>
       
      <FirebaseAuthConsumer>
        {({ isSignedIn, user, providerId }) => {
          setCurrentUser(user);
          if (isSignedIn && user.emailVerified) {
            const params = new URLSearchParams(location.search);
            const logintype = params.get('logintype');
            if (logintype == "gptconsole") {
              return (
                // <pre style={{ height: 300, overflow: "auto" }}>
                //   {JSON.stringify({ isSignedIn, user, providerId }, null, 2)}
                // </pre>
                <Redirect from="/auth" to="/auth/clilogin" />
                );
            }
            if (code) {
              // const chipPage = `/admin/chip?code=${code}`;
              const chipPage = {
                pathname: '/admin/chip',
                state: { code: code }
              };
              return <Redirect to={chipPage} />;
            } else {
              return <Redirect from="/auth" to="/admin/dashboard" />;
            }

            // return (
            // // <pre style={{ height: 300, overflow: "auto" }}>
            // //   {JSON.stringify({ isSignedIn, user, providerId }, null, 2)}
            // // </pre>
            //   <Redirect from="/auth" to="/admin/dashboard" />
            // );
          }
          if(isSignedIn && !user.emailVerified) {
            return emailVerifyScreen();
          }else{
            return loginForm();
          }
        }}
      </FirebaseAuthConsumer>
     
    </FirebaseAuthProvider>
  );
}


//https://gptconsole.firebaseapp.com/__/auth/handler