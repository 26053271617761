
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from 'react';

// import { useState, useEffect } from "react";
// // react plugin for creating charts
// // react plugin for creating vector maps

// // @material-ui/core components

// // @material-ui/icons
// // import InfoOutline from "@material-ui/icons/InfoOutline";
// // core components
// import Table from "components/Table/Table.js";

// import TimerIcon from '@material-ui/icons/Timer';
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardContent from '@material-ui/core/CardContent';
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import SettingsIcon from '@material-ui/icons/Settings';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Snackbar from "components/Snackbar/Snackbar.js";

import { useLocation, Redirect } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

// import { cardTitle } from "assets/jss/material-dashboard-pro-js";
// core components
import Heading from "components/Heading/Heading.js";
import Firebase from "firebase";
const SMBase64 = require('smbase64')
let base64 = new SMBase64()

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 500,
    margin: 'auto',
    marginTop: '100px',
    textAlign: 'center',
    padding: '20px',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  button: {
    marginTop: '20px',
  },
});
// const useStyles = makeStyles(styles);
// const classes = useStyles();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Unsubscribe() {
  const classes = useStyles();

  const query = useQuery();
  const email = query.get('email');

  if (!email) {
    return <Redirect to="/auth" />;
  }

  const unsubscribeEmail = async (email) => { 
    console.log("Unsubscribe")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({"email": email})
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    await fetch("https://us-central1-gptconsole.cloudfunctions.net/addEmailToUnsubscription", requestOptions)
    setTR(true);
  }
  const [tr, setTR] = React.useState(false);
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Unsubscribe from GPTConsole Emails
        </Typography>
        <Typography variant="body2" component="p">
         We're sorry to see you go! If you no longer wish to receive our emails at <strong>{email}</strong>, please confirm by clicking the button below.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={tr}
          onClick={() => { unsubscribeEmail(email) }}
        >
          Unsubscribe
        </Button>
        <Snackbar
          place="tr"
          color="success"
          message="You have been successfully unsubscribed."
          open={tr}
          closeNotification={() => setTR(false)}
        />
      </CardContent>
    </Card>
  );
}
