
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import React from "react";

// @material-ui/core components
// @material-ui/icons
// core components
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import moment from "moment";
import LaunchIcon from '@material-ui/icons/Launch';
import CheckIcon from '@material-ui/icons/Check';import DescriptionIcon from '@material-ui/icons/Description';
const isValidDomain = require('is-valid-domain')
import Quote from "components/Typography/Quote.js";
import CardFooter from "components/Card/CardFooter.js";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Timeline from "components/Timeline/Timeline.js";
import ChatIcon from '@material-ui/icons/Chat';
import CardTravel from "@material-ui/icons/CardTravel";
import Extension from "@material-ui/icons/Extension";
import Fingerprint from "@material-ui/icons/Fingerprint";
import FlightLand from "@material-ui/icons/FlightLand";
import Build from "@material-ui/icons/Build";
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import WorkIcon from '@material-ui/icons/Work';
import HelpOutline from "@material-ui/icons/HelpOutline";
import ChartistGraph from "react-chartist";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import StorageIcon from '@material-ui/icons/Storage';


import BrandingImageUpload from "components/CustomUpload/BrandingImageUpload.js"
const SMBase64 = require('smbase64')
let base64 = new SMBase64()
// core components
import Datetime from "react-datetime";

import Firebase from 'firebase';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

// @material-ui/core components
// @material-ui/icons
// core components
import Phone from "@material-ui/icons/Phone";
import ReactTable from "components/ReactTable/ReactTable.js";
import GooglePlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
} from 'react-google-places-autocomplete';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

// core components
import Switch from "@material-ui/core/Switch";
import Accordion from "components/Accordion/Accordion.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Heading from "components/Heading/Heading.js";

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PeopleAlt from "@material-ui/icons/PeopleAlt";

// @material-ui/core components

// material-ui icons
import classNames from "classnames";

// core components
import Table from "components/Table/Table.js";
import NavPills from "components/NavPills/NavPills.js";
import TimelineIcon from '@material-ui/icons/Timeline';
import Badge from "components/Badge/Badge.js";
import {mapStyles} from "variables/mapstyles";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import { DataUsageRounded } from "@material-ui/icons";
import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
} from "variables/charts.js";

const styles2 = {
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px",
	  },
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
    },
	cardIconTitle: {
	...cardTitle,
	marginTop: "15px",
	marginBottom: "0px",
	},
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
};


const useStyles1 = makeStyles(styles);

const useStyles2 = makeStyles(styles2);

const useStyles = () => {
  const classes = useStyles1();
  const classes2 = useStyles2();

  return { ...classes, ...classes2 };
};

const iconArray = [Timeline, ChatIcon, CardTravel, Extension, Fingerprint, FlightLand, Build, Dashboard, Schedule, Info, LocationOn, WorkIcon, HelpOutline, TimelineIcon]
const colorArray = ["info", "success", "warning", "danger", "rose"]
const squareMarkerSVG =
      "M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z";
export default function TripsPage() {
  const classes = useStyles();

  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  
  const service = new google.maps.DistanceMatrixService();
  const directionsService = new google.maps.DirectionsService();

  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: false,
    });
    const wrapper = classNames({
      [classes.wrapperRTL]: false,
    });

  const [currentUserData, setCurrentUserData] = React.useState();
  const [teamMembers, setTeamMembers] = React.useState([]);
  const [teamMembersPhone, setTeamMembersPhone] = React.useState([]);
  const [jobs, setJobs] = React.useState([]);
  const [jobsMapData, setJobsMapData] = React.useState([]);
  const [registeredTeammembers, setRegisteredTeammembers] = React.useState([]);
  const [liveTrackData, setLiveTrackData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [deliveryGuyPhone, setDeliveryGuyPhone] = React.useState();
  const [deliveryGuyName, setDeliveryGuyName] = React.useState();
  const [teamLatLng, setTeamLatLng] = React.useState();
  const [cMap, setCMap] = React.useState();
  const [markersRef, setMarkersRef] = React.useState();
  const [connectDomainModal, setConnectDomainModal] = React.useState();
  const [domainName, setDomainName] = React.useState();

  const [tabChange, setTabChange] = React.useState(true);

  const [callFeature, setCallFeature] = React.useState();
  const [tipFeature, setTipFeature] = React.useState();
  const [chatFeature, setChatFeature] = React.useState();
  const [imagesFeature, setImagesFeature] = React.useState();
  const [brandFeature, setBrandFeature] = React.useState();
  const [brandDetails, setBrandDetails] = React.useState();
  const [customDomainDetails, setCustomDomainDetails] = React.useState();

  const [deliveryData, setDeliveryData] = React.useState([]);
  const [deliveryOpen, setDeliveryOpen] = React.useState(false);
  const [address, setAddress] = React.useState();
  const [addressLatLng, setAddressLatLng] = React.useState();
  const [addressLocation, setAddressLocation] = React.useState();
  const [selectedDeliveryPerson, setSelectedDeliveryPerson] = React.useState();
  const [customerPhone, setCustomerPhone] = React.useState();
  const [jobScheduleTime, setJobScheduleTime] = React.useState();
  const [registeredDeliveryPerson, setRegisteredDeliveryPerson] = React.useState([]);
  const [distanceMatrixData, setDistanceMatrixData] = React.useState();
  const [routePreviewPolyline, setRoutePreviewPolyline] = React.useState();
  const [showConnectDomainDetails, setShowConnectDomainDetails] = React.useState();
  const [jobTrackModalOpen, setJobTrackModalOpen] = React.useState(false);
  const [currentJobDetails, setCurrentJobDetails] = React.useState(false);
  const [avgDailyVisits, setAvgDailyVisits] = React.useState();
  const [pieChartData, setPieChartData] = React.useState({ labels: ["100%"],
  series: [100]});
  const [userTimelineData, setUserTimelineData] = React.useState();
  const [userRealtimeActivtyData, setUserRealtimeActivtyData] = React.useState([]);
 

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
    Firebase.database()
      .ref(`/trackconfig/gigjobtrack/${user.email.replace(/\./g, ",")}/`)
      .once('value', snapshot => {
        const data = snapshot.val()
        if(data){
         if(data.call){
          setCallFeature(true)
         }
         if(data.tip){
          setTipFeature(true)
         }
         if(data.chat){
          setChatFeature(true)
         }
         if(data.images){
          setImagesFeature(true)
         }
         if(data.brand && data.brand.enabled) {
          setBrandDetails(data.brand)
          setBrandFeature(true)
         }
         if(data && data.customdomain){
          setCustomDomainDetails(data.customdomain)
         }
        }
      });
  }, [])
  
  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/tracking/gigjobs/teammembers/${user.email.replace(/\./g,",")}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          let tableData = []
          let liveData = []
          let phonesArray = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            if (element && element.verificationstatus === "done"){
              result.push(`${element.name} Ph: ${element.phone}`)
              phonesArray.push(element.phone)
              tableData.push([`name-|-${element.name}-|-key-|-${keys[index]}`, element.phone, element.scheduledassignments[moment().format("YYYY-MM-DD").toString()], element.finishedassignments, `deleteGigjobTeamMember:${element.phone}-|-${user.email.replace(/\./g,",")}`])
              const isSameDay = moment(element.lastupdated).isSame(moment().format("YYYY/MM/DD"));
              liveData.push([`${element.phone}`, "Active", isSameDay ? element.todaysdeliveries : 0])
            }
          }
          setTeamMembersPhone(phonesArray)
          setTeamMembers(tableData);
          setLiveTrackData(liveData)
          setRegisteredTeammembers(result);
        }
      });
    return () => Firebase.database().ref(`/tracking/gigjobs/teammembers/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webgigjobs/storeadded/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if(data){
          let result = []
          let liveData = []
          const keys = Object.keys(data);
          for (let index = keys.length-1; index > -1; index--) {
            const element = data[keys[index]];
            liveData.push(element)
            // result.push([`address-|-${element.addressmaintext || element.address}-|-key-|-${keys[index]}-|-latlng-|-${element.addresslatlng.lat},${element.addresslatlng.lng}`, element.addresslocation, element.teammembername,`deleteGigjobsJob:${keys[index]}-|-${user.email.replace(/\./g,",")}`])
            result.push({
              id: keys[index],
              address: element.address,
              customerPhone: element.destinationphone,
              liveTrackURL: element.weburl,
              teamMember: `${element.teammembername} ${element.teammemberphone}`,
              jobStatus: element.jobstatus,
              distance: element.distance,
              duration: element.duration
            })
          }
          setJobsMapData(liveData)
          setJobs(result);
        }

    });
    return () => Firebase.database().ref(`/webgigjobs/storeadded/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
    for (let index = 0; index < teamMembersPhone.length; index++) {
      const element = teamMembersPhone[index];
      Firebase.database()
      .ref(`/userlivelocationtracking/${element}/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if(data && data.latitude && data.longitude){
          // const myLatlng = new google.maps.LatLng(data.latitude, data.longitude);

          setTeamLatLng(prevCart => ({
            ...prevCart,
            [element]: `${data.latitude},${data.longitude}`
          }));
          // bounds.extend(myLatlng);
        }
        // cMap.fitBounds(bounds);
      });
    }
  // const onChildAdd = Firebase.database()
  //   .ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g,",")}/`)
  //   .on('value', snapshot => {
  //     const data = snapshot.val()
  //     if(data){
  //       let result = []
  //       let liveData = []
  //       const keys = Object.keys(data);
  //       for (let index = keys.length-1; index > -1; index--) {
  //         const element = data[keys[index]];
  //         result.push([element.name, element.phone, element.scheduleddeliveryjobs, element.finisheddeliveryjobs, `deleteDeliveryPerson:${element.phone}-|-${user.email.replace(/\./g,",")}`])
  //         liveData.push([element.name, "Idle"])
  //       }
  //       setData(result);
  //       setLiveTrackData(liveData)
  //     }

  // });
  // return () => Firebase.database().ref(`/webdeliveryteam/storeadded/${user.email.replace(/\./g,",")}/`).off('value', onChildAdd);

}, [teamMembersPhone.length])

  const getCoordinates = (address) => {
    geocodeByAddress(address.value.description)
      .then(results => getLatLng(results[0]))
      .then((data) => {
        // //console.log('LAT: ', data.lat);
        // //console.log('LNG: ', data.lng);
        setAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setAddressLocation(address.value.structured_formatting.secondary_text)
        }
        setAddressLatLng(data)

        const request = {
          origins: [currentUserData.storeaddresslatlng],
          destinations: [data],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        };
      
        directionsService.route(
        {
          destination: data,
          origin: currentUserData.storeaddresslatlng,
          travelMode: "DRIVING",
        },
        (result, status) => {
          if (status == "OK") {
            if(result && result.routes.length > 0 && result.routes[0].overview_polyline){
              setRoutePreviewPolyline(result.routes[0].overview_polyline)
            }
            }
          })
        // get distance matrix response
        service.getDistanceMatrix(request).then((response) => {
          if(response && response.rows && response.rows.length > 0 && response.rows[0].elements.length > 0){
            setDistanceMatrixData(response.rows[0].elements[0])
          }
        })
      })
  };
  const deliveryHandleOpen = () => {
    setDeliveryOpen(true);
  };

  const deliveryHandleClose = () => {
    setDeliveryOpen(false);
    setAddressLatLng(null)
    setAddress(null)
    setJobScheduleTime(null)
    setSelectedDeliveryPerson(null)
    setDeliveryGuyPhone(null)
    setDistanceMatrixData(null)
  };

  const handleNext = () => {
	setShowConnectDomainDetails(true)
  }

  
  const generateLiveTrackURL = (trackID) => {
    if(customDomainDetails && customDomainDetails.status == 'connected') {
      return `https://${customDomainDetails.url.replace(/\,/g,".")}/${trackID}`
    }else{
      return `https://trk.gg/${trackID}`
    }

    // const deliveryPersonNumber = selectedDeliveryPerson.split("Ph: ")[1]
    // const ciphertext = encodeURIComponent(`${deliveryPersonNumber},${customerPhone}`);
    // return `https://livetracking.onroad.app/?cid=${ciphertext}`;
  }
  const getPhoneFromDisplayName = (displayName) => {
    return displayName.split("Ph: ")[1]
  }
  const getNameFromDisplayName = (displayName) => {
    return displayName.split("Ph: ")[0]
  }
  const deliveryHandleSave = () => {

    const serverTimestamp = Firebase.database.ServerValue.TIMESTAMP;
    Firebase.database()
    .ref(`/${user.email.replace(/\./g,",")}/temp/time/`).set(serverTimestamp).then(() => {
      Firebase.database()
      .ref(`/${user.email.replace(/\./g,",")}/temp/time/`)
      .once('value', snapshot => {
        let val = snapshot.val()
        const trackID = base64.fromNumber(val)
        Firebase.database()
        .ref(`/tracking/gigjobs/jobs/${trackID}/`)
        .set({
          "address": address,
          "addresslocation": addressLocation,
          "addressadjustedbycustomer": false,
          "addresslatlng": addressLatLng,
          "customer": user.email.replace(/\./g, ","),
          "teammemberlatlng": currentUserData.storeaddresslatlng,
          "teammemberphone": getPhoneFromDisplayName(selectedDeliveryPerson),
          "teammembername": getNameFromDisplayName(selectedDeliveryPerson),
          "jobreview": "",
          "jobstatus": "not started",
          "destinationphone": customerPhone,
          "discussion": "",
          "images": "",
          "scheduledatetime": jobScheduleTime ? jobScheduleTime : moment().format("YYYY/MM/DD hh:mm A").toString(),
          "tipamount": "", 
          "weburl": customDomainDetails && customDomainDetails.status == 'connected' ? `https://${customDomainDetails.url}/${trackID}`:`https://trk.gg/${trackID}`,
          "tracktype": "gig job",
          "cost": "Pricing Not Setup Yet",
          "distance": (distanceMatrixData && distanceMatrixData.distance) ? `${distanceMatrixData.distance.text},${distanceMatrixData.duration.text}` : "Not Ready Yet",
          "routepreview": routePreviewPolyline,
          "staticmapurl": `https://maps.googleapis.com/maps/api/staticmap?center=${addressLatLng.lat},${addressLatLng.lng}&size=550x300&maptype=roadmap&markers=color:red%7Clabel:A%7C${addressLatLng.lat},${addressLatLng.lng}&path=enc:${routePreviewPolyline}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`
        })
        const ref = Firebase.database()
        .ref(`/webgigjobs/storeadded/${user.email.replace(/\./g, ",")}`)
        .push({
          "address": address,
          "addresslocation": addressLocation,
          "addressadjustedbycustomer": false,
          "addresslatlng": addressLatLng,
          "customer": user.email.replace(/\./g, ","),
          "teammemberlatlng": currentUserData.storeaddresslatlng,
          "teammemberphone": getPhoneFromDisplayName(selectedDeliveryPerson),
          "teammembername": getNameFromDisplayName(selectedDeliveryPerson),
          "jobreview": "",
          "scheduledatetime": jobScheduleTime ? jobScheduleTime : moment().format("YYYY/MM/DD hh:mm A").toString(),
          "jobstatus": "not started",
          "destinationphone": customerPhone,
          "discussion": "",
          "images": "",
          "tipamount": "", 
          "weburl": customDomainDetails && customDomainDetails.status == 'connected' ? `https://${customDomainDetails.url}/${trackID}`:`https://trk.gg/${trackID}`,
          "tracktype": "gig job",
          "cost": "Pricing Not Setup Yet",
          "distance": (distanceMatrixData && distanceMatrixData.distance) ? `${distanceMatrixData.distance.text},${distanceMatrixData.duration.text}` : "Not Ready Yet",
          "routepreview": routePreviewPolyline,
          "staticmapurl": `https://maps.googleapis.com/maps/api/staticmap?center=${addressLatLng.lat},${addressLatLng.lng}&zoom=12&size=550x300&maptype=roadmap&markers=color:red%7Clabel:A%7C${addressLatLng.lat},${addressLatLng.lng}&path=enc:${routePreviewPolyline}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`
            })
      Firebase.database()
        .ref(`/scheduledjobs/${getPhoneFromDisplayName(selectedDeliveryPerson)}/${ref.key}`)
        .set({
          storeid: user.email.replace(/\./g, ","),
          orderid: ref.key,
          address: address,
          lat: addressLatLng.lat,
          lng: addressLatLng.lng,
          phone: customerPhone,
          deliverystatus: "Not Delivered"
        })
      Firebase.database()
        .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
        .once('value', snapshot => {
          let val = snapshot.val()
          if(val && val.totalJobs) {
            snapshot.ref.update({
              totalJobs: parseInt(val.totalJobs) + 1,
            })
          }else{
            snapshot.ref.update({
              totalJobs: 1,
            })
          }
          
        })

      let t = {}
      t[moment().format("YYYY-MM-DD").toString()] = 0
      Firebase.database()
      .ref(`/tracking/gigjobs/teammembers/${user.email.replace(/\./g,",")}/${getPhoneFromDisplayName(selectedDeliveryPerson)}`)
      .once('value', snapshot => {
        let val = snapshot.val()
        snapshot.ref.update({
          totalassignments: val.totalassignments ? parseInt(val.totalassignments) + 1 : 1,
          todaysassignments: val.todaysassignments ? parseInt(val.todaysassignments) + 1 : 1,
          scheduledassignments: t,
          finishedassignments: val.finishedassignments ? parseInt(val.finishedassignments) + 1 : 1,
          lastupdated: moment().format("YYYY/MM/DD"),
        })
      })
      
        
      Firebase.database()
      .ref(`/tracking/gigjobs/teammembers/${user.email.replace(/\./g,",")}/${getPhoneFromDisplayName(selectedDeliveryPerson)}/timeline`)
      .push({
            badgeColor: "danger",
            title: `A Job Assigned To Team member`,
            titleColor: "danger",
            timeStamp: moment().format("YYYY/MM/DD hh:mm A"),
            mapimage: `https://maps.googleapis.com/maps/api/staticmap?center=${addressLatLng.lat},${addressLatLng.lng}&size=550x300&maptype=roadmap&markers=color:red%7Clabel:A%7C${addressLatLng.lat},${addressLatLng.lng}&path=enc:${routePreviewPolyline}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`,
            body: `
                Team member is assigned a job to be done at location ${address}, Scheduled for the time 
                ${jobScheduleTime ? jobScheduleTime : moment().format("YYYY/MM/DD hh:mm A").toString()}.
                Driving distance is ${distanceMatrixData.distance.text}, estimate driving time ${distanceMatrixData.duration.text}    
            `
          })
        
    Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        let val = snapshot.val()
        let a = 0
        if(val.totalgigjobteam) {
          a = val.totalgigjobteam
        }
        snapshot.ref.update({
          totalgigjobteam: parseInt(a) + 1
        })
      })

    setAddress(null);
    setAddressLatLng(null)
    setCustomerPhone(null)
    setAddressLocation(null)
    setSelectedDeliveryPerson(null)
    setDeliveryOpen(false);
    setDistanceMatrixData(null)
    setRoutePreviewPolyline(null)
    })
  })
  }
  const handleClick = (val) => {
    setSelectedDeliveryPerson(val);
  }
  const customerPhoneChange = (e) => {
    setCustomerPhone(`${currentUserData.countrycode}${e.target.value}`);
  }
  const jobScheduleTimeChange = (e) => {
    setJobScheduleTime(moment(e).format("YYYY/MM/DD hh:mm A").toString())
  }
  const handleConnectToOwnDomain = () => {
    setConnectDomainModal(true)
  }
  const domainNameChange = (e) => {

    setDomainName(e.target.value)
  }

  

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleConnectDomainClose = () => {
	// sendEmail();
    setCustomDomainDetails({
      enabled: true,
      url: domainName.replace(/\./g,","),
      status: "Connection Pending"
    })
    Firebase.database().ref(`/trackconfig/gigjobtrack/${user.email.replace(/\./g, ",")}/customdomain`).set({
      enabled: true,
      url: domainName.replace(/\./g,","),
      status: "Connection Pending"
    })
	  setDomainName(null);
    setConnectDomainModal(false);
  };

  const handleSave = () => {
      let t = {}
      t[moment().format("YYYY-MM-DD").toString()] = 0
      Firebase.database()
      .ref(`/tracking/gigjobs/teammembers/${user.email.replace(/\./g,",")}/${deliveryGuyPhone}`)
      .set({
            id: "storeid",
            name: deliveryGuyName,
            phone: deliveryGuyPhone,
            verificationstatus: "done",
            lastupdated: moment().format("YYYY/MM/DD"),
            totalassignments: 0,
            todaysassignments: 0,
            scheduledassignments: t,
            finishedassignments: 0
          })
        
      Firebase.database()
      .ref(`/tracking/gigjobs/teammembers/${user.email.replace(/\./g,",")}/${deliveryGuyPhone}/timeline`)
      .push({
            badgeColor: "danger",
            title: "Team member Registered",
            titleColor: "danger",
            timeStamp: moment().format("YYYY/MM/DD hh:mm A"),
            body: `
                Team member is registered with the platform.
            `
          })
        
    Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g,",")}/`)
      .once('value', snapshot => {
        let val = snapshot.val()
        let a = 0
        if(val.totalgigjobteam) {
          a = val.totalgigjobteam
        }
        snapshot.ref.update({
          totalgigjobteam: parseInt(a) + 1
        })
      })
    setDeliveryGuyPhone(null);
    setDeliveryGuyName(null)
    setOpen(false);
  }
  const deliveryGuyNameChange = (e) => {
    setDeliveryGuyName(e.target.value);
  }
  const deliveryGuyPhoneChange = (e) => {
    setDeliveryGuyPhone(`${currentUserData.countrycode}${e.target.value}`);
  }

  const dnsDetails = () => {
	return (
		<>
			<small>
				Add the TXT records below to your DNS provider to verify you own test.com. Do not delete your TXT records.
			</small>
			<br />
			<br />
			<div>
				<div className={classes.typo}>
					<div className={classes.note}>Record Type : TXT</div>
				</div>
				<br />
				<div className={classes.typo}>
					<div className={classes.note}>Host : {domainName}</div>
				</div>
				
				<div className={classes.typo}>
					<div className={classes.note}>Value : google-site-verification=d6moySVQwv_I10kSC24AqTv3rx5oWhUPvKcbDITLPjQ</div>
				</div>
				
			</div>
		 		
			<br />
			<br />
			<small>
				Add these A records to your domain by visiting your DNS provider or registrar. Your site will show a security certificate warning for a few hours, until the certificate has been provisioned.
			</small>
			<br />
			<br />
			<div>
				<div className={classes.typo}>
					<div className={classes.note}>Record Type : A</div>
				</div>
				<br />
				<div className={classes.typo}>
					<div className={classes.note}>Host : {domainName}</div>
				</div>
				
				<div className={classes.typo}>
					<div className={classes.note}>Value : 199.36.158.100</div>
				</div>
				
			</div>
			<br />
			<br />
			<small>
				It will take approx 24hrs. Support team will contact you to confirm it.
			</small>
			<br />
			<br />
		</>
	)
  }

  // const usePortal = (id) => {
  //   const rootElemRef = React.useRef(document.createElement('div'));

  //   React.useEffect(function setupElement() {
  //     // Look for existing target dom element to append to
  //     const parentElem = document.querySelector(`#${id}`);
  //     // Add the detached element to the parent
  //     parentElem.appendChild(rootElemRef.current);
  //     // This function is run on unmount
  //     return function removeElement() {
  //       rootElemRef.current.remove();
  //     };
  //   }, [id]);
  
  //   return rootElemRef.current;
  // }


  const openTeamMemberCallback = (member) => {
 
  }

  const deleteHandleLastcaseCallback = (t) => {
    if(t == 'member'){
      setTeamMembers([])
      setTeamLatLng([])
    }
    // if(t == 'place'){
    //   setPlacesData([])
    //   setPlacesMapData([])
    // }
  }

  const noTeamMember = () =>{
    return (
       <GridContainer justify = "space-between"
        style = {{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
         <h3>There are no team members yet</h3>
       </GridContainer>
    )
  }

  const noJobs = () =>{
    return (
       <GridContainer justify = "space-between"
        style = {{
            color: "grey",
            justifyContent: "center",
            height: "200px",
            display: "inline-block",
            textAlign: "center"
          }}>
         <h3>There are no Jobs scheduled yet</h3>
       </GridContainer>
    )
  }

  const teamMemberScreen = () => {
	return (
		<div>
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
			<DialogTitle id="form-dialog-title">Register A Team Member</DialogTitle>
			<DialogContent>
			<DialogContentText>
				Enter Name
			</DialogContentText>
			<CustomInput
				formControlProps={{
				fullWidth: true,
				className: classes.customFormControlClasses,
				}}
				inputProps={{
				startAdornment: (
					<InputAdornment
					position="start"
					className={classes.inputAdornment}
					>
					<PersonIcon className={classes.inputAdornmentIcon} />
					</InputAdornment>
				),
				placeholder: "Name...",
				onChange: deliveryGuyNameChange
				}}
			/>
			<br/>
			<br/>
			<DialogContentText>
				Enter Phone
			</DialogContentText>
			<CustomInput
				formControlProps={{
				fullWidth: true,
				className: classes.customFormControlClasses,
				}}
				inputProps={{
				startAdornment: (
					<InputAdornment
					position="start"
					className={classes.inputAdornment}
					>
					<PhoneIcon className={classes.inputAdornmentIcon} />
					</InputAdornment>
				),
				placeholder: "10 Digit Phone Number",
				onChange: deliveryGuyPhoneChange
				}}
			/>
			<p style={{color: "grey", padding: "10px"}}>Please make sure team member installed the mobile app</p>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose} color="primary">
				Cancel
			</Button>
			<Button onClick={handleSave} color="primary" disabled={!deliveryGuyName || !deliveryGuyPhone || deliveryGuyPhone.replace(currentUserData.countrycode,'').length != 10}>
				Save
			</Button>
			</DialogActions>
		</Dialog>
		<Dialog open={connectDomainModal} aria-labelledby="form-dialog-title" fullWidth keepMounted>
			<DialogTitle id="form-dialog-title">Add custom domain</DialogTitle>
			<DialogContent>
				<small>Enter the exact domain name you want people to see when they visit your livetrack URL. It can be a domain (yourdomain.com) or a subdomain (app.yourdomain.com)</small>
			<br/>
			<br/>
			<DialogContentText>
				Domain
			</DialogContentText>
			<CustomInput
				formControlProps={{
				fullWidth: true,
				className: classes.customFormControlClasses,
				}}
				inputProps={{
				
				endAdornment: (
					<InputAdornment
					position="end"
					className={classes.inputAdornment}
					>
					{domainName && isValidDomain(domainName) ? (<CheckIcon style={{color: "green"}}  className={classes.inputAdornmentIcon}/>) : null}
					</InputAdornment>
				),
				placeholder: "",
				onChange: domainNameChange,
				disabled: showConnectDomainDetails
				}}
			/>
			<br/>
			<br/>
			{showConnectDomainDetails && dnsDetails()}

			</DialogContent>
			<DialogActions>
			{showConnectDomainDetails ? (<Button onClick={handleConnectDomainClose} color="primary" disabled={!isValidDomain(domainName)}>
				Finish
			</Button>): (<Button onClick={handleNext} color="primary" disabled={!isValidDomain(domainName)}>
				Next
			</Button>)}
			</DialogActions>
		</Dialog>
		<GridContainer>
      <GridItem xs={12}>
        <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose" style={{float: "right", padding: "10px"}}>
          <Button  onClick={handleOpen}
          style = {{
            background: "transparent",
            boxShadow: "none"
            }}>
            Add A Team Member
          </Button>
          </CardIcon>    
        </CardHeader>
        <CardBody>
        {teamMembers && teamMembers.length> 0 ? (<Table
          tableHeaderColor="primary"
          tableHead={["Name", "Phone", "Total Scheduled Assignments", "Finished Assignments", ""]}
          tableData={teamMembers}
          coloredColls={[3]}
          colorsColls={["primary"]}
          openTeamMember={openTeamMemberCallback}
          deleteHandleLastcaseCallback={deleteHandleLastcaseCallback}
          />): noTeamMember()}
          
        </CardBody>
        </Card>
      </GridItem>
		</GridContainer>
		</div>
	);
	}
//   React.useEffect(() => {
//      const onChildAdd = Firebase.database()
//        .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
//        .on('value', snapshot => {
//          const data = snapshot.val()
//          if (data) {
//            setCurrentUserData(data);
//           //  setAutoScheduleEnabled(data.autoscheduleenabled)
//          }
//        });
//      return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
//    }, [])
  const customFunctionDP = () => {


    // setTabChange(true)
  }
  const trackDataDetails = (trackData) => {
    return (
      <>
      <Badge color="success">Battery: {trackData.battery}</Badge> &nbsp;&nbsp;
      <Badge color="danger">Location: {trackData.location}</Badge> &nbsp;&nbsp;
      <Badge color="primary">{trackData.activity}</Badge>
      </>
    )
  }
  const openTrackingModal = (e) => {
    
    Firebase.database()
    .ref(`/tracking/gigjobs/teammembers/${user.email.replace(/\./g,",")}/+18622878740/scheduledassignments/`)
    .once('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        setAvgDailyVisits(
          {
            labels: getCurrentWeekDays().reverse(),
            series: [[
              data[moment().subtract(6, "days").format("YYYY-MM-DD").toString()] || 0,
              data[moment().subtract(5, "days").format("YYYY-MM-DD").toString()] || 0,
              data[moment().subtract(4, "days").format("YYYY-MM-DD").toString()] || 0,
              data[moment().subtract(3, "days").format("YYYY-MM-DD").toString()] || 0,
              data[moment().subtract(2, "days").format("YYYY-MM-DD").toString()] || 0,
              data[moment().subtract(1, "days").format("YYYY-MM-DD").toString()] || 0,
              data[moment().format("YYYY-MM-DD").toString()] || 0
            ]],
          }
        )
      }
    })
    Firebase.database()
    .ref(`/tracking/gigjobs/teammembers/${user.email.replace(/\./g,",")}/+18622878740/piechart/`)
    .once('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        setPieChartData({
          labels: ["62%", "32%", "6%"],
          series: [62, 32, 6],
        })
      }
    })

    Firebase.database()
    .ref(`/tracking/gigjobs/teammembers/${user.email.replace(/\./g,",")}/+18622878740/timeline/`)
    .once('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        let result = []
        const keys = Object.keys(data);
        for (let index = keys.length - 1; index > -1; index--) {
          const element = data[keys[index]];
          result.push(
            {
              badgeColor: colorArray[Math.floor(Math.random() * colorArray.length)],
              badgeIcon: iconArray[Math.floor(Math.random() * iconArray.length)],
              title: element.title,
              titleColor: element.titleColor,
              userTimeStamp: element.timeStamp,
              mapimage: element.mapimage || null,
              img: element.img || null,
              body:element.body,
            }
          )
        }
        setUserTimelineData(result);
      }
    })
    
    Firebase.database()
    .ref(`/realtimetrackingdata/+18622878740/raw/`)
    .limitToLast(10)
    .on('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        
        let result = []
        const keys = Object.keys(data);
        for (let index = 0; index < keys.length - 1; index++) {
          const element = data[keys[index]];
          result.push(
            [
              element.status,
              element.timestamp,
              trackDataDetails({battery: element.battery, location: element.latlng, activity: element.activity}),
            ]
          )
        }
        setUserRealtimeActivtyData(result);
      }
    })
    setJobTrackModalOpen(true)
    setCurrentJobDetails(e.row.original)
  }
  const getCurrentWeekDays = () => {
    const weekStart = moment().startOf('week');
  
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(-i, 'days').format("D"));
    }
  
    return days;
  }
  const jobTrackModalOpenClose = () => {
    setJobTrackModalOpen(false)
  }
  const getSharableLink = (phone) => {
    if(!phone){
      return ''
    }
    return `https://trk.gg/preview}`
  }
  const TeamMemberLivetrackMap = () => {

    if(!currentJobDetails){
      return (<> <h1>No Map Data Yet</h1></>)
    }
    const teamMemberPhone = `+${currentJobDetails.teamMember.split("+")[1]}`
    const mapRef = React.useRef(null);
    const google = window.google;
    const [markerRef, setMarkerRef] = React.useState(null);
    const [locationLiveTracking, setLocationLiveTracking] = React.useState(null);

    function createCenterControl(map) {
      const controlButton = document.createElement("button");
    
      // Set CSS for the control.
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "14px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 0 22px 8px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";
    
      controlButton.textContent = `${moment().format("DD-MM-YYYY")}`;
      controlButton.title = "Change the date";
      controlButton.type = "button";
    
      // Setup the click event listeners: simply set the map to Chicago.
      controlButton.addEventListener("click", () => {
        
      });
    
      return controlButton;
    }    

    const dropDownDetails = () => {
      var z = document.createElement('div'); // is a node
      z.innerHTML = `<Card id="myControl">
      <CardHeader>
        <h4 className={classes.cardTitle}>
          Description about product
        </h4>
        <p className={classes.cardCategory}>
          More information here
        </p>
      </CardHeader>
      <CardBody>
        Collaboratively administrate empowered markets via
        plug-and-play networks. Dynamically procrastinate B2C
        users after installed base benefits.
        <br />
        <br />
        Dramatically visualize customer directed convergence
        without revolutionary ROI.
      </CardBody>
    </Card>`;
     return z;
    }

    React.useEffect(() => {
      if (teamMemberPhone) {
        const onChildAdd = Firebase.database()
          .ref(`/userlivelocationtracking/${teamMemberPhone}`)
          .on('value', snapshot => {
            const data = snapshot.val()
            if (data && data.latitude && data.longitude) {
              setLocationLiveTracking({
                lat: parseFloat(data.latitude),
                lng: parseFloat(data.longitude)
              })
            }
          });
        // Stop listening for updates when no longer required
        return () => Firebase.database().ref(`/userlivelocationtracking/${teamMemberPhone}`).off('value', onChildAdd);
      }
    }, []);

    React.useEffect(() => {
      if (locationLiveTracking) {
        if(markerRef){
        setTimeout(() => {
          markerRef.setPosition({
            lat: locationLiveTracking.lat,
            lng: locationLiveTracking.lng
          })
          // mapRef.panTo({lat: deliveryGuyLocationLiveTracking.lat,lng: deliveryGuyLocationLiveTracking.lng});
          // mapRef.setZoom(11);
          // animatingCamera(position.coords.latitude, position.coords.longitude, position.coords.heading);
          // getPointOnPath([position.coords.latitude, position.coords.longitude])
  
          // TODO 
          // mapRef.setHeading(45);
        }, 1000)
      }
      }
    }, [locationLiveTracking])

    React.useEffect(() => {
      let google = window.google;
      let map = mapRef.current;
      let lat = "37.7799273"
      let lng = "-121.9780153";
      const myLatlng = new google.maps.LatLng(lat, lng);
      const mapOptions = {
        zoom: 8,
        center: myLatlng,
        mapTypeControl: false,
        // mapTypeId: "satellite",
        styles: mapStyles,
      }
  
      map = new google.maps.Map(map, mapOptions);
      
      const centerControlDiv = document.createElement("div");
      // Create the control.
      const centerControl = createCenterControl(map);
      // Append the control to the DIV.
      centerControlDiv.appendChild(centerControl);

      
      

      //       <div id="myControl" style="display:none"><select><option>hello
      // world^^</option></select></div>


      // var customMapControls = document.createElement('div');
      // customControlDiv = document.getElementById("myControl").innerHTML;
      // customMapControls.innerHTML = customControlDiv;

      // const liveTrackMarker = new google.maps.Marker({
      //   position: userCurrentLatLng,
      //   icon: {
      //     path: squareMarkerSVG,
      //     fillColor: "#e91e63",
      //     fillOpacity: 1,
      //     strokeColor: "#e91e63",
      //   },
      //   map: map,
      // });

      // setMarkerRef(liveTrackMarker);
    
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

      // const marker = new google.maps.Marker({
      //   position: myLatlng,
      //   map: map,
      //   animation: google.maps.Animation.DROP,
      //   title: props.data[0],
      // });
  
      // const contentString =
      //   `<div class="info-window-content"><h2>${props.data[0]}</h2></div>`
  
      // const infowindow = new google.maps.InfoWindow({
      //   content: contentString,
      // });
  
      // google.maps.event.addListener(marker, "click", function () {
      //   infowindow.open(map, marker);
      // });
      // const directionsService = new google.maps.DirectionsService();

      function autoRefresh(map, pathCoords) {
        var i, route;
  
        route = new google.maps.Polyline({
          path: [],
          geodesic: true,
          strokeColor: "#000000",
          strokeWeight: 5,
          editable: false,
          map: map,
        });
        const startLatLng = new google.maps.LatLng(pathCoords[0].lat(), pathCoords[0].lng())
        const liveTrackMarker = new google.maps.Marker({
          position: startLatLng,
          icon: {
            path: squareMarkerSVG,
            fillColor: "#e91e63",
            fillOpacity: 1,
            strokeColor: "#e91e63",
          },
          map: map,
        });
  
        for (let i = 0; i < pathCoords.length; i++) {
          setTimeout(
            function (coords) {
              route.getPath().push(coords);

              if(liveTrackMarker){
                liveTrackMarker.setPosition({
                  lat: coords.lat(),
                  lng: coords.lng()
                }) 
              }
              if(i === pathCoords.length-1){
                liveTrackMarker.setVisible(false)
              }
              
            },
            50 * i,
            pathCoords[i]
          );
        }
      }
      // const directionsDisplay = new google.maps.DirectionsRenderer({
      //   map: map,
      //   suppressMarkers: true,
      //   // polylineOptions: {
      //   //   strokeWeight: 5,
      //   //   strokeColor: "#808080",
      //   // },
      //   preserveViewport: true,
      // });
      let bounds = new google.maps.LatLngBounds();
      // const test = "trackhistory/+18622878740/13-11-2022/"
      const liveTrackMarker = new google.maps.Marker({
       
        icon: {
          path: squareMarkerSVG,
          fillColor: "#e91e63",
          fillOpacity: 1,
          strokeColor: "#e91e63",
        },
        map: map,
      });
      setMarkerRef(liveTrackMarker)

      const today = moment().format("DD-MM-YYYY").toString();
      Firebase.database()
          .ref(`trackhistory/${teamMemberPhone}/13-11-2022/`)
          .once('value', snapshot => {
            const data = snapshot.val()
            if (data) {
              let result = []
              const keys = Object.keys(data);
              for (let index = 0; index < keys.length - 1; index++) {
                const element = data[keys[index]]["location"].split(",");
                const point = new google.maps.LatLng(parseFloat(element[0]), parseFloat(element[1]))
                result.push(point)
                bounds.extend(point)
              }
              var tourplan = new google.maps.Polyline({
                path:result,
                strokeColor:"#0000FF",
                strokeOpacity:0.6,
                strokeWeight:5
            });
            
            tourplan.setMap(map);
            map.fitBounds(bounds);
            autoRefresh(map, result)
            }
           
          })

      // directionsService.route(
      //   {
      //     destination: {
      //       lat: 37.706576,
      //       lng: -121.89552,
      //     },
      //     origin: {
      //       lat: 37.7799273,
      //       lng: -121.9780153
      //     },
      //     travelMode: "DRIVING",
      //   },
      //   (result, status) => {
      //     //console.log("=====direction result====", result)
      //     if (status == "OK") {
      //       // setLiveTrackMarkerRef(new google.maps.Marker({
      //       //   map: map
      //       // }));
      //       // const a = Date.now()
      //       for (let index = 0; index < result.routes[0].overview_path.length; index++) {
      //         const element = result.routes[0].overview_path[index];
      //         Firebase.database()
      //         .ref(`/trackhistory/+18622878740/${moment().format("DD-MM-YYYY")}`)
      //         .push(
      //           {"location": `${element.lat()},${element.lng()}`,
      //            "servertimestamp": Firebase.database.ServerValue.TIMESTAMP,
      //            "localtimestamp": Date.now()
      //           }
      //         )
      //       }
           
      //       bounds.union(result.routes[0].bounds);
      //       map.fitBounds(bounds);
  
      //       var display = new google.maps.DirectionsRenderer({preserveViewport: true});
  
      //       display.setMap(map);
      //       directionsDisplay.setDirections(result);
  
      //       autoRefresh(map, result.routes[0].overview_path);
      //     }
      //   }
      // );
    }, []);


    return (
      <>
        <div
          style={{
            height: `500px`,
            borderRadius: "6px",
            overflow: "hidden",
          }}
          ref={mapRef}
        />
      </>
    );
  };

  const jobsScreen = () => {
    return (
      <div>
        <Dialog open={jobTrackModalOpen} onClose={jobTrackModalOpenClose} aria-labelledby="form-dialog-title" fullScreen>
        <DialogTitle id="form-dialog-title" style={{paddingBottom: "0px", fontSize: "15px", backgroundColor: "#eeeeee"}}>
          
          <Grid container direction="row" justify="space-between">
            <div style={{color: "gray", fontSize: "10px"}}>
            {currentJobDetails?.address} &nbsp;&nbsp; {currentJobDetails?.distance} &nbsp;&nbsp; 
            <br />
              <span style={{color: "gray", fontSize: "10px"}}>Customer Livetrack URL: </span><a href={currentJobDetails?.liveTrackURL} target="_blank" style={{fontSize: "15px"}}>
                {currentJobDetails?.liveTrackURL} <LaunchIcon style={{paddingLeft: "5px", paddingTop: "5px", fontSize: "1rem"}} />
              </a>
            </div>
            
            <IconButton aria-label="close" onClick={jobTrackModalOpenClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
      
        <DialogContent style={{backgroundColor: "#eeeeee"}}>
          {/* <h4>{address}</h4> */}
          {/* <img src={`https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/teamtrack.jpg?alt=media&token=7e2f91c8-12d4-4178-be1a-2c29a76cbb78`} title="" style={{width: "100%"}}/> */}
          <GridContainer >
            <GridItem xs={12} sm={12} md={12}>
            {React.useMemo(
              () => (
                <TeamMemberLivetrackMap />
              ),
              [jobTrackModalOpen]
            )}
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <NavPills
                color="warning"
                alignCenter
                tabs={[
                  {
                    tabButton: "Summary",
                    tabIcon: Info,
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <Card>
                            <CardHeader>
                              <h4>
                                <small>Avg Daily Visits</small>
                              </h4>
                            </CardHeader>
                            <CardBody>
                              <ChartistGraph
                                data={avgDailyVisits}
                                type="Line"
                                options={colouredLineChart.options}
                                listener={colouredLineChart.animation}
                              />
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5}>
                        <Card>
                          <CardHeader>
                            <h4><small> Time Distribution</small></h4>
                          </CardHeader>
                          <CardBody>
                            <ChartistGraph
                              data={pieChartData}
                              type="Pie"
                              options={pieChart.options}
                            />
                          </CardBody>
                          <CardFooter stats className={classes.cardFooter}>
                            {pieChartData?.labels?.length == 1 ? (<>
                              <i className={"fas fa-circle " + classes.info} /> No Tracking Data Yet
                            </>) : (
                              <>
                            <i className={"fas fa-circle " + classes.info} /> Driving{` `}
                            <i className={"fas fa-circle " + classes.warning} /> Visits
                            {` `}
                            <i className={"fas fa-circle " + classes.danger} /> Idle
                            {` `}</>)}
                            
                          </CardFooter>
                        </Card>
                      </GridItem>
                      </GridContainer>
                    ),
                  },
                  {
                    tabButton: "Timeline",
                    tabIcon: TimelineIcon,
                    tabContent: (
                      <GridContainer justify = "space-between"
                        style = {{
                            justifyContent: "center",
                            textAlign: "center"
                          }}>  
                          <GridItem xs={12} sm={12} md={12} lg={9}>
                            {userTimelineData?.length >0 ? (<Timeline simple stories={userTimelineData} />) : ""}
                          </GridItem>
                        </GridContainer>

                    ),
                  },
                  {
                    tabButton: "Activity",
                    tabIcon: StorageIcon,
                    tabContent: (
                      <Card>
                        <CardHeader>
                          <h4 className={classes.cardTitle}>
                            Real time Activity
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <Table
                            tableHead={[
                              "Status",
                              "Timestamp",
                              "Trackdata",
                            ]}
                            tableData={userRealtimeActivtyData}
                            customCellClasses={[classes.center, classes.right, classes.right]}
                            customClassesForCells={[0, 4, 5]}
                            customHeadCellClasses={[
                              classes.center,
                              classes.right,
                              classes.right,
                            ]}
                            customHeadClassesForCells={[0, 4, 5]}
                          />
                        </CardBody>
                      </Card>
                    ),
                  },
                  // {
                  //   tabButton: "Chat",
                  //   tabIcon: ChatIcon,
                  //   tabContent: (
                  //     <Card>
                  //       <CardHeader>
                  //         <h4 className={classes.cardTitle}>Help center</h4>
                  //         <p className={classes.cardCategory}>
                  //           More information here
                  //         </p>
                  //       </CardHeader>
                  //       <CardBody>
                  //         From the seamless transition of glass and metal to the
                  //         streamlined profile, every detail was carefully considered
                  //         to enhance your experience. So while its display is
                  //         larger, the phone feels just right.
                  //         <br />
                  //         <br />
                  //         Another Text. The first thing you notice when you hold the
                  //         phone is how great it feels in your hand. The cover glass
                  //         curves down around the sides to meet the anodized aluminum
                  //         enclosure in a remarkable, simplified design.
                  //       </CardBody>
                  //     </Card>
                  //   ),
                  // },
                ]}
              />
            </GridItem>
          </GridContainer>

        </DialogContent>
        {/* <DialogActions>
          <Button onClick={adderessTrackClose} color="primary">
          close
          </Button>
        </DialogActions> */}
        </Dialog>
        <Dialog open={deliveryOpen} onClose={deliveryHandleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Schedule A Job</DialogTitle>
        <DialogContent>
          <b>{address} &nbsp; {distanceMatrixData?.distance?.text} &nbsp; {distanceMatrixData?.duration?.text}</b>
          {addressLatLng && (<img src={`https://maps.googleapis.com/maps/api/staticmap?center=${addressLatLng.lat},${addressLatLng.lng}&size=550x300&maptype=roadmap&markers=color:red%7Clabel:A%7C${addressLatLng.lat},${addressLatLng.lng}&path=enc:${routePreviewPolyline}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`} title="" />)}
    
          <DialogContentText>
            Enter Address
          </DialogContentText>
          <GooglePlacesAutocomplete
          selectProps={{
          onChange: (address) => getCoordinates(address),
          }}
          placeholder="Search Address "
          apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
          />
          &nbsp;
          <h4>Customer Phone</h4>
          <CustomInput
          formControlProps={{
            fullWidth: true,
            className: classes.customFormControlClasses,
          }}
          inputProps={{
            startAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornment}
            >
              <Phone className={classes.inputAdornmentIcon} />
            </InputAdornment>
            ),
            type: "number",
            placeholder: "10 Digit Phone...",
            onChange: customerPhoneChange
          }}
          />
          {/* <p style={{color: "grey"}}>Please make sure delivery person installed onroad mobile app</p> */}
          <br/>
          <h4> Team Member : <b>{selectedDeliveryPerson}</b></h4>
          &nbsp;
          <CustomDropdown
            dropup
            onClick={(val) => handleClick(val)}
            buttonText="Pick A Team Member"
            hoverColor="info"
            dropPlacement="top"
            buttonProps={{
              round: true,
              fullWidth: true,
              style: { marginBottom: "0" },
              color: "info",
              width: "80%"
            }}
            style={{width: "80%"}}
            dropdownList={registeredTeammembers}
            />
            <p>{registeredTeammembers.length == 0 ? "Please register atleast on delivery person" : null}</p>
          &nbsp;
          <h4>Schedule Time(Optional)</h4>
            <Datetime
              inputProps={{ placeholder: "Pick Schedule time Here"
            }}
            onChange={(e) => {jobScheduleTimeChange(e)}}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={deliveryHandleClose} color="primary">
          Cancel
          </Button>
          <Button onClick={deliveryHandleSave} color="primary" disabled={registeredTeammembers.length == 0 || !address || !addressLatLng || !customerPhone || customerPhone.replace(currentUserData.countrycode,'').length != 10}>
          Add
          </Button>
        </DialogActions>
        </Dialog>
        <GridContainer>
          <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
            <CardIcon color="primary" style={{float: "right", padding: "10px"}}>
              <Button  onClick={deliveryHandleOpen}
              style = {{
                background: "transparent",
                boxShadow: "none"
              }}>
              Add A Job
              </Button>
            </CardIcon>
            </CardHeader>
            <CardBody>
           {jobs && jobs.length > 0 ? (<ReactTable
              columns={[
              {
                Header: "Address",
                accessor: "address",
                Cell: (e) =>{
                  return (<Button color="transparent" size="sm"  onClick={() => {
                    openTrackingModal(e)
                  }} style={{fontSize: "10px",padding: "0px", color: "#9c27b0"}}>{e.value}</Button>)
                }
              },
              {
                Header: "Customer Phone",
                accessor: "customerPhone",
              },
              {
                Header: "Livetrack URL",
                accessor: "liveTrackURL",
                Cell: e =><a href={e.value} target="_blank"> {e.value} </a>
              },
              {
                Header: "Team Member",
                accessor: "teamMember",
              },
              {
                Header: "Distance",
                accessor: "distance",
              },
              {
                Header: "Job Status",
                accessor: "jobStatus",
              }
              ]}
              data={jobs}
            />) : noJobs()}
            </CardBody>
          </Card>
          </GridItem>
        </GridContainer>
      </div>
      );
   }
   const onTaglineChange = (e) => {
    if(e.target.value){
      // setShowProgress(true)
      Firebase.database().ref(`/trackconfig/gigjobtrack/${user.email.replace(/\./g, ",")}/brand/tagline`).set(e.target.value)
    } 
  }
  const onWebsiteChange = (e) => {
    if(e.target.value){
      // setShowProgress(true)
      Firebase.database().ref(`/trackconfig/gigjobtrack/${user.email.replace(/\./g, ",")}/brand/website`).set(e.target.value)
    } 
  }
   const tripsScreen = () => {
    return (
      <>
      <Heading
          textAlign="center"
          title="Jobs"
          // category={currentUserData?.apikey}
        />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
          <Accordion
            collapses={[
              {
                title: (<h5 style={{paddingLeft: "15px"}}>Livetrack URL Configuration</h5>),
                content: (
                  <CardBody>
                    <div className={classes.typo}>
                      <div className={classes.note} style={{maxWidth: "50%"}}>
                        Add Custom Domain
                      </div>
                    <div style={{width: "100%", position: "relative"}}>
                      <span style={{paddingLeft: "90px", textTransform: "capitalize"}}>
                      {customDomainDetails?.url ? 
                      (<>
                        <small style={{color: "gray"}}>{customDomainDetails?.status}</small> &nbsp; &nbsp;&nbsp;
                        <small><b>{customDomainDetails?.url?.replace(/\,/g, ".")}</b></small></>) : 
                      (<Button color="primary" size="sm" className={classes.marginRight} onClick={handleConnectToOwnDomain}>
                        Connect
                      </Button>)}
                      {/* <small style={{color: "gray"}}>{(false) ? "Enabled" : "Disabled"}</small> */}
                      </span>
                    </div>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note} style={{maxWidth: "50%"}}>
                        Livetrack and ETA
                      </div>
                      <div style={{width: "100%", position: "relative"}}>
                        <span style={{paddingLeft: "90px", textTransform: "capitalize"}}>
                        <Switch
                          checked={true}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar,
                          }}
                          
                        />
                        <small style={{color: "gray"}}>{(true) ? "Enabled" : "Disabled"}</small>
                        </span>
                      </div>
                    </div>

                    <div className={classes.typo}>
                        <div className={classes.note} style={{maxWidth: "50%"}}>
                          Call
                          <br />
                          <small>Customer can call</small>
                        </div>
                        <div style={{width: "100%", position: "relative"}}>
                          <span style={{paddingLeft: "90px", textTransform: "capitalize"}}>
                          <Switch
                            checked={callFeature || false}
                            onChange={(event) => {
                              setCallFeature(event.target.checked)
                              Firebase.database().ref(`/trackconfig/gigjobtrack/${user.email.replace(/\./g, ",")}/call`).set(event.target.checked)
                            }
                            }
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                          <small style={{color: "gray"}}>{callFeature ? "Enabled" : "Disabled"}</small>
                          </span>
                        </div>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note} style={{maxWidth: "50%"}}>
                        Accept Tips
                      </div>
                      <div style={{width: "100%", position: "relative"}}>
                        <span style={{paddingLeft: "90px", textTransform: "capitalize"}}>
                        <Switch
                          checked={tipFeature || false}
                          onChange={(event) => {
                            setTipFeature(event.target.checked)
                            Firebase.database().ref(`/trackconfig/gigjobtrack/${user.email.replace(/\./g, ",")}/tip`).set(event.target.checked)
                          }
                          }
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar,
                          }}
                        />
                        <small style={{color: "gray"}}>{tipFeature ? "Enabled" : "Disabled"}</small>
                        </span>
                      </div>
                    </div>
                    <div className={classes.typo}>
                    <div className={classes.note} style={{maxWidth: "50%"}}>
                      Enable Messaging
                    </div>
                    <div style={{width: "100%", position: "relative"}}>
                      <span style={{paddingLeft: "90px", textTransform: "capitalize"}}>
                      <Switch
                        checked={chatFeature || false}
                        onChange={(event) => {
                          setChatFeature(event.target.checked)
                          Firebase.database().ref(`/trackconfig/gigjobtrack/${user.email.replace(/\./g, ",")}/chat`).set(event.target.checked)
                          }
                        }
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                      <small style={{color: "gray"}}>{chatFeature ? "Enabled" : "Disabled"}</small>
                      </span>
                    </div>
                    </div>
                    <div className={classes.typo}>
                    <div className={classes.note} style={{maxWidth: "50%"}}>
                      Show Other Details
                    </div>
                    <div style={{width: "100%", position: "relative"}}>
                      <span style={{paddingLeft: "90px", textTransform: "capitalize"}}>
                      <Switch
                        checked={imagesFeature || false}
                        onChange={(event) => {
                          setImagesFeature(event.target.checked)
                          Firebase.database().ref(`/trackconfig/gigjobtrack/${user.email.replace(/\./g, ",")}/images`).set(event.target.checked)
                          }
                        }
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                      <small style={{color: "gray"}}>{imagesFeature ? "Enabled" : "Disabled"}</small>
                      </span>
                    </div>
                    </div>
                    <div className={classes.typo}>
                      <div className={classes.note} style={{maxWidth: "50%"}}>
                        Branding
                      </div>
                      <div style={{width: "100%", position: "relative"}}>
                          <span style={{paddingLeft: "90px", textTransform: "capitalize"}}>
                          <Switch
                            checked={brandFeature || false}
                            onChange={(event) => {
                              setBrandFeature(event.target.checked)
                              Firebase.database().ref(`/trackconfig/gigjobtrack/${user.email.replace(/\./g, ",")}/brand/enabled`).set(event.target.checked)
                            }
                            }
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                          <small style={{color: "gray"}}>{brandFeature ? "Enabled" : "Disabled"}</small>
                          </span>
                        </div>
                      
                    </div>
                    {brandFeature && (
                        <>
                          <div className={classes.typo}>
                                <div className={classes.note} style={{paddingLeft: "6%", paddingTop: "0px"}}>Tagline</div>
                                  <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.customFormControlClasses,
                                    }}
                                    inputProps={{
                                      startAdornment: (
                                        <InputAdornment
                                          position="start"
                                          className={classes.inputAdornment}
                                        >
                                          {/* <LocalGroceryStoreIcon className={classes.inputAdornmentIcon} /> */}
                                        </InputAdornment>
                                      ),
                                      placeholder: "Your company tagline",
                                      // // onChange: storeNameChange,
                                      // // onFocus: onFocus,
                                      // disabled: currentUserData && currentUserData.storename,
                                      value: brandDetails?.tagline,
                                      onBlur: onTaglineChange
                                    }}
                                  />
                              
                          </div>
                          <div className={classes.typo}>
                                <div className={classes.note} style={{paddingLeft: "6%", paddingTop: "0px"}}>Website</div>
                                  <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.customFormControlClasses,
                                    }}
                                    inputProps={{
                                      startAdornment: (
                                        <InputAdornment
                                          position="start"
                                          className={classes.inputAdornment}
                                        >
                                          {/* <LocalGroceryStoreIcon className={classes.inputAdornmentIcon} /> */}
                                        </InputAdornment>
                                      ),
                                      placeholder: "Your company tagline",
                                      // // onChange: storeNameChange,
                                      // // onFocus: onFocus,
                                      // disabled: currentUserData && currentUserData.storename,
                                      value: brandDetails?.website,
                                      onBlur: onWebsiteChange
                                    }}
                                  />
                              
                          </div>
              
                        <GridContainer justify = "space-between"
                          style = {{
                              color: "grey",
                              justifyContent: "center",
                              textAlign: "center"
                            }}>
                              
                            <GridItem xs={6} sm={3} md={3} >
                              <>
                              <BrandingImageUpload
                                addButtonProps={{
                                  color: "rose",
                                  round: true,
                                }}
                                changeButtonProps={{
                                  color: "rose",
                                  round: true,
                                }}
                                removeButtonProps={{
                                  color: "danger",
                                  round: true,
                                }}
                                existingImage={brandDetails?.logo}
                                imageID={() => {
                                  return `${user.email.replace(/\./g, ",")}/logo`
                                }}
                                imageNumber={1}
                                email={user?.email}
                                apikey={currentUserData?.apikey}
                              />
                              </>
                              
                            </GridItem>
                          
                                </GridContainer>
                                <br />
                                </>
                        )} 
                  </CardBody>
                )
              }]}
              />  
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card style={{marginBottom: "0px"}}>
            <Accordion
            collapses={[
              {
              title: (<h5 style={{paddingLeft: "15px"}}>Live Track URL Preview</h5>),
              content: (
                <CardBody height="100vh">
                  Default URL: <a href={"https://trk.gg/preview"} target="_blank">
                    {"https://trk.gg/preview"} <LaunchIcon style={{paddingLeft: "5px", paddingTop: "5px", fontSize: "1rem"}} />
                  </a> &nbsp;&nbsp;&nbsp;
                  <br />
                {customDomainDetails?.url && customDomainDetails?.status == 'connected' && (
                <>
                  Custom URL: <a href={`${customDomainDetails?.url?.replace(/\,/g, ".")}/preview`} target="_blank">
                    {`${customDomainDetails?.url?.replace(/\,/g, ".")}/preview`} <LaunchIcon style={{paddingLeft: "5px", paddingTop: "5px", fontSize: "1rem"}} />
                  </a>
                </>)}
                  <iframe src={`https://trk.gg/preview?user=${user.email.replace(/\./g, ",")}`} width="100%" style={{height: "60vh"}}>
                  </iframe>          
                </CardBody>
              )
              }
            ]}
            />
          </Card>
          <small style={{color: "grey"}}>
            URL that end user receives when job started. 
          </small>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            headerColor="success"
            customFunction={customFunctionDP}
            tabs={[
              {
                tabName: "Team Members",
                tabIcon: PeopleAlt,
                tabContent: (
                <>
                  {teamMemberScreen()}
                </>
                ),
              },
              {
                tabName: "Jobs",
                tabIcon: FormatListBulletedIcon,
                tabContent: (
                  <>
                  {jobsScreen()}
                  </>
                ),
              }
            ]}
          >
          </CustomTabs>
        </GridItem>
      </GridContainer> 
      </>
    )
  }
  return (
    <div>
    	{tripsScreen()}
    </div>
  );
}
