import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomInput from "components/CustomInput/CustomInput.js";
import Input from "@material-ui/core/Input";
import CloseIcon from '@material-ui/icons/Close';
import Firebase from "firebase";
import Button from "components/CustomButtons/Button.js";
import RoomIcon from '@material-ui/icons/Room';
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { addSyntheticLeadingComment } from "typescript";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SweetAlert from "react-bootstrap-sweetalert";
import {default as styles2} from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Datetime from "react-datetime";
import { Terminal } from 'xterm';
import 'xterm/css/xterm.css';

const term = new Terminal();


const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

export default function CustomTable(props) {
  const classes = useStyles();

  const classes2 = useStyles2();
  

  const [showAlert, setShowAlert] = React.useState(false);
  const [overWriteTime, setOverWriteTime] = React.useState();
  const [overWriteError, setOverWriteError] = React.useState();
  const [alertDataArray, setAlertDataArray] = React.useState([]);
  
  const handleOverWriteTime = (momentObj) => {
    setOverWriteTime(momentObj.format("hh:mm A"));
    setOverWriteError(null)
  };

  const handleShowAlert = (rT) => {
    setAlertDataArray(rT)
    setShowAlert(true);
  };

  const handleHideAlert = () => {
    setOverWriteTime(null)
    setOverWriteError(null)
    setAlertDataArray([])
    setShowAlert(false);
  };
  const handleConfirmAlert = () => {
    if (!overWriteTime) {
      setOverWriteError("Please Enter A Valid Time")
      return;
    }

    // const nt = moment(overWriteTime, 'hh:mm A');
    // const ot = moment(alertDataArray[0], 'hh:mm A');
    // const t = nt.isSameOrAfter(ot);
    
    // if (alertDataArray[5] == "checkout" && !t) {
    //   setOverWriteError("Please Enter A Valid Time, Should be after Check in time")
    //   return;
    // }
    // if (alertDataArray[5] == "checkin" && !t) {
    //   setOverWriteError("Please Enter A Valid Time, Should be after Check in time")
    //   return;
    // }

    let updateObject = {}
    const detailsT = alertDataArray.details.split("-|-")
    updateObject[alertDataArray.type] = overWriteTime
    updateObject[`overwrittenfrom-${alertDataArray.type}`] = alertDataArray.entry

    Firebase.database().ref(`/tracking/timecard/checkincheckouts/${detailsT[0]}/${detailsT[1]}/${detailsT[2]}/${detailsT[3]}/shifthours/${alertDataArray.index}`).update(updateObject)
    overWriteCallback(detailsT[1])
    setOverWriteError(null)
    setOverWriteTime(null)
    setShowAlert(false);
    setAlertDataArray([])
  }

  const {
    tableHead,
    tableData,
    tableHeaderColor,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    striped,
    tableShopping,
    customHeadCellClasses,
    customHeadClassesForCells,
    openAddress,
    openTeamMember,
    deleteHandleLastcaseCallback,
    overWriteCallback,
    deleteAutoGPTInstanceCallback,
  } = props;
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setDurationArray();
  //   },1000);
  // });
   
  const deleteProduct = (prop) => {
    if(prop){
      if(prop.includes("removetheproduct")){
        const pid = prop.split("removetheproduct:")[1].split('-|-')[0]
        const key = prop.split("removetheproduct:")[1].split('-|-')[1]
        if(pid && key){ 
          Firebase.database()
          .ref(`/ecommerce/${key}/products/${pid}`).set(null)
        }
      }
      if(prop.includes("removetheservice")){
        const pid = prop.split("removetheservice:")[1].split('-|-')[0]
        const key = prop.split("removetheservice:")[1].split('-|-')[1]
        if(pid && key){ 
          Firebase.database()
          .ref(`/serviceofferings/${key}/services/${pid}`).set(null)
        }
      }
      
    }
  }

  const deleteAutoGPTInstance = async (prop) => {
    try {
      if (prop) {
        if(prop.includes("removeAutoGPTInstance")){
          const temp = prop.split("removeAutoGPTInstance:")[1]
          const fbEntryId = temp.split("-|-email-|-:")[0]
          const email = temp.split("-|-email-|-:")[1].split("-|-apikey-|-:")[0]
          const apikey = temp.split("-|-apikey-|-:")[1].split("-|-dropletid-|-:")[0]
          const dropletid = temp.split("-|-dropletid-|-:")[1]
          if (fbEntryId && email && apikey && dropletid) { 
            //console.log("instanceid-------", fbEntryId, email, apikey, dropletid)
  
            var myHeaders = new Headers();
            myHeaders.append("Authorization", apikey);
            myHeaders.append("Content-Type", "application/json");
  
            var raw = JSON.stringify({
              "instanceId": dropletid
            });
  
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
  
            const deleteResp = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/deleteAutoGTPCloud", requestOptions)
            const deleteRespJson = deleteResp.json()
            
  
            Firebase.database()
            .ref(`/agentactivity/${email}/autogpt/${fbEntryId}`)
              .once('value', snapshot => {
                const element = snapshot.val();
                //console.log("element-------", element)
  
                if (element && element.start) {
                  const startTime = moment(element.start, "DD-MM-YYYY hh:mm A");
                  const currentTime = moment(); // Gets current time
                  const diffDuration = moment.duration(currentTime.diff(startTime));
                  let hours = diffDuration.hours();
                  let minutes = diffDuration.minutes();
  
                  if (minutes > 0) {
                      hours++;
                  }
                  if (hours > 0) {
                    Firebase.database().ref(`/webadmin/${userid}/usage/autogpt`)
                      .once('value', snapshot => {
                        const element = snapshot.val();
                        //console.log("webadmin-------", element)
                        if (element) {
                          snapshot.ref.update({
                            hours: element.hours + hours,
                            instancecount: element.instancecount + 1
                          })
                        } else {
                          snapshot.ref.update({
                            hours: hours,
                            instancecount: 1
                          })
                        }
                       
                    })
                  }
                }
                snapshot.ref.update({
                  end: moment().format("DD-MM-YYYY hh:mm A"),
                  access: "",
                  status: "Inactive"
                })
            })
          }
        }
      }
    } catch (error) {
      console.error("Error occurred during deleting AutoGPT instance: ", error);
    }
  }
  

  const launchAutoGPT = (prop) => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // Adjust the dimensions as needed
    const newWindowWidth = Math.floor(width * 0.9);
    const newWindowHeight = Math.floor(height * 0.9);
    
    window.open('https://cloud.digitalocean.com/droplets/354097386/terminal/ui/', '_blank', `width=${newWindowWidth},height=${newWindowHeight}`);

  }

     // let productID = ""
    // let productNameOrPrice = ""
    // if(prop.includes("|name-")){
    //   productID = prop.split("input:")[1].split("|name-")[0]
    //   productNameOrPrice = prop.split("input:")[1].split("|name-")[1]
    // }
    // if(prop.includes("|price-")){
    //   productID = prop.split("input:")[1].split("|price-")[0]
    //   productNameOrPrice = prop.split("input:")[1].split("|price-")[1]
    // }
    const [showTooltip, setShowTooltip] = React.useState(false);

    const handleCopyClick = (fullText, key) => {
      navigator.clipboard.writeText(fullText).then(() => {
        setShowTooltip({ [key]: true });
        setTimeout(() => setShowTooltip({ [key]: false }), 1000);
      });
    };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor]}>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [customHeadCellClasses[
                      customHeadClassesForCells.indexOf(key)
                    ]]: customHeadClassesForCells.indexOf(key) !== -1,
                    [classes.tableShoppingHead]: tableShopping,
                    [classes.tableHeadFontSize]: !tableShopping,
                  });
                return (
                  <TableCell className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            var rowColor = "";
            var rowColored = false;
            if (prop.color !== undefined) {
              rowColor = prop.color;
              rowColored = true;
              prop = prop.data;
            }
            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: hover,
              [classes[rowColor + "Row"]]: rowColored,
              [classes.tableStripedRow]: striped && key % 2 === 0,
            });
            if (prop.total) {
              return (
                <TableRow key={key} hover={hover} className={tableRowClasses}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={prop.colspan}
                  />
                  <TableCell
                    className={classes.tableCell + " " + classes.tableCellTotal}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    className={
                      classes.tableCell + " " + classes.tableCellAmount
                    }
                  >
                    {prop.amount}
                  </TableCell>
                  {tableHead.length - (prop.colspan - 0 + 2) > 0 ? (
                    <TableCell
                      className={classes.tableCell}
                      colSpan={tableHead.length - (prop.colspan - 0 + 2)}
                    />
                  ) : null}
                </TableRow>
              );
            }
            if (prop.purchase) {
              return (
                <TableRow key={key} hover={hover} className={tableRowClasses}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={prop.colspan}
                  />
                  <TableCell
                    className={classes.tableCell + " " + classes.right}
                    colSpan={prop.col.colspan}
                  >
                    {prop.col.text}
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow
                key={key}
                hover={hover}
                className={classes.tableRow + " " + tableRowClasses}
              >
                {prop.map((prop, key) => {
                  const tableCellClasses =
                    classes.tableCell +
                    " " +
                    cx({
                      [classes[colorsColls[coloredColls.indexOf(key)]]]:
                        coloredColls.indexOf(key) !== -1,
                      [customCellClasses[customClassesForCells.indexOf(key)]]:
                        customClassesForCells.indexOf(key) !== -1,
                    });
                  
                  
                  if (typeof (prop) === "string" && prop.includes("AutoGPTAccessLink:")) {
                    if (!prop.split("AutoGPTAccessLink:")[1]) {
                        return <TableCell className={tableCellClasses} key={key} component={null}>
                                {" "}
                              </TableCell>
                      }
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                             <Button simple className={classes.actionButton} style={{color: "#9c27b0", padding: "0px"}} onClick={() => launchAutoGPT(prop)}>
                              Launch AutoGPT
                            </Button>
                          </TableCell>
                        );
                    }
                  
                  if (typeof (prop) === "string" && prop.includes("removeAutoGPTInstance")){
                      return (
                          <TableCell className={tableCellClasses} key={key}>
                            <Button simple style={{color: "red", padding: "0px"}} onClick={() => deleteAutoGPTInstance(prop)}>
                              <CloseIcon/><small style={{color: "gray"}}>Delete</small>
                            </Button>
                          </TableCell>
                        );
                    }
        
                  if (typeof (prop) === "string" && prop.includes("removeAutoGPTInstance")){
                    return (
                        <TableCell className={tableCellClasses} key={key}>
                          <Button simple style={{color: "red", padding: "0px"}} onClick={() => deleteAutoGPTInstance(prop)}>
                            <CloseIcon/><small style={{color: "gray"}}>Delete</small>
                          </Button>
                        </TableCell>
                      );
                  }
                
                  if (typeof (prop) === "string" && prop.includes("llmresponse")) {
                    const response = prop.split("llmresponse:")[1]
                    const shortText = response && response.length > 30 ? response.substring(0, 30) + "..." : response;
                    return (
                        <TableCell className={tableCellClasses} key={key} onClick={() => handleCopyClick(response, shortText)}>
                        {shortText}
                        {showTooltip[shortText] && <span style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'black', color: 'white', padding: '2px 5px', borderRadius: '3px' }}>Copied</span>}
                        </TableCell>
                      );
                  }
                 
                    
                  return (
                    <TableCell className={tableCellClasses} key={key}>
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
  openAddress: PropTypes.func,
  openTeamMember: PropTypes.func,
  deleteHandleLastcaseCallback: PropTypes.func
};
