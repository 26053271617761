
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useState, useEffect, useRef } from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components

// @material-ui/icons
// import InfoOutline from "@material-ui/icons/InfoOutline";
// core components
import Table from "components/Table/Table.js";

import TimerIcon from '@material-ui/icons/Timer';
import ChatIcon from '@material-ui/icons/Chat';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { NavLink } from 'react-router-dom';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Danger from "components/Typography/Danger.js";

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dialog from '@material-ui/core/Dialog';

import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import ReactJson from 'react-json-view';
import ReactMarkdown from 'react-markdown';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import InfoIcon from '@material-ui/icons/Info';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Snackbar from "components/Snackbar/Snackbar.js";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'; // For ready to deploy code
import SecurityIcon from '@material-ui/icons/Security'; // For login and payment integration
import DescriptionIcon from '@material-ui/icons/Description'; // For instructions
import EmailIcon from '@material-ui/icons/Email'; // For email support


var axios = require('axios');

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';




// import { cardTitle } from "assets/jss/material-dashboard-pro-js";
// core components
import Heading from "components/Heading/Heading.js";
import Firebase from "firebase";
const SMBase64 = require('smbase64')
let base64 = new SMBase64()

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);




export default function PixiePage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
 
  const [currentUserData, setCurrentUserData] = useState();
  const [agentLiceCycle, setAgentLiceCycle] = useState();
  const elements = useElements();
  const stripe = useStripe();


  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  function toTitleCase(str) {
    return str.toLowerCase().replace(/(^|\s)\S/g, (c) => c.toUpperCase());
  }
  function truncate(str) {
    return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
  }
  
  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/pixie/logs`)
      .limitToLast(100)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];            
            if (element && element.operation) {
              result.push([element.localdate, element.localtime, toTitleCase(element.operation), truncate(element.operationdescription)])
            }
          }
          setAgentLiceCycle(result);
        }
      });
    return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/pixie/logs`).off('value', onChildAdd);
  }, []) 

  const noLifeCycleEvents = () => {
    return (
      <GridContainer justify="space-between"
        style={{
          color: "grey",
          justifyContent: "center",
          height: "200px",
          display: "inline-block",
          textAlign: "center"
        }}>
        <h3>No lifecycle events found today. The agent has not been initiated yet.</h3>
        <br />
        <p>
          Follow the instructions mentioned &nbsp;
          <a href="https://github.com/hribab/gpt-console#readme" target="_blank" style={{ display: 'inline-flex', alignItems: 'center' }}>
            here
            <OpenInNewIcon style={{ fontSize: '1rem', marginLeft: '4px' }} />
                  </a> 
                  &nbsp;
          to initiate the console.
        </p>
      </GridContainer>
    )
  }

  const lifeCycle = () => {
    return (<>
      <Card>
        <CardBody>
          {agentLiceCycle && agentLiceCycle.length > 0 ? (
            <Table
              tableHeaderColor="primary"
              tableHead={["Date", "Time", "Task", "Log"]}
              tableData={agentLiceCycle}
              coloredColls={[3]}
              colorsColls={["primary"]}
            />) : noLifeCycleEvents()}

        </CardBody>
      </Card>
    </>)

  }

  function generateExplainerHTML(appConfig) {
    let explainerHTML = "<div><ul style='list-style-type:none; padding-left: 0;'>";
    const config = appConfig;
    const configKeys = Object.keys(config);
  
    configKeys.forEach((key) => {
      const item = config[key];
  
      if (key === 'landingpage') {
        explainerHTML += "<li style='padding: 5px;'>- I will be creating a <strong>landing page</strong>";
  
        const sectionKeys = Object.keys({ "header-section": "TBD", "features-section": "TBD", "contactus-section": "TBD", "blogs-section": "TBD", "teams-section": "TBD", "testimonials-section": "TBD"  });
        if (sectionKeys.length > 0) {
          explainerHTML += "<li style='padding: 5px;'>- The landing page might have sections: ";
          const sectionNames = sectionKeys.map(key => `<strong>${key.replace(/-/g, ' ').replace(/section/g, '').trim()}</strong>`).join(", ");
          explainerHTML += `${sectionNames}.</li>`;
        }
      } else {
        explainerHTML += `<li style='padding: 5px;'>- I will be creating <strong>${key.replace(/([A-Z])/g, ' $1').trim()}</strong> `;
        if (item.entities) {
          const entities = item.entities;
          const entityNames = Object.keys(entities).map(entity => `<strong>${entity}</strong>`).join(", ");
          explainerHTML += `Application with ${entityNames}. </li>`;
    
          Object.keys(entities).forEach(entity => {
            const details = entities[entity].attributes;
            const detailNames = Object.keys(details).map(detail => `<strong>${detail}</strong>`).join(", ");
            explainerHTML += `<li style='padding: 5px;'>- ${capitalizeFirstLetter(entity)} has details ${detailNames}. </li>`;
            
            if (entities[entity].relationships) {
              const relationships = entities[entity].relationships;
              Object.keys(relationships).forEach(rel => {
                explainerHTML += `<li style='padding: 5px;'>- Also, you can link multiple <strong>${rel}</strong> to a single <strong>${entity}</strong>. </li>`;
              });
            }
          });
        } else if (item.features) {
          const features = item.features;
          const featureEntries = Object.entries(features).filter(([, value]) => value);
          if (featureEntries.length > 0) {
            explainerHTML += "with ";
          }
          featureEntries.forEach(([feature, ], index) => {
            explainerHTML += `${index > 0 ? ", " : ""}<strong>${capitalizeFirstLetter(feature.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase())}</strong> enabled`;
          });
          if (featureEntries.length > 0) {
            explainerHTML += ".";
          }
          explainerHTML += "</li>";
        }else {
          // Handle other types similarly as needed
          explainerHTML += "Page.</li>";
        }
      }
    });
  
    explainerHTML += "</ul></div>";
    return explainerHTML;
  }
  
  
  // Helper function to capitalize the first letter of each sentence.
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  

  function CollapsibleFileList({ config }) {
    const [open, setOpen] = useState(false);
  
    const handleToggle = () => {
      setOpen(!open);
    };
    let adjustedConfig = {};
    if (config) {
        if (config["dashboard"]) {
            adjustedConfig = config["dashboard"]["entities"];
        } else if (config["aiapplication"]) {
            // Corrected typo from `config["aiapplicatio"]` to `config["aiapplication"]`
            adjustedConfig = config["aiapplication"]["features"];
        } else if (config["landingpage"]) {
          // Corrected typo from `config["aiapplicatio"]` to `config["aiapplication"]`
          adjustedConfig = { "landingpage": { "header-section": "TBD", "features-section": "TBD", "contactus-section": "TBD", "blogs-section": "TBD", "teams-section": "TBD", "testimonials-section": "TBD"  } };
      }
    }
        
    // Inside CollapsibleFileList
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={handleToggle}
          aria-expanded={open}
          aria-label="show more"
          size="small" // Adjust the size as needed
        >
          <ExpandMoreIcon />
        </IconButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ReactJson src={adjustedConfig||{}} theme="rjv-default" />;
        </Collapse>
      </div>
    );

  }
  
  function validateJSON(json) {
 
    const attributeTypes = ["string", "date", "number", "file"];
    const mainKeys = Object.keys(json);
    const relationshipTypes = ["array", "single"];


    for (const key of mainKeys) {
        const item = json[key];
        // Check if key is a string and non-empty
        if (typeof key !== 'string' || key.trim() === '') {
            return false;
        }
        // Check if item has attributes
        if (!item.attributes || typeof item.attributes !== 'object' || Object.keys(item.attributes).length === 0) {
            return false;
        }
        // Check attributes types
        for (const attrKey of Object.keys(item.attributes)) {
            const attrType = item.attributes[attrKey];
            if (!attributeTypes.includes(attrType)) {
                return false;
            }
        }
        // Optional relationships check
        if (item.relationships) {
    
            for (const relKey of Object.keys(item.relationships)) {
                if (!mainKeys.includes(item.relationships[relKey].target)) {
                    return false;
                }
            }
      
            if (typeof item.relationships !== "object" || Array.isArray(item.relationships) || Object.keys(item.relationships).length === 0) {
                return false;
            }

            for (let relKey in item.relationships) {
                // Check if the relationships keys matches one of the main keys
                if (!mainKeys.includes(relKey)) {
                    return false;
                }
                const relationship = item.relationships[relKey];
                if (!relationship || typeof relationship !== "object" || Object.keys(relationship).sort().toString() !== ["target", "type"].sort().toString()) {
                    return false;
                }

                // Check if the "target" value matches one of the main keys
                if (!mainKeys.includes(relationship.target)) {
                    return false;
                }

                // Check if the "type" value is "array" or "single"
                if (!relationshipTypes.includes(relationship.type)) {
                    return false;
                }

            }
        }
    }
    return true;
}

  const agentChat = () => {
    //// // console.log("=========currentuseraata---->", currentUserData)
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [generatingResponseInProgress, setGeneratingResponseInProgress] = useState(false);
    const [appBuildingInProgress, setAppBuildingInProgress] = useState();
    const [activeProjectId, setActiveProjectId] = useState();
    const [activeBuildId, setActiveBuildId] = useState();
    const [activeBuildStatus, setActiveBuildStatus] = useState();
    const [buildFailed, setBuildFailed] = useState();
    const [previewIsReady, setPreviewIsReady] = useState(false);
    const [startingNewProject, setStartingNewProject] = useState(false);
    const intervalRef = useRef(null); // Use useRef to store the interval ID
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const [open, setOpen] = useState(false);
    const [paymentError, setPaymentError] = useState(null);
    const [paymentProcessing, setPaymentProcessing] = useState();
    const [tl, setTL] = useState(false);
    const [creditError, setCreditError] = useState(false); // New state for managing credit input validation
    const [showButton, setShowButton] = useState(false);
    const [isPreviewAvailable, setIsPreviewAvailable] = useState(true);
    const textFieldDivRef = useRef(null);

    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true)
    }

    useEffect(() => {
      if (user.email) { 
        
        const onChildAdd = Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/pixie/weblogs/`)
        .limitToLast(100)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            const messagesArray = Object.entries(data).map(([key, value]) => ({ key, ...value }));
            //const lastStartIndex = messagesArray.map((e, i) => e.prompt === "Start New Project" ? i : -1).lastIndexOf(Math.max(...messagesArray.map((e, i) => e.prompt === "Start New Project" ? i : -1)));
            const lastStartIndex = messagesArray.map((e, i) => e.prompt.trim().toLowerCase() === "start new project" ? i : -1).lastIndexOf(Math.max(...messagesArray.map((e, i) => e.prompt.trim().toLowerCase() === "start new project" ? i : -1)));

            // Filter out the messages after the last "Start New Project"
            const filteredMessages = messagesArray.slice(lastStartIndex + 1);
            // Convert the filtered messages back to an object
            let newMessages;
            if (messagesArray.length == 1) {
              newMessages = messagesArray.reduce((acc, current) => {
                acc[current.key] = current;
                delete acc[current.key].key; // Remove the key from the value object
                return acc;
              }, {});
            } else {
              newMessages = filteredMessages.reduce((acc, current) => {
                acc[current.key] = current;
                delete acc[current.key].key; // Remove the key from the value object
                return acc;
              }, {});
              
            }
            //// console.log("===newMessages====", newMessages)

            //// // console.log("====newMessages===>", newMessages)
            setMessages(newMessages);
            // //// // console.log("====data===>", data)
          }
        });
        return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/pixie/weblogs/`).off('value', onChildAdd);  
      }
    }, [user.email])

    useEffect(() => {
      async function fetchData() {
        if (user.email && currentUserData && currentUserData.apikey) {
          const onChildAdd = Firebase.database()
            .ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/`)
            .on('value', async (snapshot) => {
              const data = snapshot.val();
              if (data) {
                const projectId = Object.keys(data)
                //// // console.log("=--=======projectId============>",projectId,  data[projectId[0]])
                if(projectId.length > 0) {
                  //TODO remove all active projects
                };

                if (data[projectId[0]] && data[projectId[0]].status && data[projectId[0]].status.toLowerCase() === "failure") { 
                  
                  Firebase.database().ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/${projectId[0]}`).set(null)
                  setAppBuildingInProgress(false)
                  setActiveProjectId(null)
                }
                const status = data[projectId[0]].status.toLowerCase()
                if (data[projectId[0]] && data[projectId[0]].status && (status === "progress" || status === "working" || status === "queued") ) { 
                  //TODO check status and update
                  //// // console.log("====data[projectId[0]===>", data)
                  setAppBuildingInProgress(true)
                  //// // console.log("=======setting from firebae data", projectId[0])
                  setActiveBuildId(data[projectId[0]]["buildId"])
                  setActiveBuildStatus(status)
                  //console.log("====setting from firebase data", projectId[0])
                  setActiveProjectId(projectId[0])
                  // let myHeaders = new Headers();
                  // myHeaders.append("Authorization", currentUserData.apikey);
                  // myHeaders.append("Content-Type", "application/json");
                  // const raw = JSON.stringify({email: user.email,  buildId: data[projectId[0]]["buildId"]});
                  // const requestOptions = {
                  //     method: 'POST',
                  //     headers: myHeaders,
                  //     body: raw,
                  //     redirect: 'follow'
                  // };
                        // await checkAndUpdateBuildStatus()

                    // const buildStatus = await fetch("https://us-central1-gptconsole.cloudfunctions.net/checkBuildStatus", requestOptions)
                    // const result = await buildStatus.json()
                    // if (result && result["status"] && result["status"].toLowerCase() === "success") { 
                    //   Firebase.database().ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/${projectId[0]}`).update({ status: "success" })
                    // }
                    // if (result && result["status"] && result["status"].toLowerCase() === "failure") { 
                    //   Firebase.database().ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/${projectId[0]}`).update({ status: "failure" })
                    // } 
                    
                }

                if (data[projectId[0]] && data[projectId[0]].status && data[projectId[0]].status.toLowerCase() === "success") { 
                  //TODO check status and update
                  setAppBuildingInProgress(false)
                  setActiveProjectId(projectId[0])
                }
              }
            });
    
          return () => Firebase.database().ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/`).off('value', onChildAdd);
        }
      }
    
      fetchData();
    }, [user.email, currentUserData]);  // Depend on currentUserData as well

    useEffect(() => {
      if (!activeProjectId || !activeBuildId) {
        // // console.log("=====inside useEffect====", activeProjectId);
        return;
      }
    
      // Define the function that checks and updates the build status
      async function checkAndUpdatebuild() {
        await checkAndUpdateBuildStatus();
      }
    
      // Call it immediately if activeProjectId is available
      checkAndUpdatebuild();
    
      // Then set up an interval to call it every 5 seconds
      intervalRef.current = setInterval(() => {
        // // console.log("Checking and updating build status...");
        checkAndUpdatebuild();
      }, 30000); // 5000 milliseconds = 5 seconds
    
      // Clear the interval when the component unmounts or activeProjectId changes
      return () => clearInterval(intervalRef.current);
    }, [activeProjectId, activeBuildId]);
    
    useEffect(() => {
      async function checkPreview() {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", currentUserData.apikey);
        myHeaders.append("Content-Type", "application/json");
      
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({ email: user.email, projectId: activeProjectId }),
          redirect: 'follow'
        };
      
        const response = await fetch("https://us-central1-gptconsole.cloudfunctions.net/isPreviewAvailable", requestOptions);
        const result = await response.json();
        if (result && result.status && result.status.toLowerCase() === "valid") {
          setIsPreviewAvailable(true);
        } else {
          setIsPreviewAvailable(false);
        }
      }
    
      if (activeProjectId) {
        checkPreview();
      }
      
    }, [activeProjectId]); // Consider adding dependencies related to the async function
    

    useEffect(() => {
      const shouldShowButton = (messages && Object.keys(messages).length > 0) ;
      setShowButton(shouldShowButton);
     // Function to call when user scrolls

      
    }, [messages]); // Dependencies
    

    useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // The empty div is visible, indicating the user has scrolled to the bottom
              setShowButton(false); // Hide the button
            } else {
              // The div is not visible, user is not at the bottom
              setShowButton(true); // Show the button
            }
          });
        },
        {
          root: null, // assuming the viewport is the scroll container
          rootMargin: '0px',
          threshold: 1.0, // Adjust this value based on how much of the div must be visible to consider it intersecting
        }
      );

      if (textFieldDivRef.current) {
        observer.observe(textFieldDivRef.current);
      }

      return () => {
        if (textFieldDivRef.current) {
          observer.disconnect();
        }
      };
    }, []); // Make sure to correctly set dependencies if needed


    async function checkPreviewStatus(tempActiveProjectId) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", currentUserData.apikey);
      myHeaders.append("Content-Type", "application/json");
    
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ email: user.email, projectId: tempActiveProjectId }),
        redirect: 'follow'
      };
    
      const response = await fetch("https://us-central1-gptconsole.cloudfunctions.net/isPreviewAvailable", requestOptions);
      const result = await response.json();
      if (result && result.status && result.status.toLowerCase() === "valid") {
        setIsPreviewAvailable(true);
      } else {
        setIsPreviewAvailable(false);
      }
    }

    
    async function checkAndUpdateBuildStatus() {

      if (!activeProjectId || !activeBuildId) {
        return;
      }
      //// // console.log("=====checkAndUpdateBuildStatus====", activeBuildId)
      let myHeaders = new Headers();
      myHeaders.append("Authorization", currentUserData.apikey);
      myHeaders.append("Content-Type", "application/json");
    
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ email: user.email, buildId: activeBuildId }),
        redirect: 'follow'
      };
    
      //// // console.log("=====calling====", activeBuildId);
    
      try {
        const response = await fetch("https://us-central1-gptconsole.cloudfunctions.net/checkBuildStatus", requestOptions);
        const result = await response.json();
        if (result && result.status) {
          let newStatus = result.status.toLowerCase();

          Firebase.database().ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/${activeProjectId}`).update({ status: newStatus });
          
          if (newStatus === "success" || newStatus === "failure") {
            setAppBuildingInProgress(false);
            setActiveProjectId(activeProjectId); // Optionally clear the activeProjectId here
            clearInterval(intervalRef.current); // Clear the interval when status is success or failure
            await checkPreviewStatus(activeProjectId);
          }
        }
      } catch (error) {
        setAppBuildingInProgress(true);
        //console.error("Error updating build status:", error);
        // Optionally clear the interval on error if you don't want to continue checking
      }
    }
    
    const handleNewProject = async () => {
      try {
        setMessages([]);
        setStartingNewProject(true);
        if (activeProjectId) {
          let myHeaders = new Headers();
          myHeaders.append("Authorization", currentUserData.apikey);
          myHeaders.append("Content-Type", "application/json");
          const raw = JSON.stringify({ email: user.email, siteId: activeProjectId });
          setActiveProjectId(null);

          const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
            const buildStatus = await fetch("https://us-central1-gptconsole.cloudfunctions.net/dangerouslyDeleteSite", requestOptions)
            const result = await buildStatus.json()
            ////// // console.log("====status===>", result["buildId"])
    
            Firebase.database()
            .ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/${activeProjectId}`).set(null)
            
        }
        
  
        await Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/pixie/weblogs/`).push({ 
          prompt: "Start New Project",
        });
        setStartingNewProject(false);
      } catch (e) {
        setMessages([]);
  
        setActiveProjectId(null);
        setStartingNewProject(false);
      }
     

    }

    const handleSend = async () => {
      try {
        
        setGeneratingResponseInProgress(true);
       
        if (activeProjectId) {
          let myHeaders = new Headers();
          myHeaders.append("Authorization", currentUserData.apikey);
          myHeaders.append("Content-Type", "application/json");
          const lastMessageEntry = Object.entries(messages ?? {}).pop(); // Gets the last [key, value] pair, safely handling null
  
          let lastMessage = lastMessageEntry ? lastMessageEntry[1] : null;
          let previousString = ""
          if (lastMessage) {
            previousString = `Previous Prompt: ${lastMessage.prompt}\n Previous Response:${JSON.stringify(lastMessage.resp)}`;
          }
  
          let raw = JSON.stringify({ email: user.email, prompt: inputText, previous: previousString, applicationId: activeProjectId });
          let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          const a = await fetch("https://us-central1-gptconsole.cloudfunctions.net/pixieUpdateApplication", requestOptions)
          let result = await a.json()

          setInputText('')
          setGeneratingResponseInProgress(false);

        } else {
          let myHeaders = new Headers();
          myHeaders.append("Authorization", currentUserData.apikey);
          myHeaders.append("Content-Type", "application/json");
          const lastMessageEntry = Object.entries(messages ?? {}).pop(); // Gets the last [key, value] pair, safely handling null
  
          let lastMessage = lastMessageEntry ? lastMessageEntry[1] : null;
          let previousString = ""
          if (lastMessage) {
            previousString = `Previous Prompt: ${lastMessage.prompt}\n Previous Response:${JSON.stringify(lastMessage.appConfig)}`;
          }
  
          let raw = JSON.stringify({ email: user.email, prompt: inputText, previous: previousString });
          let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          const a = await fetch("https://us-central1-gptconsole.cloudfunctions.net/pixieRequirementAnalysis", requestOptions)
          let result = await a.json()
          setInputText('')
          setGeneratingResponseInProgress(false);
        }
       
        ////////// // console.log("====result2===>", a2)
      } catch (e) { 
        // // console.log("====error===>", e)
        setInputText('')
        setGeneratingResponseInProgress(false);

      }
    };

    function normalizeLineBreaks(str) {
      return str.replace(/\n\n/g, '\n');
    }

    function generateSubUrl(email, prompt) {
      // Normalize the email
      let normalizedEmail = user.email.substring(0, user.email.indexOf('@')).replace(/[^a-zA-Z0-9]/g, '-');
      
      // Extract words from the prompt
      let words = prompt.match(/\b(\w+)\b/g);
      let randomWord = '';
      if (words && words.length > 0) {
        randomWord = words[Math.floor(Math.random() * words.length)];
      }
      
      // Generate timestamp
      let timestamp = Date.now();
      
      // Concatenate to form sub-URL
      return `${normalizedEmail}-${randomWord}-${timestamp}`;
    }
    
    const deleteActiveProjects = async () => { 
      const activeProjectData = await Firebase.database().ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}`).once('value')
        
      const activeProjects = activeProjectData.val()
      const projectIds = activeProjects ? Object.keys(activeProjects) : [];
      if (projectIds.length > 0) {
        for (let i = 0; i < projectIds.length; i++) {
          const activeProjectId = projectIds[i];
          if (activeProjectId) {
            let myHeaders = new Headers();
            myHeaders.append("Authorization", currentUserData.apikey);
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({email: user.email, siteId: activeProjectId});
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };
              const buildStatus = await fetch("https://us-central1-gptconsole.cloudfunctions.net/dangerouslyDeleteSite", requestOptions)
              const result = await buildStatus.json()
              ////// // console.log("====status===>", result["buildId"])
      
              Firebase.database()
              .ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/${activeProjectId}`).set(null)
          }
        }
      }
      
    }
    
    const buildNow = async (appConfig, prompt) => {
      //delete all active projects if any
      setAppBuildingInProgress(true)

      await deleteActiveProjects();

      const projectId = generateSubUrl(user.email, prompt);
      Firebase.database()
        .ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/${projectId}`).update({ status: "progress" })
      Firebase.database()
        .ref(`/previewprojects/${user.email.replace(/\./g, ",")}/${projectId}`).update({ status: "progress", prompt: prompt, appConfig: appConfig })
      ////// // console.log("====projectId===>", projectId, appConfig["dashboard"]["entities"])
      let myHeaders = new Headers();
      myHeaders.append("Authorization", currentUserData.apikey);
      myHeaders.append("Content-Type", "application/json");
      let adjustedConfig = {};
      if (appConfig) {
          if (appConfig["dashboard"]) {
              adjustedConfig = appConfig["dashboard"]["entities"];
          } else if (appConfig["aiapplication"]) {
              // Corrected typo from `config["aiapplicatio"]` to `config["aiapplication"]`
              adjustedConfig = appConfig["aiapplication"]["features"];
          }
      }
      
      let raw = JSON.stringify({email: user.email,  demoProjectId: projectId, appConfig: adjustedConfig });
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      const keysToCheck = ["customtextgeneration", "imagegeneration", "textgeneration", "texttospeech"];
      
      // Check if at least one of the specified keys is true
      const hasTrueFeature = keysToCheck.some(key => adjustedConfig[key] === true);
      let result;
      if (hasTrueFeature) {
        const a = await fetch("https://us-central1-gptconsole.cloudfunctions.net/firebaseCloudBuildChatApplication", requestOptions)
        result = await a.json()
      } else if (appConfig["landingpage"]) {
        const lastMessageEntry = Object.entries(messages ?? {}).pop(); // Gets the last [key, value] pair, safely handling null
  
        let lastMessage = lastMessageEntry ? lastMessageEntry[1] : null;
        let previousPrompt = ""
        if (lastMessage) {
          previousPrompt = `Previous Prompt: ${lastMessage.prompt}\n`;
        }

        let raw = JSON.stringify({email: user.email,  demoProjectId: projectId, userRequirement: `${prompt} \n ${previousPrompt}` });
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        const a = await fetch("https://us-central1-gptconsole.cloudfunctions.net/firebaseCloudBuildLandingpage", requestOptions)
        result = await a.json()
      }else {
        const a = await fetch("https://us-central1-gptconsole.cloudfunctions.net/firebaseCloudBuild", requestOptions)
        result = await a.json()
       }  
      Firebase.database()
        .ref(`/previewprojects/${user.email.replace(/\./g, ",")}/${projectId}`).update({ buildId: result["buildId"] })
      Firebase.database()
        .ref(`/activepreviewprojects/${user.email.replace(/\./g, ",")}/${projectId}`).update({ buildId: result["buildId"], status: "progress" })
      
      // // console.log("=====setting from build now====", projectId)
      setActiveProjectId(projectId)
      setActiveBuildId(result["buildId"])
      // await checkAndUpdateBuildStatus()
      // ////// // console.log("====buildresult===>", result["buildId"])

      // raw = JSON.stringify({email: user.email,  buildId: result["buildId"]});
      // requestOptions = {
      //   method: 'POST',
      //   headers: myHeaders,
      //   body: raw,
      //   redirect: 'follow'
      // };
      // const buildStatus = await fetch("https://us-central1-gptconsole.cloudfunctions.net/checkBuildStatus", requestOptions)
      // result = await buildStatus.json()

      // //// // console.log("====status===>", result["status"])
      

    }
    const handleDownload = async () => {
      if (!currentUserData.method) {
        setOpen(true)
        return;
      }
      
      //// // console.log("============", activeProjectId)
      setDownloadInProgress(true); // Show the loader
      // Download logic here
      // // console.log("Download logic here");
      let myHeaders = new Headers();
      myHeaders.append("Authorization", currentUserData.apikey);
      myHeaders.append("Content-Type", "application/json");
    
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ email: user.email, projectId: activeProjectId }),
        redirect: 'follow'
      };
    
      // // // console.log("=====calling====", activeProjectId);
    
      try {
        const response = await fetch("https://us-central1-gptconsole.cloudfunctions.net/downloadProjectZip", requestOptions);
        const result = await response.json();
        if (result.url) {
          const link = document.createElement('a');
          link.href = result.url;
          link.setAttribute('download', ''); // This is optional and can be omitted
          document.body.appendChild(link); // Append to page
          link.click(); // Programmatically click the link to trigger the download
          document.body.removeChild(link); // Clean up and remove the link
        }
      } catch (error) {
        console.error("Error downloading code", error);
      } finally {
          setDownloadInProgress(false); // Stop showing the loader
      }
    };

    function generateRandomState() {
      // Generates a random string; you can replace this with a more robust solution
      return Math.random().toString(36).substring(7);
    }

    const NoCreditsMessage = () => {
      return (
        <>
          <br />
          <p style={{textAlign: "center", padding: "5px"}}>You currently have no credits available. Please {" "}
            <NavLink to="/admin/settings">
            <Button style={{
              color: 'inherit', // Use the current text color
              backgroundColor: 'transparent', // Ensure the button has a transparent background
              boxShadow: 'none', // No shadow for a flat appearance
              textTransform: 'none', // Prevent uppercase text transformation
              padding: 0, // Remove padding to mimic inline text
              minWidth: 0, // Allow the button to shrink to the size of its content
            }}>
              Purchase credits
              </Button>
              
            </NavLink>
            {" "} to continue using our services.</p>
        </>
      );
    };

    const purchaseAndDownload = async () => {
      try {
         
          const cardElement = elements.getElement(CardElement);
  
          if (!cardElement || !cardElement._complete) {
            setPaymentError("Please enter card details");
            return;
          }
          setPaymentError(null);
          setPaymentProcessing(true)
                    
          var config = {
            method: 'post',
            url: 'https://us-central1-gptconsole.cloudfunctions.net/stripeCreatePaymentIntentV2',
            headers: { 
              'Content-Type': 'application/json',
              'id': 2000
            }
          };
      
          //// // console.log("-----req-----", config)
          const resp = await axios(config)
          //// // console.log("-----resp-----", resp)
  
          if (!resp || (resp && !resp.data)) {
            if (!tl) {
              setTL(true);
              setTimeout(function () {
                setTL(false);
              }, 6000);
            }
            setPaymentError("Something Went Wrong, Please try again. You are not charged")
            setPaymentProcessing(false)
            return;
          }
    
          const {paymentIntent, error} = await stripe
          .confirmCardPayment(resp.data.client_secret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                name: user.email,
              },
            },
          })
          if (error) {
            if (!tl) {
              setTL(true);
              setTimeout(function () {
                setTL(false);
              }, 6000);
            }
            setPaymentProcessing(false);
            setPaymentError(error.message)
          }
        if (paymentIntent && paymentIntent.status === "succeeded") {
          setPaymentProcessing(false)
          setOpen(false)
           //// // console.log("============", activeProjectId)
            setDownloadInProgress(true); // Show the loader
            // Download logic here
            let myHeaders = new Headers();
            myHeaders.append("Authorization", currentUserData.apikey);
            myHeaders.append("Content-Type", "application/json");
          
            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({ email: user.email, projectId: activeProjectId, paymentIntentId: paymentIntent.id }),
              redirect: 'follow'
            };
          
            // // // console.log("=====calling====", activeProjectId);
          
            try {
              const response = await fetch("https://us-central1-gptconsole.cloudfunctions.net/downloadProjectZip", requestOptions);
              const result = await response.json();
              if (result.url) {
                const link = document.createElement('a');
                link.href = result.url;
                link.setAttribute('download', ''); // This is optional and can be omitted
                document.body.appendChild(link); // Append to page
                link.click(); // Programmatically click the link to trigger the download
                document.body.removeChild(link); // Clean up and remove the link
              }
            } catch (error) {
              console.error("Error downloading code", error);
            } finally {
                setDownloadInProgress(false); // Stop showing the loader
            }
          
            
         
            // setOrderPlaced(true);
          }
           
        } catch (error) {
          if (!tl) {
            setTL(true);
            setTimeout(function () {
              setTL(false);
            }, 6000);
          }
          setPaymentError(true)
          setPaymentProcessing(false)
  
          console.error(error);
        }    
    }

    // Assuming you have activeProjectId, user.email, and projectId defined



    return (<>
       <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.5);
            }
            100% {
              transform: scale(1);
            }
          }

          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          
          .fadeInUp {
            animation: fadeInUp 1s ease-out forwards;
          }

          .buttonOutline {
            min-width: '300px';
            marginRight: '20px';
            justifyContent: "flex-start";
            boxShadow: 'none';
            textTransform: 'none';
            border: '1px solid rgba(0, 0, 0, 0.23)';
            paddingLeft: 8;
            paddingRight: 8;
            color: "grey";
            backgroundColor: 'transparent';
          }
          
          .buttonOutline:hover {
            border-color: black; /* Change outline to black on hover */
          }
          
          
        `}
      </style>
      <Snackbar
        place="tc"
        color="rose"
        message="Oops! There was an issue processing your payment. Please check your payment details and try again. If the problem persists, consider using a different payment method or contacting our customer service for assistance"
        open={tl}
        closeNotification={() => setTL(false)}
        close
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Download Code</DialogTitle>
      <DialogContent style={{ minHeight: "400px" }}>
        <DialogContent>
          <div style={{ backgroundColor: "#f3f5f9", padding: "20px 7%", backgroundClip: "border-box" }}>
            <GridContainer>
            <GridItem xs={12}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <InfoIcon style={{ marginRight: "10px" }} />
          <p>Full source code for the showcased demo project</p>
        </div>
      </GridItem>

     

      <GridItem xs={12}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <CloudDownloadIcon style={{ marginRight: "10px" }} />
          <p>Detailed instructions for running the project locally and deploying to the cloud</p>
        </div>
      </GridItem>

      <GridItem xs={12}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <SecurityIcon style={{ marginRight: "10px" }} />
          <p>Source includes commented login and payment integration for customization </p>
        </div>
      </GridItem>
      {/* <GridItem xs={12}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <AccessTimeIcon style={{ marginRight: "10px" }} />
          <p>1 hour of senior developer consulting (additional charges apply)</p>
        </div>
      </GridItem> */}

      
    
              <Divider style={{ border: "0", width: "100%", height: "1px", marginBottom: "20px" }} />
    
              <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center" }}>
                <LocalOfferIcon style={{ marginRight: "10px" }} />
                <span>Total cost</span>
                <span style={{ marginLeft: "auto", fontWeight: "900" }}>$20</span>
                </GridItem>
              </GridContainer>
              <br />
              {/* <div style={{fontSize: "8px", textAlign: "center" }}>
                <Danger >
                  Please send an email to info@gptconsole.ai if you have any questions or need assistance
                </Danger>
              </div> */}
              
            </div>
          <br />
          {!(currentUserData && currentUserData.method === 'subscription') && (
            <CardElement options={{}} />
          )}
        </DialogContent>
        <br />
          <DialogActions>
          {paymentProcessing && <CircularProgress />}

          <Button onClick={handleClose} color="primary" disabled={paymentProcessing}>
            Close
          </Button>
          <Button onClick={() => { purchaseAndDownload() }} color="primary" disabled={paymentProcessing}>
            Confirm Purchase
            </Button>
          {paymentError && <p style={{ color: "red", textAlign: "center" }}>{paymentError}</p>}

          </DialogActions>
          
          <p style={{ textAlign: "center" }}> Or {" "}
          <NavLink to="/admin/settings">
            <Button style={{
              color: 'inherit', // Use the current text color
              backgroundColor: 'transparent', // Ensure the button has a transparent background
              boxShadow: 'none', // No shadow for a flat appearance
              textTransform: 'none', // Prevent uppercase text transformation
              padding: 0, // Remove padding to mimic inline text
              minWidth: 0, // Allow the button to shrink to the size of its content
            }}>
              Purchase monthly subscription
              </Button>
              
            </NavLink>
            for unlimited code downloads</p>
      </DialogContent>
     
      </Dialog>

      

    <Card>      
      {messages && Object.keys(messages).length > 0 ? (
        <CardHeader color="primary" icon>
          <CardIcon color="rose" style={{ float: "right", padding: "0px",  background: "gray",
                boxShadow: "none", }}>
            <Button 
              onClick={() => handleNewProject()}
                size="sm"
                color="gray"
              style={{
                background: "gray",
                boxShadow: "none",
                padding: "5px"
              }}
                disabled={generatingResponseInProgress || appBuildingInProgress}
              >
              Start Fresh
              </Button>
              
            </CardIcon>
        </CardHeader>
      ): ""}
        <CardBody>
          {startingNewProject && (
            <div style={{
              display: 'inline-block',
              margin: '10px',
              width: '20px',
              height: '20px',
              backgroundColor: 'black',
              borderRadius: '50%',
              animation: 'pulse 1.5s infinite ease-in-out'
            }}></div>
          ) }
          {!startingNewProject && messages && Object.keys(messages).length == 0 && (<GridContainer
              justify="space-between"
              style={{
                color: "grey",
                justifyContent: "center",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              <h3>
              {"What do you want me to build ?"}
            </h3>
            <p>
              <small>I can build simple AI applications, dashboards, and landing pages for any business scenario </small>
              <br />
              <small>You can see the working app in action, try it out, and download the production-ready code if needed.</small>

              </p>
          </GridContainer>)}
          
          <div  style={{ width: '100%', marginTop: '20px' }}>
        {messages && Object.entries(messages).filter(([key, chatMessage]) => !chatMessage.resp).map(([key, chatMessage], index) => (
          <div key={key}>
            {/* Render the prompt */}
            <div style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap' }}>
              <div style={{ fontWeight: 'bold' }}>You</div>
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                {chatMessage.prompt}
              </div>
            </div>
            {/* Render the chip's response */}
            <div style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap' }}>
              <div style={{ fontWeight: 'bold' }}>Pixie</div>
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                <div style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                  
                  {chatMessage && chatMessage.appConfig ? (
                    <>
                      
                      <br />
                      {chatMessage.appConfig === "Error, Please try again" ? (<>
                        <div style={{ padding: "10px", display: 'flex', alignItems: 'center' }}>
                          <p style={{color: "red"}}>
                          <strong>Error </strong>
                        {" Please Try Again"}
                          </p>
                        <br />
                      </div>                      
                        <br />
                      </>) : (<>
                      
                        <div style={{ padding: "10px", display: 'flex', alignItems: 'center' }}>
                        <strong>Config:{" "}</strong>
                        <CollapsibleFileList config={chatMessage.appConfig} />
                        <br />
                      </div>                      
                      <br />
                      <div style={{"padding": "10px"}}>
                      <strong>Application Features:</strong>    
                      <div id="explainer-container"
                        dangerouslySetInnerHTML={{ __html: generateExplainerHTML(chatMessage.appConfig) }}>
                      </div>
                      </div>
                      <div style={{ height: '60px' }}> {/* Spacer div */}</div>
                      {index === Object.keys(messages).length - 1 && !appBuildingInProgress && !activeProjectId && (<Button
                        color="success"
                        size="sm"
                        onClick={() => buildNow(chatMessage.appConfig, chatMessage.prompt)} // Corrected to an arrow function call
                        style={{
                          position: 'absolute',
                          right: 10,
                          bottom: 10,
                          marginBottom: "20px"
                        }}>
                        Build Now
                      </Button>)}
                     
                      </>)}
                      
 

                      </>
                  ) : (
                      <>
                        <br />
                    <div style={{
                      display: 'inline-block',
                      margin: '10px',
                      width: '10px',
                      height: '10px',
                      backgroundColor: 'black',
                      borderRadius: '50%',
                      animation: 'pulse 1.5s infinite ease-in-out'
                        }}></div> 
                        </>
                  )}
                </div>

                
              </div>
            </div>
          </div>
        ))}
          </div>

          {
            appBuildingInProgress ? (
              <>
               
                <p>Your application development is underway! It typically takes 5-10 minutes.. The process will continue even if you navigate away.</p>
                {activeBuildStatus && (<> <p>Build Status: <b>{activeBuildStatus}</b></p></>)}
                <div style={{
                  display: 'inline-block',
                  margin: '10px',
                  width: '20px',
                  height: '20px',
                  backgroundColor: 'black',
                  borderRadius: '50%',
                  animation: 'pulse 1.5s infinite ease-in-out'
                }}></div>
              </>
            ) : activeProjectId ? (
                <>
                <br />
                <div style={{ fontWeight: 'bold' }}>Pixie</div>
                <div style={{
                    background: '#f8f8f8', // Lighter shade for a fresher look
                    padding: '24px', // Uniform padding
                    borderRadius: '12px', // Soften the corners a bit
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                  }}>
                    <div style={{
                      display: 'flex', // Enables flex layout
                      justifyContent: 'space-between', // Distributes space between and around content items
                      alignItems: 'center', // Centers items vertically
                      marginBottom: '24px', // Space below this container, before the iframe
                    }}>
                      <p style={{
                        fontSize: '14px',
                        color: "#4a4a4a",
                        lineHeight: '1.6',
                        flex: '70%', // Allocates 70% of the container's width to the text
                        marginRight: '20px', // Adds some space between the text and the button
                      }}>
                        Below is the preview of the app you requested. If it meets your expectations, you have the option to download the code. Feel free to request updates; these will be provided as suggestions to the existing code.
                      </p>
                      <Button 
                        onClick={() => { handleDownload() }}
                        color="success"
                        size="sm"
                        style={{
                          flex: '10%', // Allocates 30% of the container's width to the button
                          padding: '5px 10px', // Button padding for better touch
                          fontSize: '14px', // Button font size for readability
                          borderRadius: '8px', // Rounds the corners of the button
                          border: 'none', // Removes the default border
                          cursor: 'pointer', // Changes cursor on hover        
                      }}>
                        Download Code
                      </Button>

                      {downloadInProgress && <CircularProgress />}
                    </div>

                   
                    {activeProjectId && (<div
                      id ="iframeContainer"
                      style={{
                      position: 'relative', // Parent container is relative
                      
                    }}>
                      {/* {checkUrlAndDisplayIframe()} */}
                      {isPreviewAvailable ? (
                      <iframe
                        src={`https://${activeProjectId}.web.app/`}
                        style={{
                          width: '100%', // Full-width iframe
                          border: 'none', // Remove border
                          borderRadius: '12px', // Consistent border radius with the container
                          height: '500px', // Fixed height for better control over layout
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Slightly more pronounced shadow for the iframe
                        }}
                        title="App Preview"
                      />

                      ) : (
                        <div
                          style={{
                            width: '100%', // Full-width div
                            border: 'none', // Remove border
                            borderRadius: '12px', // Consistent border radius with the container
                            height: '500px', // Fixed height for better control over layout
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Slightly more pronounced shadow for the div
                            display: 'flex', // Use flexbox for centering
                            alignItems: 'center', // Center vertically
                            justifyContent: 'center', // Center horizontally
                            padding: '20px', // Add some padding around the text
                            boxSizing: 'border-box', // Include padding in the height calculation
                              backgroundColor: '#f0f0f0', // A light background color for the div
                              fontSize: '22px', // Slightly larger font size for emphasis
                              color: "#4a4a4a", // Consistent text color for readability
                          }}
                          title="App Preview"
                        >
                          Sorry, preview is not available due to resource shortage
                        </div>
                      )}
                    </div>)}
                    <p style={{
                      marginTop: '20px', // Add space above the bottom paragraph
                      padding: '10px', // Padding inside the paragraph for separation
                      fontSize: '16px', // Slightly larger font size for emphasis
                      color: "#4a4a4a", // Consistent text color for readability
                      backgroundColor: '#f9f9f9', // Very light background for subtle emphasis
                      borderLeft: '4px solid #4a4a4a', // A colored bar to add a hint of prominence without overwhelming
                    }}>
                      From here, every new prompt will be for updating the generated application.
                      <br />
                      Previews are not available for updates
                      <br />
                      Click 'Start Fresh' in the top right corner to create a new one, your current project will be deleted
                  </p>

                  </div>
              </>
            ) : null
          }

        {messages && Object.entries(messages).filter(([key, chatMessage]) => chatMessage.resp).map(([key, chatMessage], index) => (
          <div key={key}>
            {/* Render the prompt */}
            <div style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap' }}>
              <div style={{ fontWeight: 'bold' }}>You</div>
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                {chatMessage.prompt}
              </div>
            </div>
            {/* Render the chip's response */}
            <div style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap' }}>
              <div style={{ fontWeight: 'bold' }}>Pixie</div>
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                <div style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                  
                <div className="fadeInUp">
                      <ReactMarkdown>{normalizeLineBreaks(chatMessage.resp)}</ReactMarkdown>
                </div>
                </div>

                
              </div>
            </div>
          </div>
        ))}


          
            {generatingResponseInProgress && (<div style={{
                      display: 'inline-block',
                      margin: '10px',
                      width: '20px',
                      height: '20px',
                      backgroundColor: 'black',
                      borderRadius: '50%',
                      animation: 'pulse 1.5s infinite ease-in-out'
            }}></div>)}
              

        {/* <div style={{ width: '100%', marginTop: '20px' }}>
        {messages.map((msg, index) => (
          <div key={index} style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap'  }}>
            <div style={{ fontWeight: 'bold' }}>{msg.sender}</div>
            {msg.sender === 'Pixie' && msg.iframe ? (
              index === messages.length - 1 ? (
                <>
                  <iframe 
                    src="https://mandlinsarah-managing-1707745254341.web.app/" 
                    style={{ width: '100%', border: '1px solid gray', borderRadius: '15px', height: "80vh" }}
                    title="Pixie Response"
                  />
                  <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <Button color="twitter" size="sm" onClick={handleDownload} style={{ marginTop: '0px'}}>
                      Download
                    </Button>
                  </div>
                </>
              ) : (
                <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px', height: '2em', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {"Old version - See latest"}
                </div>
                )
            ) : (
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                {msg.text}
              </div>
            )}
          </div>
        ))}
        </div> */}
        
          

        {!generatingResponseInProgress && (!messages || messages && Object.keys(messages).length == 0) && (<div >
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('I want an application for project management');
          }}
        >
         I want an application for project management
          </Button>
          <br />
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('I want an AI application that generates images and converts text to speech');
          }}
        >
          I want an AI application that generates images and converts text to speech
          </Button>
          <br />
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('I want a landing page for my business, which sells gaming headphones');
          }}
        >
                       
            I want a landing page for my business, which sells gaming headphones
          </Button>
              <br />
               
              
          </div>)}
          
          <div style={{ width: '100%', marginTop: '40px' }}></div>
          {!startingNewProject && (<div>
              <TextField
                ref={textFieldDivRef}
              fullWidth
              multiline
              rows={2}
              rowsMax={9}
              variant="outlined"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'ArrowUp' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      onClick={handleSend}
                      disabled={generatingResponseInProgress || appBuildingInProgress || (!currentUserData?.method && currentUserData?.availablecredits < 10) || inputText.trim() === ''} 
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ borderRadius: 20 }} // Rounded corners
              disabled={generatingResponseInProgress || appBuildingInProgress || (!currentUserData?.method && currentUserData?.availablecredits < 10)}    
              />
              {!currentUserData?.method && currentUserData?.availablecredits < 10 && <NoCreditsMessage />}

          </div>)}
      </CardBody>
      </Card>
      {showButton && (
        <IconButton
            onClick={() => {
              textFieldDivRef.current.scrollIntoView({ behavior: 'smooth' })
              setShowButton(false)
            }}
          style={{
            position: 'fixed',
            bottom: '10%', // Adjust according to your needs
            right: '40%', // Adjust according to your needs
            zIndex: 1000, // Make sure it's above other items
            backgroundColor: 'gray', // Set background color to gray
            borderRadius: '50%', // Make it round
            color: 'white', // Optional: change the icon color to white for contrast
            padding: '10px', // Optional: adjust padding to ensure the round shape is visible if needed
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)' // Optional: add a slight shadow for better visibility
          }}
        >
          <ArrowDownwardIcon />
        </IconButton>
      )}
      </>
  );

  }


const agentSettings = () => {
  const pixieConfig = {
    lifespan: "~30 mins",
    taskType: {
      landingPages: true,
      appDevelopment: false
    },
    goal: "Based on Prompt",
    codingLanguage: "Reactjs",
    backend: ["GCP", "Firebase"],
    messaging: "SEO Friendly",
    imageGeneration: "Leonardo"
  };

  return (
    <>
      <Card>  
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <div style={{ padding: "30px", fontSize: "15px" }}>Lifespan</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>Task Type</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>Goal</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>Tech Stack</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>Image Generation</div>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <div style={{ padding: "30px", fontSize: "15px" }}>{pixieConfig.lifespan}</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>
                Landing Pages: {pixieConfig.taskType.landingPages ? "Enabled" : "Disabled"},
                App Development: {pixieConfig.taskType.appDevelopment ? "Enabled" : "Disabled"}
              </div>
              <div style={{ padding: "30px", fontSize: "15px" }}>{pixieConfig.goal}</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>{pixieConfig.codingLanguage}, {pixieConfig.backend.join(", ")}</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>{pixieConfig.imageGeneration}</div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};


  const timeCardScreen = () => {
    return (
      <>
        <div style={{ alignItems: "center", justifyContent: "center", padding: "0px", marginTop: "-50px" }}>
          <Heading
            textAlign="center"
            title="Pixie"
            className={classes.buttonLink}
            style={{ padding: "0px", margin: "0px", margin: "0px!important" }}
          // category={currentUserData?.apikey}
          />
        </div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              headerColor="success"
              // customFunction={customFunctionDP}
              tabs={[
                {
                  tabName: "Agent Chat",
                  tabIcon: ChatIcon,
                  tabContent: (
                    <>
                      {agentChat()}
                    </>
                  ),
                },
                {
                  tabName: "CLI Logs",
                  tabIcon: TimerIcon,
                  tabContent: (
                    <>
                      {lifeCycle()}
                    </>
                  ),
                },
                {
                  tabName: "Agent Configuration",
                  tabIcon: SettingsIcon,
                  tabContent: (
                    <>
                      {agentSettings()}
                    </>
                  ),
                },
                // {
                //   tabName: "Task Details",
                //   tabIcon: DashboardIcon,
                //   tabContent: (
                //     <>
                //       {agentTaskDetails()}
                //     </>
                //   ),
                // },
              ]}
            >
            </CustomTabs>
          </GridItem>
        </GridContainer>


      </>
    )
  }
  return (
    <div>
      {timeCardScreen()}
    </div>
  );
}
