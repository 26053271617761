import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import {
  NavLink, Redirect
} from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import GroupIcon from '@material-ui/icons/Group';
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import RedeemIcon from '@material-ui/icons/Redeem';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
import CryptoJS from 'crypto-js'


import Firebase from "firebase";

// const firebaseConfig = {
//   apiKey: "AIzaSyBjFBb39m0ii_nbnSNt-xtS8TmZoAwO45U",
//   authDomain: "gptconsole.firebaseapp.com",
//   databaseURL: "https://gptconsole-default-rtdb.firebaseio.com",
//   projectId: "gptconsole",
//   storageBucket: "gptconsole.appspot.com",
//   messagingSenderId: "730500216840",
//   appId: "1:730500216840:web:af0f001f83f5ac051ff9bd",
//   measurementId: "G-V9C0SLHQLC"
// };
// Firebase.initializeApp(firebaseConfig);


import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";


const useStyles = makeStyles(styles);

export default function CliLogin() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  const [currentUserData, setCurrentUserData] = React.useState();
 
  React.useEffect(() => {
    if (currentUserData?.apikey) {
      const lowerCaseEmail = user.email.toLowerCase();
      fetch("http://localhost:8085/?token="+currentUserData.apikey+"&user="+lowerCaseEmail)
    }
    // const result = await a.json()
    // //console.log(result)
      
  }, [currentUserData])

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  
  return (
    <div className={classes.container}>
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={10}>
        <Card className={classes.cardSignup}>
          <h2 className={classes.cardTitle}>Login successful</h2>
          <CardBody>
             <div className={classes.center}>
                <h3> Please close this window </h3>
              </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  </div>
  );
}
