import React from 'react';
import { Grid, Tabs, Tab, Typography, Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Icons
import CodeIcon from '@material-ui/icons/Code';
import CloudIcon from '@material-ui/icons/Cloud';
import ComputerIcon from '@material-ui/icons/Computer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: theme.spacing(2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`api-tabpanel-${index}`}
      aria-labelledby={`api-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `api-tab-${index}`,
    'aria-controls': `api-tabpanel-${index}`,
  };
}

function ApiDocumentationTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="API documentation tabs"
        >
          <Tab label="CURL" icon={<CloudIcon />} {...a11yProps(0)} />
          <Tab label="JavaScript" icon={<CodeIcon />} {...a11yProps(1)} />
          <Tab label="Python" icon={<ComputerIcon />} {...a11yProps(2)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Typography variant="body2" component="pre">
          curl --location 'https://api.gptconsole.ai' \{'\n'}
          --header 'Authorization: rPWe7M9TNJEeu1UXS1Sh2tpVcp7IJM9ICWLWJ6REQy8YFI=' \{'\n'}
          --header 'Content-Type: application/json' \{'\n'}
          --data-raw '{{"email": "gptconsoledemo@gmail.com", "prompt": "cute puppy being happy face"}}'
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant="body2" component="pre">
          const requestOptions = {'{\n'}
          method: 'POST',\{'\n'}
          headers: myHeaders,\{'\n'}
          body: raw,\{'\n'}
          redirect: 'follow'\{'\n'}
          {'};\n'}
          fetch('https://api.gptconsole.ai', requestOptions)\{'\n'}
      
          {'}\n'}
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography variant="body2" component="pre">
          import http.client\{'\n'}
          import json\{'\n'}
          const conn = new http.client.HTTPSConnection('api.gptconsole.ai');\{'\n'}
          const payload = json.dumps({{'email': 'gptconsoledemo@gmail.com', 'prompt': 'cute puppy being happy face'}});\{'\n'}
          const headers = {'{\n'}
          'Authorization': 'rPWe7M9TNJEeu1UXS1Sh2tpVcp7IJM9ICWLWJ6REQy8YFI=',\{'\n'}
          'Content-Type': 'application/json'\{'\n'}
          {'};\n'}
          conn.request('POST', '/', payload, headers);\{'\n'}
          const res = conn.getresponse();\{'\n'}
          const data = res.read();\{'\n'}
          console.log(data.decode('utf-8'));\{'\n'}
          {'}\n'}
        </Typography>
      </TabPanel>
    </div>
  );
}

export default ApiDocumentationTabs;
