import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import {
  NavLink,Redirect, useLocation
} from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import GroupIcon from '@material-ui/icons/Group';
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import RedeemIcon from '@material-ui/icons/Redeem';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
import CryptoJS from 'crypto-js'
import {markdownText} from "./doc.js"
import ReactMarkdown from 'react-markdown';

import Firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBjFBb39m0ii_nbnSNt-xtS8TmZoAwO45U",
  authDomain: "gptconsole.firebaseapp.com",
  databaseURL: "https://gptconsole-default-rtdb.firebaseio.com",
  projectId: "gptconsole",
  storageBucket: "gptconsole.appspot.com",
  messagingSenderId: "730500216840",
  appId: "1:730500216840:web:af0f001f83f5ac051ff9bd",
  measurementId: "G-V9C0SLHQLC"
};
Firebase.initializeApp(firebaseConfig);


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";


const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  const [currentUserData, setCurrentUserData] = React.useState();
  const [activityLogs, setActivityLogs] = React.useState({ doodle: [], pixie: [], chip: [] });
  const noLogsAvailable = !activityLogs.doodle.length && !activityLogs.pixie.length && !activityLogs.chip.length;

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  React.useEffect(() => {
    const fetchData = async () => {
      const agents = ['doodle', 'pixie', 'chip'];
      const logs = { doodle: [], pixie: [], chip: [] };

      for (const agent of agents) {
        const snapshot = await Firebase.database()
          .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/${agent}/weblogs`)
          .limitToLast(3)
          .once('value');

        snapshot.forEach(childSnapshot => {
          const data = childSnapshot.val();
          if (data.prompt !== "Start New Project") {
            if (agent === 'doodle') {
              logs.doodle.push([data.prompt, <a href={data.url} target="_blank" rel="noopener noreferrer">View Art</a>]);
            } else if (agent === 'chip') {
              const t = Object.values(data)
              for (let i = 0; i < t.length; i++) {
                logs.chip.push([t[i].prompt, ""]);
              }
            } else if (agent === 'pixie') {
              logs.pixie.push([data.prompt, ""]);
            }
          }
        });
      }
     setActivityLogs(logs);
    };

    fetchData();
  }, []);

  const noLifeCycleEvents = () => {
    return (
      <Card>
      <GridContainer justify="space-between"
        style={{
          color: "grey",
          justifyContent: "center",
          height: "200px",
          display: "inline-block",
          textAlign: "center"
        }}>
        <h3>No Activity Logs, please start using agents <NavLink to={"/admin/agents"}>
                   here
                </NavLink></h3>
        <br />
        </GridContainer>
        </Card>
    )
  }

  const cardStyle = {
    margin: '20px 0',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#fff',
    width: '100%'
  };

  const headerStyle = {
    backgroundColor: 'gray',
    color: 'white',
    padding: '10px',
    borderRadius: '4px',
    fontSize: '18px',
    fontWeight: 'bold'
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Agents Available</p>
              <h3 className={classes.cardTitle}>5</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <NavLink to={"/admin/agents"}>
                  Go To Agents
                </NavLink>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <GroupIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Billing</p>
              <h4 className={classes.cardTitle}>{currentUserData?.currentplan}</h4>
              {currentUserData?.availablecredits && <h4 className={classes.cardTitle}>Available Credits: {currentUserData?.availablecredits}</h4>}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <NavLink to={"/admin/settings"}>
                  Go To Settings
                </NavLink>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
            {noLogsAvailable ? noLifeCycleEvents() : <>
              <div style={{ padding: '20px', width: "100%" }}>
                <h1 style={{ textAlign: 'center' }}>Activity Logs</h1>
                <Card style={cardStyle}>
                  <CardHeader style={headerStyle}>
                    Doodle Logs
                  </CardHeader>
                  <GridContainer justify="space-between">
                    <GridItem xs={12} sm={12} md={12}>
                      <Table
                        tableData={activityLogs.doodle.length ? activityLogs.doodle : [["No activity logs for Doodle"]]}
                      />
                    </GridItem>
                  </GridContainer>

                  <CardHeader style={headerStyle}>
                    Pixie Logs
                  </CardHeader>
                  <GridContainer justify="space-between">
                    <GridItem xs={12} sm={12} md={12}>
                      <Table
                        tableData={activityLogs.pixie.length ? activityLogs.pixie : [["No activity logs for Pixie"]]}
                      />
                    </GridItem>
                  </GridContainer>

                  <CardHeader style={headerStyle}>
                    CHIP Logs
                  </CardHeader>
                  <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                      <Table
                        tableData={activityLogs.chip.length ? activityLogs.chip : [["No activity logs for CHIP"]]}
                      />
                    </GridItem>
                  </GridContainer>
                </Card>
              </div>
            </>}

  </GridContainer>
    </div>
  );
}
