import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import firebase from "firebase/app";
import "firebase/auth";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [logedOut, setLogedOut] = React.useState(false);

  const handleLogout = async () => {
    await firebase.auth().signOut()
    setLogedOut(true);
  }

  const classes = useStyles();
  const { rtlActive } = props;
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  

  const redirectToLogin = () => {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  
  if(logedOut){
    return redirectToLogin()
  }else{
    return ( <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color="transparent"
          style={{color: "red"}}
          className={classes.buttonLink}
          onClick={handleLogout}
        >
        Logout
        </Button>
      </div>
    </div>)
  }
  
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
  userEmail: PropTypes.string
};
