
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import React, { useState, useEffect } from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import {
  NavLink
} from "react-router-dom";

// @material-ui/icons
// import InfoOutline from "@material-ui/icons/InfoOutline";
import GroupIcon from '@material-ui/icons/Group';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// core components
import Table from "components/Table/Table.js";
import moment from "moment";
import Phone from "@material-ui/icons/Phone";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import LaunchIcon from '@material-ui/icons/Launch';
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import avatar from "assets/img/faces/marc.jpg";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import Accordion from "components/Accordion/Accordion.js";
import Switch from "@material-ui/core/Switch";
import SweetAlert from "react-bootstrap-sweetalert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import StorefrontIcon from '@material-ui/icons/Storefront';
import TimerIcon from '@material-ui/icons/Timer';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import SettingsIcon from '@material-ui/icons/Settings';
import Quote from "components/Typography/Quote.js";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import { cardTitle } from "assets/jss/material-dashboard-pro-js";
// core components
import Heading from "components/Heading/Heading.js";
import Firebase from "firebase";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Person";
import EditIcon from '@material-ui/icons/Edit';
const SMBase64 = require('smbase64')
let base64 = new SMBase64()
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Timeline from "components/Timeline/Timeline.js";
import ChatIcon from '@material-ui/icons/Chat';
import CardTravel from "@material-ui/icons/CardTravel";
import Extension from "@material-ui/icons/Extension";
import Fingerprint from "@material-ui/icons/Fingerprint";
import FlightLand from "@material-ui/icons/FlightLand";
import Build from "@material-ui/icons/Build";
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import WorkIcon from '@material-ui/icons/Work';
import HelpOutline from "@material-ui/icons/HelpOutline";
import NavPills from "components/NavPills/NavPills.js";
import TimelineIcon from '@material-ui/icons/Timeline';
import Badge from "components/Badge/Badge.js";
import { mapStyles } from "variables/mapstyles";
import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
} from "variables/charts.js";
import ChartistGraph from "react-chartist";
import StorageIcon from '@material-ui/icons/Storage';
import { CSVLink, CSVDownload } from "react-csv";
import { uniqueNamesGenerator, starWars } from 'unique-names-generator';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import Slider from "nouislider";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Datetime from "react-datetime";
import Snackbar from "components/Snackbar/Snackbar.js";

import Popover from '@material-ui/core/Popover';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import axios from "axios";
import Divider from "@material-ui/core/Divider";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";


const useStyles = makeStyles(styles);



const fieldsForReport = [
  "Branch name",
  "Branch location",
  "Team member name",
  "Checkin Time",
  "Checkout Time",
  "Hours spent",]


export default function AutoGPTPage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
 
  const [currentUserData, setCurrentUserData] = useState();
  const [agentLiceCycle, setAgentLiceCycle] = useState();
  const [createDatabaseModal, setCreateDatabaseModal] = React.useState(false);
  const [showCreatLoading, setShowCreatLoading] = React.useState(false);
  const [tl, setTL] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(false);

  const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  const createDatabase = async () => {
    let ref;
    try {
      setShowCreatLoading(true);
      ref = Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/autogpt/`)
        .push({
          start: moment().format("DD-MM-YYYY hh:mm A").toString(),
          status: "Processing",
        });
  
      const myHeaders = new Headers();
      myHeaders.append("Authorization", currentUserData.apikey);
      myHeaders.append("Content-Type", "application/json");
  
      const raw = JSON.stringify({
        autogptid: ref.key,
        email: user.email,
      });
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
  
      const createResult = await fetch(
        "https://us-central1-onroad3-e593b.cloudfunctions.net/createAutoGPTCloud",
        requestOptions
      );
      const createResultJson = await createResult.json();
      //console.log("-----createResultJson-----", createResultJson);
      await Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/autogpt/${ref.key}/resource/create`)
        .set(createResultJson);
  
      const dropletID = createResultJson.droplet.id;
      await sleep(30000);
      const detailsRaw = JSON.stringify({ instanceId: dropletID });
      const detailsRequestOptions = {
        method: "POST",
        headers: myHeaders,
        body: detailsRaw,
        redirect: "follow",
      };
  
      const detailsResult = await fetch(
        "https://us-central1-onroad3-e593b.cloudfunctions.net/getAutoGPTDetails",
        detailsRequestOptions
      );
      const detailsResultJson = await detailsResult.json();
      //console.log("-----detailsResultJson-----", detailsResultJson);
  
      await Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/autogpt/${ref.key}/resource/details`)
        .set(detailsResultJson);
      await Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/autogpt/${ref.key}/status`)
        .set("Active");
  
      setShowCreatLoading(false);
      setCreateDatabaseModal(false);
    } catch (error) {
      console.error("Error:", error);
      Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/autogpt/${ref.key}`).set(null);
      // Handle the error here or re-throw it to propagate the error
      
      if (!tl) {
        setTL(true);
        setTimeout(function () {
          setTL(false);
        }, 6000);
      }    
      setShowCreatLoading(false);
      setCreateDatabaseModal(false);
    }
  };
  
  const deleteAutoGPTInstanceCallback = async (key) => {
    if (!tl) {
      setDeleteError(true);
      setTimeout(function () {
        setDeleteError(false);
      }, 6000);
    } 
  }
  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/autogpt/`)
      .limitToLast(100)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            if (element && element.start && element.status) {
              let durationString, link, deleteButton;
              if (element.status == 'Active' && element.resource && element.resource.droplet && element.resource.droplet.id) {
                const startTime = moment(element.start, "DD-MM-YYYY hh:mm A");
                const currentTime = moment(); // Gets current time
                const diffDuration = moment.duration(currentTime.diff(startTime));
                durationString = `${diffDuration.hours()}:${diffDuration.minutes()}`;
                link = `AutoGPTAccessLink:${element.access}`
                deleteButton = `removeAutoGPTInstance:${keys[index]}-|-email-|-${user.email.replace(/\./g, ",")}-|-apikey-|-${currentUserData.apikey}-|-dropletid-|-${element.resource.droplet.id}`
              } else {
                const startTime = moment(element.start, "DD-MM-YYYY hh:mm A");
                const endtime = moment(element.end, "DD-MM-YYYY hh:mm A");
                const diffDuration = moment.duration(endtime.diff(startTime));
                durationString = `${diffDuration.hours()}:${diffDuration.minutes()}`;
                link = ""
                deleteButton = ""
              }
             
              result.push([element.status, element.start, durationString, `AutoGPTAccessLink:${element.access}`, deleteButton])
            }
          }
          setAgentLiceCycle(result);
        }
      });
    return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/autogpt`).off('value', onChildAdd);
  }, [currentUserData]) 

  const noLifeCycleEvents = () => {
    return (
      <GridContainer justify="space-between"
        style={{
          color: "grey",
          justifyContent: "center",
          height: "200px",
          display: "inline-block",
          textAlign: "center"
        }}>
        <h3>No AutoGPT instances have been created yet.</h3>
      </GridContainer>
    )
  }

  const lifeCycle = () => {
    return (<>
      <Card>
  
      <Dialog open={createDatabaseModal} aria-labelledby="form-dialog-title" fullWidth keepMounted>
        <DialogTitle id="form-dialog-title">Create AugotGPT Agent</DialogTitle>
        <DialogContent>
          <FormControlLabel
              control={
                <Radio
                  checked={true}
                  aria-label="B"
                  icon={
                    <FiberManualRecord
                      className={classes.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={classes.radioChecked}
                    />
                  }
                  classes={{
                    checked: classes.radio,
                    disabled: classes.disabledCheckboxAndRadio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
                label={
                  (<>
                    <p style={{ fontWeight: "bolder", color: "black", paddingRight: "10px"}}>
                      {/* $15/mo ($0.022/hr) */}
                      AugotGPT
                    </p>
                  <p>
                      1 vCPU / 1 GB RAM / 15 GB SSD
                  </p>
                  </>)
                }
            /> 
            <br />
            <br />
            <DialogContentText>
              Totla Monthly Cost
            </DialogContentText>
            <div style={{backgroundColor: "#f3f5f9", paddingLeft: "7%",  paddingTop: "20px", backgroundClip: "border-box", paddingRight: "20px"} }>
             <GridContainer>
                <GridItem xs={1} sm={1} md={1}>
                  <StorageIcon />
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                    <span>AugotGPT</span>
                    <p>
                    1 vCPU / 1 GB RAM / 25 GB SSD
                    </p>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  $15.00
                </GridItem>
                <br />
               
                <Divider style={{border: "0", clear:"both", display:"block", width: "96%", height: "1px"}} />
                <br />                
                <GridItem xs={1} sm={1} md={1}>
                  <LocalOfferIcon />
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                    <span>Total cost</span>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                    <span style={{fontWeight: "900"}}>$15/month</span>
                    <p>$0.022/hour</p>
                </GridItem>                
              </GridContainer>
            </div>
            <DialogContentText>
             <p style={{fontSize: "12px"}}>After creation, you can access your AutoGPT-enabled VM via browser-based SSH. Start with the official AutoGPT<a href="https://github.com/Significant-Gravitas/Auto-GPT" target="_blank">guide</a> </p>
            </DialogContentText>
       

        </DialogContent>
          <DialogActions>
          <Button size="sm" onClick={() => setCreateDatabaseModal(false)} color="danger" >
          Dismiss
        </Button>
        
        {showCreatLoading && <CircularProgress />}

        <Button  size="sm" onClick={() => createDatabase()} color="primary" disabled={!currentUserData?.isbillingenabled || showCreatLoading}>
          Proceed and Create
        </Button>
        <br />            
        </DialogActions>
          {!currentUserData?.isbillingenabled && <p style={{ fontSize: "12px", textAlign: "right", paddingRight: "20px" }}>Please enable billing to create an AutoGPT instance. { " "}
          <NavLink to={"/admin/settings"}>
            Configure Billing
          </NavLink>
          </p>}
      </Dialog>
      <CardHeader color="rose" icon>
        
        <CardIcon color="rose" style={{float: "right", padding: "0px"}}>
          <Button onClick={() => {setCreateDatabaseModal(true)}}
            style = {{
              background: "transparent",
              boxShadow: "none",
              padding:"10px"
            }}
              disabled={!currentUserData?.isbillingenabled || agentLiceCycle?.filter(entry => entry[0] === 'Active').length > 4}
            >
           Create AutoGPT
            </Button>
         
          </CardIcon>
      </CardHeader>
        <CardBody>
          <div style={{textAlign: "right"}}>
          {agentLiceCycle?.filter(entry => entry[0] === 'Active').length > 4 && "Max allowed instances are 5"}
          </div>

          {agentLiceCycle && agentLiceCycle.length > 0 ? (
            <Table
              tableHeaderColor="primary"
              tableHead={["Status", "Start Time", "Duration", "Access", "Delete"]}
              tableData={agentLiceCycle}
              coloredColls={[3]}
              colorsColls={["primary"]}
              deleteAutoGPTInstanceCallback={deleteAutoGPTInstanceCallback}
            />) : noLifeCycleEvents()}

        </CardBody>
      </Card>
    </>)

  }



  const agentSettings = () => {

    return (<>
    
    <Card>
        <CardBody>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <div style={{padding: "30px", fontSize: "15px"}}>Version</div>
            <div style={{padding: "30px", fontSize: "15px"}}>OS</div>
            <div style={{padding: "30px", fontSize: "15px"}}>CPU</div>
            <div style={{padding: "30px", fontSize: "15px"}}>Memory</div>
            <div style={{padding: "30px", fontSize: "15px"}}>Disk</div>
            
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <div style={{padding: "30px", fontSize: "15px"}}>Auto-GPT v0.4.2</div>
            <div style={{padding: "30px", fontSize: "15px"}}>Ubuntu 22.10 x64</div>
            <div style={{padding: "30px", fontSize: "15px"}}>1 vCPU</div>
            <div style={{padding: "30px", fontSize: "15px"}}>1GB</div>
            <div style={{padding: "30px", fontSize: "15px"}}>25GB SSD</div>
            
          </GridItem>
        </GridContainer>
        </CardBody>
      </Card>
    </>)
  }

  const timeCardScreen = () => {
    return (
      <>
         <Snackbar
            place="tc"
            color="rose"
            message="Oops! There was an issue creating AutoGPTCloud Agent. Please try again later."
            open={tl}
            closeNotification={() => setTL(false)}
            close
        />
        <Snackbar
          place="tc"
          color="rose"
          message="Oops! Unable to delete the agent. Please try again later."
          open={deleteError}
          closeNotification={() => setDeleteError(false)}
          close
        />
        <div style={{ alignItems: "center", justifyContent: "center", padding: "0px", marginTop: "-50px" }}>
          <Heading
            textAlign="center"
            title="AutoGPT Cloud"
            className={classes.buttonLink}
            style={{ padding: "0px", margin: "0px", margin: "0px!important" }}
          // category={currentUserData?.apikey}
          />
        </div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              headerColor="success"
              // customFunction={customFunctionDP}
              tabs={[
                {
                  tabName: "Agent Lifecycle",
                  tabIcon: TimerIcon,
                  tabContent: (
                    <>
                      {lifeCycle()}
                    </>
                  ),
                },
                {
                  tabName: "Agent Configuration",
                  tabIcon: SettingsIcon,
                  tabContent: (
                    <>
                      {agentSettings()}
                    </>
                  ),
                },
                // {
                //   tabName: "Task Details",
                //   tabIcon: DashboardIcon,
                //   tabContent: (
                //     <>
                //       {agentTaskDetails()}
                //     </>
                //   ),
                // },
              ]}
            >
            </CustomTabs>
          </GridItem>
        </GridContainer>


      </>
    )
  }
  return (
    <div>
      {timeCardScreen()}
    </div>
  );
}
