import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function Quote(props) {
  const classes = useStyles();
  const { text, author } = props;
  return (
    <blockquote className={classes.defaultFontStyle + " " + classes.quote}>
      <p className={classes.quoteText} style={{fontSize: "12px"}}>{text}</p>
      <small className={classes.quoteAuthor}>
        {Array.isArray(author) ? (
          author.map((line, index) => (
            <React.Fragment key={index}>
              {line}{index < author.length - 1 ? <br /> : null}
            </React.Fragment>
          ))
        ) : (
          author
        )}
      </small>
    </blockquote>
  );
}
