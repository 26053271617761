/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, useLocation } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import {loadStripe} from '@stripe/stripe-js';
import Unsubscribe from "views/Pages/Unsubscribe.js";

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
const stripePromise = loadStripe('pk_live_51K9af5BfGfOYVrvwM5FcBWYD5pIP0qKUqI0dzjV24SqHobHGAvGLJByrtOdvrYggIHIbshO28wm7IhvtxojR4Utf00Q6PYT9kU')
//pk_test_51K9af5BfGfOYVrvwzumqhvEMn4mkG8P45l3B2u2ertsjdJxGfPOwfX7RxTjN1RaYLP6COVMAgC3xFxdPs4ulsWd800qUHy0sL1')//pk_live_51K9af5BfGfOYVrvwM5FcBWYD5pIP0qKUqI0dzjV24SqHobHGAvGLJByrtOdvrYggIHIbshO28wm7IhvtxojR4Utf00Q6PYT9kU');


ReactDOM.render(
  <Elements stripe={stripePromise}>
    <BrowserRouter>
      <Switch>
        {/* <Route path="/rtl" component={RtlLayout} /> */}
      <Route path="/unsubscribe" component={Unsubscribe} />
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Redirect from="/" to="/auth" />
      </Switch>
    </BrowserRouter>
  </Elements>,
  document.getElementById("root")
);
