
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useState, useEffect, useRef } from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components

// @material-ui/icons
// import InfoOutline from "@material-ui/icons/InfoOutline";
// core components
import Table from "components/Table/Table.js";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import LinearProgress from '@material-ui/core/LinearProgress';

import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import TimerIcon from '@material-ui/icons/Timer';
import ChatIcon from '@material-ui/icons/Chat';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from "@material-ui/core/Divider";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import { Paper, Typography } from '@material-ui/core';
import ReactDiffViewer from 'react-diff-viewer';
import Danger from "components/Typography/Danger.js";
import { NavLink } from 'react-router-dom';


// import { cardTitle } from "assets/jss/material-dashboard-pro-js";
// core components
import Heading from "components/Heading/Heading.js";
import Firebase from "firebase";
const SMBase64 = require('smbase64')
let base64 = new SMBase64()

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";


const useStyles = makeStyles(styles);




export default function ChipPage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
  const location = useLocation(); // Hook to access current location

  const code = location.state && location.state.code;
  //console.log("chip   Code: ", code);
  const [currentUserData, setCurrentUserData] = useState();
  const [agentLiceCycle, setAgentLiceCycle] = useState();
  const [chiplearning, setChiplearning] = useState();
  
  // const location = useLocation(); // Hook to access current location

  // const queryParams = new URLSearchParams(location.search);
  // const code = queryParams.get('code'); // Get 'code' parameter from URL
  // //console.log("chip   Code: ", code);

  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  
  

 

  useEffect(() => {
    const fetchData = async () => {
      if (code && currentUserData && user.email) {
        //console.log("=======calling chipReadGitRepos====", code, currentUserData.apikey, user.email);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", currentUserData.apikey);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({ email: user.email, code: code });
        //console.log("payload ", raw);
  
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
  
        try {
          const response = await fetch("https://us-central1-gptconsole.cloudfunctions.net/chipReadGitRepos", requestOptions);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // Assuming you want to do something with the response data
          const data = await response.json();
          //console.log(data); // Or handle data as needed
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      }
    };
  
    fetchData();
  }, [code, currentUserData, user.email]); // Added user.email to dependencies as its value is used inside the effect
  


  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/chiplearning/${user.email.replace(/\./g, ",")}/`)
      .limitToLast(100)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setChiplearning(data);
        }
      });
    return () => Firebase.database().ref(`/chiplearning/${user.email.replace(/\./g, ",")}/`).off('value', onChildAdd);
  }, []) 
    
 
  function toTitleCase(str) {
    return str.toLowerCase().replace(/(^|\s)\S/g, (c) => c.toUpperCase());
  }
  function truncate(str) {
    return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
  }
  
  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/chip/logs`)
      .limitToLast(100)
      .on('value', snapshot => {
        const data = snapshot.val()
        // ////console.log("====data----", data)
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];            
            if (element && element.operation) {
              result.push([element.localdate, element.localtime, toTitleCase(element.operation), truncate(element.operationdescription)])
            }
          }
          setAgentLiceCycle(result);
        }
      });
    return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/chip/logs`).off('value', onChildAdd);
  }, []) 

  const noLifeCycleEvents = () => {
    return (
      <GridContainer justify="space-between"
        style={{
          color: "grey",
          justifyContent: "center",
          height: "200px",
          display: "inline-block",
          textAlign: "center"
        }}>
        <h3>No lifecycle events found today. The agent has not been initiated yet.</h3>
        <br />
        <p>
          Follow the instructions mentioned &nbsp;
          <a href="https://github.com/hribab/gpt-console#readme" target="_blank" style={{ display: 'inline-flex', alignItems: 'center' }}>
            here
            <OpenInNewIcon style={{ fontSize: '1rem', marginLeft: '4px' }} />
                  </a> 
                  &nbsp;
          to initiate the console.
        </p>
      </GridContainer>
    )
  }

  const lifeCycle = () => {
    return (<>
      <Card>
        <CardBody>
          {agentLiceCycle && agentLiceCycle.length > 0 ? (
            <Table
              tableHeaderColor="primary"
              tableHead={["Date", "Time", "Task", "Log"]}
              tableData={agentLiceCycle}
              coloredColls={[3]}
              colorsColls={["primary"]}
            />) : noLifeCycleEvents()}

        </CardBody>
      </Card>
    </>)

  }

  function CollapsibleFileList({ files }) {
    const [open, setOpen] = useState(false);
  
    const handleToggle = () => {
      setOpen(!open);
    };
  
    // Inside CollapsibleFileList
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={handleToggle}
          aria-expanded={open}
          aria-label="show more"
          size="small" // Adjust the size as needed
        >
          <ExpandMoreIcon />
        </IconButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file}</li>
            ))}
          </ul>
        </Collapse>
      </div>
    );

  }
  

  const agentChat = () => {
    //console.log("-----currentuser====", user.email, currentUserData)
    //const isConnectedToGITHUB = true;// TODO: to be read from database
    // const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');

    const [selectedRepo, setSelectedRepo] = useState(''); // State for selected repo
    const [selectedRepoFiles, setSelectedRepoFiles] = useState('');
    const [selectedRepoPRs, setSelectedRepoPRs] = useState();
    const [filePrefix, setFilePrefix] = useState();
    const [repoChatMessages, setRepoChatMessages] = useState();
    const [prChatMessages, setPrChatMessages] = useState();
    const [generatingResponseInProgress, setGeneratingResponseInProgress] = useState(false);
    const [isConnectedToGITHUB, setIsConnectedToGITHUB] = useState(false);
    const [selectedPR, setSelectedPR] = useState(null);
    const [isLoadingPRs, setIsLoadingPRs] = useState(false);
    const [isPRListEmpty, setIsPRListEmpty] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const textFieldDivRef = useRef(null);

    const NoCreditsMessage = () => {
      return (
        <>
          <br />
          <p style={{textAlign: "center", padding: "5px"}}>You currently have no credits available. Please {" "}
            <NavLink to="/admin/settings">
            <Button style={{
              color: 'inherit', // Use the current text color
              backgroundColor: 'transparent', // Ensure the button has a transparent background
              boxShadow: 'none', // No shadow for a flat appearance
              textTransform: 'none', // Prevent uppercase text transformation
              padding: 0, // Remove padding to mimic inline text
              minWidth: 0, // Allow the button to shrink to the size of its content
            }}>
              Purchase credits
              </Button>
              
            </NavLink>
            {" "} to continue using our services.</p>
        </>
      );
    };
  

    useEffect(() => {
      if (user.email) {
        //console.log("====selectedRepo===>", selectedRepo)
        const onChildAdd = Firebase.database()
        .ref(`/chiplearning/${user.email.replace(/\./g, ",")}/accessToken/`)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setIsConnectedToGITHUB(true);
          } else {
            setIsConnectedToGITHUB(false);
          }
        });
        return () => Firebase.database().ref(`/chiplearning/${user.email.replace(/\./g, ",")}/accessToken/`).off('value', onChildAdd);  
      }
    }, [user.email])


    useEffect(() => {
      if (selectedRepo && !selectedPR) {
        setPrChatMessages(null);
        //console.log("====selectedRepo===>", selectedRepo)
        const onChildAdd = Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/chip/weblogs/${selectedRepo.name}`)
        .limitToLast(100)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setRepoChatMessages(data);
            setShowButton(Object.keys(data).length > 0);
          }
        });
        return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/chip/weblogs/${selectedRepo.name}`).off('value', onChildAdd);  
      }
    }, [selectedRepo])

    useEffect(() => {
      if (selectedPR && selectedPR.number) {
        setRepoChatMessages(null)
        //console.log("====selectedRepo===>", selectedRepo)
        const onChildAdd = Firebase.database()
        .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/chip/prlogs/${selectedRepo.name}/${selectedPR.number}`)
        .limitToLast(100)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            setPrChatMessages(data);
            setShowButton(Object.keys(data).length > 0);
          }
        });
        return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/chip/prlogs/${selectedRepo.name}/${selectedPR.number}`).off('value', onChildAdd);  
      }
    }, [selectedPR])


    useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // The empty div is visible, indicating the user has scrolled to the bottom
              setShowButton(false); // Hide the button
            } else {
              // The div is not visible, user is not at the bottom
              setShowButton(true); // Show the button
            }
          });
        },
        {
          root: null, // assuming the viewport is the scroll container
          rootMargin: '0px',
          threshold: 1.0, // Adjust this value based on how much of the div must be visible to consider it intersecting
        }
      );

      if (textFieldDivRef.current) {
        observer.observe(textFieldDivRef.current);
      }

      return () => {
        if (textFieldDivRef.current) {
          observer.disconnect();
        }
      };
    }, []); // Make sure to correctly set dependencies if needed

        
    const repoUrls = chiplearning?.repos?.map(a => a.clone_url) || []//['https://github.com/repo1https://github.com/login/oauth/authorize?client_id=', 'https://github.com/repo2https://github.com/login/oauth/authorize?client_id=', 'https://github.com/login/oauth/authorize?client_id=']; // Add your repo URLs here
    const handleRepoChange = async (val) => {
      if (val) {
        setSelectedRepo(val);
        setSelectedPR(null);
        setIsLoadingPRs(true); // Start loading
        setIsPRListEmpty(false); // Reset empty list state
        const repoName = val.url.split("https://github.com/")[1];

        setSelectedRepoFiles(Object.values(chiplearning?.repofiles[val.name]).map(s => s.substring(s.indexOf('/') + 1)))
        setRepoChatMessages(null)
          const sampleFile = Object.values(chiplearning?.repofiles[val.name])[0]; // Using the first file as a sample
          const prefix = sampleFile.substring(0, sampleFile.indexOf('/'));
          setFilePrefix(prefix);
        //console.log("====sss===>",prefix, val, Object.values(chiplearning?.repofiles[val.name]).map(s => s.substring(s.indexOf('/') + 1)))
        // Additional logic if needed when repo is selected
         try {
          let myHeaders = new Headers();
          myHeaders.append("Authorization", currentUserData.apikey);
          myHeaders.append("Content-Type", "application/json");
          let raw = JSON.stringify({email: user.email, accessToken: chiplearning.accessToken, repo: repoName });
          let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };  
          const response = await fetch("https://us-central1-gptconsole.cloudfunctions.net/getPullRequests", requestOptions);
          // Assuming you want to do something with the response data
           const data = await response.json();
           //console.log("=========>", data); // Or handle data as needed

           if (data && data.length > 0) {
            setSelectedRepoPRs(data);
            setIsPRListEmpty(false);
          } else {
            setIsPRListEmpty(true);
          }
          
           setIsLoadingPRs(false); // End loading
          //console.log(data); // Or handle data as needed
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      }
     
    };

    const handleSendPR = async () => {
      if (inputText === '') {
        return;
      }
    
      setRepoChatMessages(null)
      setGeneratingResponseInProgress(true);
      let myHeaders = new Headers();
      myHeaders.append("Authorization", currentUserData.apikey);
      myHeaders.append("Content-Type", "application/json");
      const lastMessageEntry = Object.entries(prChatMessages ?? {}).pop(); // Gets the last [key, value] pair, safely handling null

      let lastMessage = lastMessageEntry ? lastMessageEntry[1] : null;
      let previousString = ""
      if (lastMessage) {
        previousString = `Previous Prompt: ${lastMessage.prompt}\n Previous Response:${lastMessage.answer}`;
      }

      let raw = JSON.stringify({
        email: user.email,
        filesstring: selectedRepoFiles.join(","),
        prompt: inputText,
        reponame: selectedRepo.name,
        prnumber: selectedPR.number,
        diffstring: selectedPR.diff,
        description: selectedPR.description,
        previous: previousString
      });
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };  
      const a = await fetch("https://us-central1-gptconsole.cloudfunctions.net/chipAnswerPRQuestions", requestOptions)
      let result = await a.json()
      setInputText('')
      // const result2 = await a2.json()
      setGeneratingResponseInProgress(false);

      return;

    };

    const handleSend = async () => {
      if (inputText === '') {
        return;
      }

      setGeneratingResponseInProgress(true);
      let myHeaders = new Headers();
      myHeaders.append("Authorization", currentUserData.apikey);
      myHeaders.append("Content-Type", "application/json");
      let raw = JSON.stringify({email: user.email, filesstring: selectedRepoFiles.join(","), "prompt": inputText,  reponame: selectedRepo.name});
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };  
      const a = await fetch("https://us-central1-gptconsole.cloudfunctions.net/chipMostRelaventFiles", requestOptions)
      let result = await a.json()
    
      if (!result.listOfFiles.includes("README.md")) {
        result.listOfFiles.push("README.md");
      }
      

      const lastMessageEntry = Object.entries(repoChatMessages ?? {}).pop(); // Gets the last [key, value] pair, safely handling null

      let lastMessage = lastMessageEntry ? lastMessageEntry[1] : null;
      let previousString = ""
      if (lastMessage) {
        previousString = `Previous Prompt: ${lastMessage.prompt}\n Previous Response:${lastMessage.answer}`;
      }

      myHeaders = new Headers();
      myHeaders.append("Authorization", currentUserData.apikey);
      myHeaders.append("Content-Type", "application/json");
      raw = JSON.stringify({email: user.email, filesstring: result.listOfFiles.map(element => `${filePrefix}/${element}`).join(","), reponame: selectedRepo.name, prompt: inputText, id: result.key, previous: previousString});
      requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };  
      const a2 = await fetch("https://us-central1-gptconsole.cloudfunctions.net/chipGenerateAnswer", requestOptions)
      setInputText('')
      // const result2 = await a2.json()
      setGeneratingResponseInProgress(false);
      ////console.log("====result2===>", a2)

    };
    const handleDownload = () => {
      // Download logic here
    };

    function generateRandomState() {
      // Generates a random string; you can replace this with a more robust solution
      return Math.random().toString(36).substring(7);
    }

    const startNew = () => { 
      // setMessages([])
      const clientID = '0cf1b361cf6e473271e7';
      const redirectURI = encodeURIComponent('https://agent.gptconsole.ai/admin/dashboard');
      const scope = encodeURIComponent('repo'); // 'repo' scope allows access to private repos; adjust as needed
      const state = generateRandomState(); // A random string to protect against CSRF attacks
    
      const url = `https://github.com/login/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectURI}&scope=${scope}&state=${state}`;
      //console.log("=======>", url)
      window.location.href = url;
      
    }
    function normalizeLineBreaks(str) {
      return str.replace(/\n\n/g, '\n');
    }


  
    // Collapsible Pull Requests Component
    const CollapsiblePRList = () => {
      const [open, setOpen] = useState(false);
  
      const handleToggle = () => {
        setOpen(!open);
      };

      // <CollapsiblePRList pullRequests={selectedRepoPRs} />
      // {selectedPR && renderSelectedPRDetails()}
      const prLink = `${selectedRepo.url}/pull/${selectedPR.number}`;
      return (
        <>
          <a href={prLink} target="_blank" rel="noopener noreferrer" className={classes.prLink}>
            {`Pull Request: ${selectedPR.title}`}
          </a>
          <IconButton
            onClick={handleToggle}
            aria-expanded={open}
            aria-label="show more"
          >
            <ExpandMoreIcon /> 
          </IconButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
          {renderSelectedPRDetails()}
          </Collapse>
        </>
      );
    };
  

    
    const renderSelectedPRDetails = () => {
      const oldCode = selectedPR.diff.split('\n').filter(line => !line.startsWith('+')).join('\n');
      const newCode = selectedPR.diff.split('\n').filter(line => !line.startsWith('-')).join('\n');
      const customStyles = {
        variables: {
          dark: {
            diffViewerBackground: '#fafafa', // Change the background color if needed
            diffViewerColor: '#424242', // Adjust text color
          },
        },
        line: {
          height: '10px', // Adjust the line height
          padding: '2px 2px', // Decrease the height of each line
          '&:hover': {
            background: '#f7f7f7', // Hover background color
          },
        },
        contentText: {
          fontSize: '10px', // Decrease font size
          fontFamily: 'Monaco, monospace', // Change the font family if desired
          textAlign: 'left', // Align text to the left
        },
        codeFoldGutterBackground: {
          backgroundColor: '#dbdbdb', // Customize code fold gutter background if needed
        },
        gutter: {
          textAlign: 'left', // Align gutter content to the left
        },
      };

      const prLink = `${selectedRepo.url}/pull/${selectedPR.number}`;

      
      return selectedPR ? (
        <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.title}>
          <a href={prLink} target="_blank" rel="noopener noreferrer" className={classes.prLink}>
            {`PR #${selectedPR.number}: ${selectedPR.title}`}
            </a>
          
          </Typography>
          <p>
            {selectedPR.description}    
          </p>
        <ReactDiffViewer
          oldValue={oldCode}
          newValue={newCode}
          splitView={true}
          styles={customStyles}
          leftTitle="Original"
          rightTitle="Changed"
        />
      </Paper>
      ) : (
        <div>No Pull Request Selected</div>
      );
    };

    const handlePRSelection = (pr) => {
      setSelectedPR(selectedRepoPRs.find(p => p.title === pr))
      setRepoChatMessages(null)
      setPrChatMessages(null)
    }
    
    const disConnectGithub = () => {
      setSelectedPR(null)
      setChiplearning(null)
      setRepoChatMessages(null)
      setPrChatMessages(null)
      setSelectedRepo(null)
      setSelectedRepoPRs(null)
      Firebase.database().ref(`/chiplearning/${user.email.replace(/\./g, ",")}/`).remove()
    }

    
    return (
      <>
        <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.5);
            }
            100% {
              transform: scale(1);
            }
          }

          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          
          .fadeInUp {
            animation: fadeInUp 1s ease-out forwards;
          }

          @keyframes fade {
            0%, 39%, 100% { opacity: 0; }
            40% { opacity: 1; }
          }
          
          .loading-dot {
            display: inline-block;
            margin: 10px;
            width: 10px;
            height: 10px;
            background-color: #3498db; /* Pleasant blue, but choose any color you like */
            border-radius: 50%;
            animation: fade 1.2s infinite ease-in-out both;
          }
          
          .loading-dot:nth-child(1) { animation-delay: -1.1s; }
          .loading-dot:nth-child(2) { animation-delay: -1.0s; }
          .loading-dot:nth-child(3) { animation-delay: -0.9s; }
          .loading-dot:nth-child(4) { animation-delay: -0.8s; }
          
          
        `}
        </style>
        
        {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {!isConnectedToGITHUB ? (
            <></>
          ) : (
            <>
              <CustomDropdown
                dropup
                onClick={(val) => handleRepoChange({name: val.split(' - ')[0], url: val.split(' - ')[1]})}
                buttonText="Pick A Repository"
                hoverColor="info"
                dropPlacement="top"
                buttonProps={{
                  round: true,
                  fullWidth: false,
                  style: {
                    marginBottom: "0",
                    padding: "7px", // Reduced padding
                    fontSize: "8px", // Smaller font size
                    minWidth: "auto" // Allow the button to shrink
                  },
                  color: "info"
                }}
                style={{
                  width: "auto",
                  maxWidth: "30%", // Maximum width set to 30%
                  marginRight: '10px', // Add some space between the dropdowns
                }}
                dropdownList={chiplearning?.repos?.map(repo => `${repo.name} - ${repo.url}`) || []}
                />
              
              {isLoadingPRs && <div> &nbsp; Fetching pull requests...</div>}

              {!isLoadingPRs && isPRListEmpty && <Danger> &nbsp; No open pull requests on this repo</Danger>}

              {!isLoadingPRs && !isPRListEmpty && selectedRepo && selectedRepoPRs && selectedRepoPRs.length > 0 && (
                <CustomDropdown
                  dropup
                  onClick={(pr) => {handlePRSelection(pr)}}
                  buttonText="Pick A Pull Request"
                  hoverColor="info"
                  dropPlacement="top"
                  buttonProps={{
                    round: true,
                    fullWidth: false,
                    style: {
                      marginBottom: "0",
                      padding: "7px", // Reduced padding
                      fontSize: "8px", // Smaller font size
                      minWidth: "auto" // Allow the button to shrink
                    },
                    color: "info"
                  }}
                  style={{
                    width: "auto",
                    maxWidth: "30%", // Maximum width set to 30%
                  }}
                  dropdownList={selectedRepoPRs.map(pr => pr.title) || []}
                />
              )}
            </>
          )}
        </div> */}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {!isConnectedToGITHUB ? (
            <></>
          ) : (
            <>
             <CustomDropdown
                dropup
                onClick={(val) => handleRepoChange({name: val.split(' - ')[0], url: val.split(' - ')[1]})}
                buttonText="Pick A Repository"
                hoverColor="info"
                    dropPlacement="bottom"
                    placement="bottom-start"
                buttonProps={{
                  round: true,
                  fullWidth: false,
                  style: {
                    marginBottom: "0",
                    padding: "7px",
                    fontSize: "8px",
                    minWidth: "auto"
                  },
                  color: "info"
                }}
                dropdownStyle={{
                  maxHeight: "200px",
                  overflowY: "auto"
                }}
                dropdownList={chiplearning?.repos?.map(repo => `${repo.name} - ${repo.url}`) || []}
              />

              
              {isLoadingPRs && <div> &nbsp; Fetching pull requests...</div>}

              {!isLoadingPRs && isPRListEmpty && <Danger> &nbsp; No open pull requests on this repo</Danger>}

              {!isLoadingPRs && !isPRListEmpty && selectedRepo && selectedRepoPRs && selectedRepoPRs.length > 0 && (
                <CustomDropdown
                  dropup
                  onClick={(pr) => {handlePRSelection(pr)}}
                  buttonText="Pick A Pull Request"
                  hoverColor="info"
                      dropPlacement="bottom"
                      placement="bottom-start"
                  buttonProps={{
                    round: true,
                    fullWidth: false,
                    style: {
                      marginBottom: "0",
                      padding: "7px", // Reduced padding
                      fontSize: "8px", // Smaller font size
                      minWidth: "auto" // Allow the button to shrink
                    },
                    color: "info"
                  }}
                  dropdownStyle={{
                    maxHeight: "200px",
                    overflowY: "auto"
                  }}
                  dropdownList={selectedRepoPRs.map(pr => pr.title) || []}
                />
              )}
            </>
          )}
          </div>
            {isConnectedToGITHUB && (
            <Button size="sm" color="danger" onClick={disConnectGithub} style={{ marginBottom: "20px", padding: "5px", fontSize: "9px", minWidth: "auto" }}>
              Disconnect GITHUB
            </Button>
            )}
        </div>


      <Card>      
      <CardHeader color="primary" icon>
            
      {selectedRepo ? (
          <GridContainer
            justify="space-between"
            style={{
              color: "grey",
              justifyContent: "center",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            <h3>
              {selectedRepo.name}
              <a
                href={selectedRepo.url}
                target="_blank"
                rel="noopener noreferrer" // Important for security reasons when using target="_blank"
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <OpenInNewIcon style={{ fontSize: '8px', marginLeft: '4px' }} />
              </a>
            </h3>
            {selectedRepo && !selectedPR && <h4>You can ask anything related to this stack. For example: Explain what this stack does?</h4>}    
              {selectedPR && <CollapsiblePRList  />}
          
            {/* <p>{selectedRepoFiles?.join(", ")}</p> */}
          </GridContainer>
        ) : (
          <GridContainer
            justify="space-between"
            style={{
              color: "grey",
              justifyContent: "center",
              display: "inline-block",
              textAlign: "center",
            }}
          >
          <h3>
          {isConnectedToGITHUB ? "Please select a repository" : "Please Connect to GitHub to Browse Repositories"}
                  </h3>
                 {!isConnectedToGITHUB && ( <>
                  <Button size="sm" color="primary" onClick={startNew} style={{ marginBottom: "20px" }}>
            Connect to GitHub
          </Button>
          <br />
                   <small>Connect through the Gptconsole GitHub OAuth app. If you're already connected, please allow a few minutes for loading.</small>
                  </>)}
      </GridContainer>
    )}
      </CardHeader>
      <CardBody >
        
      <div style={{ width: '100%', marginTop: '20px' }}>
        {repoChatMessages && Object.entries(repoChatMessages).map(([key, chatMessage], index) => (
          <div key={key}>
            {/* Render the prompt */}
            <div style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap' }}>
              <div style={{ fontWeight: 'bold' }}>You</div>
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                {chatMessage.prompt}
              </div>
            </div>
            {/* Render the chip's response */}
            <div style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap' }}>
              <div style={{ fontWeight: 'bold' }}>Chip</div>
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <strong>Relevant Files:</strong> Key code files selected to generate the response
                  {chatMessage && chatMessage.listOfFiles ? (
                    <CollapsibleFileList files={chatMessage.listOfFiles} />
                  ) : (
                    <div style={{
                      display: 'inline-block',
                      margin: '10px',
                      width: '10px',
                      height: '10px',
                      backgroundColor: 'black',
                      borderRadius: '50%',
                      animation: 'pulse 1.5s infinite ease-in-out'
                    }}></div> 
                  )}
                </div>

                {chatMessage.listOfFiles && (<>
                  <div>
                {chatMessage && chatMessage.answer ? (
                    <strong>Response:</strong>
                  ) : (
                    <strong>Generating Response:</strong>                  
                  )}
                </div>
                
                <div>
                  {chatMessage && chatMessage.answer ? (
                    <div className="fadeInUp">
                      <ReactMarkdown>{normalizeLineBreaks(chatMessage.answer)}</ReactMarkdown>
                      </div>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="loading-dot"></div>
                    <div className="loading-dot"></div>
                    <div className="loading-dot"></div>
                    <div className="loading-dot"></div>
                  </div>             
                    
                  )}
                </div>
                </>)}
                
              </div>
            </div>

          </div>
        ))}
      </div>

      <div style={{ width: '100%', marginTop: '20px' }}>
        {prChatMessages && Object.entries(prChatMessages).map(([key, chatMessage], index) => (
          <div key={key}>
            {/* Render the prompt */}
            <div style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap' }}>
              <div style={{ fontWeight: 'bold' }}>You</div>
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                {chatMessage.prompt}
              </div>
            </div>
            {/* Render the chip's response */}
            <div style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap' }}>
              <div style={{ fontWeight: 'bold' }}>Chip</div>
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
              <div>
                  {chatMessage && chatMessage.answer ? (
                    <div className="fadeInUp">
                      <ReactMarkdown>{normalizeLineBreaks(chatMessage.answer)}</ReactMarkdown>
                      </div>
                  ) : (
                    <div style={{
                      display: 'inline-block',
                      margin: '10px',
                      width: '10px',
                      height: '10px',
                      backgroundColor: 'black',
                      borderRadius: '50%',
                      animation: 'pulse 1.5s infinite ease-in-out'
                    }}></div>                    
                    
                  )}
                </div>

               
                
              </div>
            </div>

          </div>
        ))}
      </div>

      
        {/* <div style={{ width: '100%', marginTop: '20px' }}>
        {messages.map((msg, index) => (
          <div key={index} style={{ margin: '10px 0', position: 'relative', whiteSpace: 'pre-wrap'  }}>
            <div style={{ fontWeight: 'bold' }}>{msg.sender}</div>
            {msg.sender === 'chip' ? 
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
              <p style={{ padding: '5px', color: "black", fontWeight: "bold", color: "gray" }}>{msg.subtitle}</p>
                {msg.output}
              </div>
             : 
              <div style={{ padding: '10px', background: '#f0f0f0', borderRadius: '15px' }}>
                {msg.text}
              </div>
            }
          </div>
        ))}
        </div> */}
        
        {generatingResponseInProgress && (<div style={{
                      display: 'inline-block',
                      margin: '10px',
                      width: '20px',
                      height: '20px',
                      backgroundColor: 'black',
                      borderRadius: '50%',
                      animation: 'pulse 1.5s infinite ease-in-out'
            }}></div>)}
        <div>
      
      {/* Your component code */}
      </div>
      
            
            
        {selectedPR && !prChatMessages && (<p style={{color: "grey",textAlign: "center"}}>You can ask anything related to this pull request </p>)}
        
        {!generatingResponseInProgress && isConnectedToGITHUB && selectedRepo && !selectedPR && !repoChatMessages && !prChatMessages && (<div >
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('Could you provide a detailed overview of this stack\'s components and their functionalities?');
          }}
        >
          Could you provide a detailed overview of this stack's components and their functionalities?
          </Button>
          <br />
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('What steps are required to configure and run this project locally for development and testing purposes?');
          }}
        >
          What steps are required to configure and run this project locally for development and testing purposes?
          </Button>
          <br />
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('what are examples of real-world applications or use cases for this project');
          }}
        >
                       
               what are examples of real-world applications or use cases for this project
          </Button>
              <br />
              <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('What are the best practices for contributing to this repository?');
          }}
        >
                       
                       What are the best practices for contributing to this repository?
          </Button>
              <br />   
              
        </div>)}
            
        {!generatingResponseInProgress && isConnectedToGITHUB && selectedRepo && selectedPR && !prChatMessages && (<div >
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('Review the pull request');
          }}
        >
         Review the pull request
          </Button>
          <br />
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('What specific problem or feature does this pull request address?');
          }}
        >
          What specific problem or feature does this pull request address?
          </Button>
          <br />
          <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('Can you detail the testing strategy for these changes?');
          }}
        >
                       
                       Can you detail the testing strategy for these changes?
          </Button>
              <br />
              <Button
          variant="outlined"
          style={{
            minWidth: '300px',
            marginRight: '20px',
            justifyContent: "flex-start",
            boxShadow: 'none',
            textTransform: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            paddingLeft: 8,
            paddingRight: 8,
            color: "grey", backgroundColor: 'transparent'
          }}
          startIcon={<ArrowUpwardIcon />}
          onClick={() => {
            setInputText('Provide documentation for this pull request');
          }}
        >
                       
                       Provide documentation for this pull request
          </Button>
          <br />
        </div>)}

            <div ref={textFieldDivRef} style={{ display: 'flex', marginTop: '40px' }}>
            <TextField
              fullWidth
            multiline
            rows={2}
            rowsMax={9}
            variant="outlined"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (selectedPR) {
                        handleSendPR()
                      } else {
                        handleSend()
                      }
                      
                    }}
                    disabled={!selectedRepo || generatingResponseInProgress || inputText.trim() === '' || (!currentUserData?.method && currentUserData?.availablecredits < 10)} 
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ borderRadius: 20 }} // Rounded corners
              disabled={!selectedRepo || generatingResponseInProgress || (!currentUserData?.method && currentUserData?.availablecredits < 10) }    
            />
            </div>
            {!currentUserData?.method && currentUserData?.availablecredits < 10 && <NoCreditsMessage />}

      </CardBody>
        </Card>
        {showButton && (
        <IconButton
            onClick={() => {
              textFieldDivRef.current.scrollIntoView({ behavior: 'smooth' })
              setShowButton(false)
            }}
          style={{
            position: 'fixed',
            bottom: '10%', // Adjust according to your needs
            right: '40%', // Adjust according to your needs
            zIndex: 1000, // Make sure it's above other items
            backgroundColor: 'gray', // Set background color to gray
            borderRadius: '50%', // Make it round
            color: 'white', // Optional: change the icon color to white for contrast
            padding: '10px', // Optional: adjust padding to ensure the round shape is visible if needed
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)' // Optional: add a slight shadow for better visibility
          }}
        >
          <ArrowDownwardIcon />
        </IconButton>
      )}
    </>
    
  );

  }


const agentSettings = () => {
  const chipConfig = {
    lifespan: "~30 mins",
    taskType: {
      landingPages: true,
      appDevelopment: false
    },
    goal: "Based on Prompt",
    Repository: "Github Oauth",
  };

  return (
    <>
      
      <Card>  
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <div style={{ padding: "30px", fontSize: "15px" }}>Lifespan</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>Repository Access</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>Goal</div>
           
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <div style={{ padding: "30px", fontSize: "15px" }}>{chipConfig.lifespan}</div>
              <div style={{ padding: "30px", fontSize: "15px" }}>
                GitHub Oauth
              </div>
              <div style={{ padding: "30px", fontSize: "15px" }}>{chipConfig.goal}</div>
             
            </GridItem>
          </GridContainer>
        </CardBody>
        </Card>
    </>
  );
};


  const timeCardScreen = () => {
    return (
      <>
        <div style={{ alignItems: "center", justifyContent: "center", padding: "0px", marginTop: "-50px" }}>
          <Heading
            textAlign="center"
            title="Chip"
            className={classes.buttonLink}
            style={{ padding: "0px", margin: "0px", margin: "0px!important" }}
          // category={currentUserData?.apikey}
          />
        </div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              headerColor="success"
              // customFunction={customFunctionDP}
              tabs={[
                {
                  tabName: "Agent Chat",
                  tabIcon: ChatIcon,
                  tabContent: (
                    <>
                      {agentChat()}
                    </>
                  ),
                },
                // {
                //   tabName: "Agent Logs",
                //   tabIcon: TimerIcon,
                //   tabContent: (
                //     <>
                //       {lifeCycle()}
                //     </>
                //   ),
                // },
                {
                  tabName: "Agent Configuration",
                  tabIcon: SettingsIcon,
                  tabContent: (
                    <>
                      {agentSettings()}
                    </>
                  ),
                },
                // {
                //   tabName: "Task Details",
                //   tabIcon: DashboardIcon,
                //   tabContent: (
                //     <>
                //       {agentTaskDetails()}
                //     </>
                //   ),
                // },
              ]}
            >
            </CustomTabs>
          </GridItem>
        </GridContainer>


      </>
    )
  }
  return (
    <div>
      {timeCardScreen()}
      
    </div>
  );
}
