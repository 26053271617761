
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useState, useEffect } from "react";
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components

// @material-ui/icons
// import InfoOutline from "@material-ui/icons/InfoOutline";
// core components
import Table from "components/Table/Table.js";

import TimerIcon from '@material-ui/icons/Timer';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// import { cardTitle } from "assets/jss/material-dashboard-pro-js";
// core components
import Heading from "components/Heading/Heading.js";
import Firebase from "firebase";
const SMBase64 = require('smbase64')
let base64 = new SMBase64()

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";


const useStyles = makeStyles(styles);



const fieldsForReport = [
  "Branch name",
  "Branch location",
  "Team member name",
  "Checkin Time",
  "Checkout Time",
  "Hours spent",]


export default function BirdPage() {
  const classes = useStyles();
  var user = Firebase.auth().currentUser;
  if (!user) {
    return (<Redirect from="/admin" to="/auth/login-page" />)
  }
 
  const [currentUserData, setCurrentUserData] = useState();
  const [agentLiceCycle, setAgentLiceCycle] = useState();
  

  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/webadmin/${user.email.replace(/\./g, ",")}`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setCurrentUserData(data);
        }
      });
    return () => Firebase.database().ref(`/webadmin/${user.email.replace(/\./g, ",")}`).off('value', onChildAdd);
  }, [])

  function toTitleCase(str) {
    if (!str) {
      return 'Auto';
    }
    if (typeof str !== 'string') {
      return 'Auto';
    }
    return str.toLowerCase().replace(/(^|\s)\S/g, (c) => c.toUpperCase());
  }
  function truncate(str) {
    if (!str) {
      return `Auto`;
    }
    return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
  }
  
  useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/agentactivity/${user.email.replace(/\./g, ",")}/bird/logs`)
      .limitToLast(100)
      .on('value', snapshot => {
        const data = snapshot.val()
        //console.log("====data----", data)
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];            
            if (element && element.operation ) {
              result.push([element.localdate, element.localtime, toTitleCase(truncate(element.prompt)),  toTitleCase(element.operation), truncate(element.operationdescription)])
            }
          }
          setAgentLiceCycle(result);
        }
      });
    return () => Firebase.database().ref(`/agentactivity/${user.email.replace(/\./g, ",")}/bird/logs`).off('value', onChildAdd);
  }, []) 

  const noLifeCycleEvents = () => {
    return (
      <GridContainer justify="space-between"
        style={{
          color: "grey",
          justifyContent: "center",
          height: "200px",
          display: "inline-block",
          textAlign: "center"
        }}>
        <h3>No lifecycle events found. The agent has not been initiated yet.</h3>
        <br />
        <p>
          Follow the instructions mentioned &nbsp;
          <a href="https://github.com/hribab/gpt-console#readme" target="_blank" style={{ display: 'inline-flex', alignItems: 'center' }}>
            here
            <OpenInNewIcon style={{ fontSize: '1rem', marginLeft: '4px' }} />
                  </a> 
                  &nbsp;
          to initiate the console.
        </p>
      </GridContainer>
    )
  }

  const lifeCycle = () => {
    return (<>
      <Card>
        <CardBody>
          {agentLiceCycle && agentLiceCycle.length > 0 ? (
            <Table
              tableHeaderColor="primary"
              tableHead={["Date", "Time","Task", "Activity Mode",  "Log"]}
              tableData={agentLiceCycle}
              coloredColls={[3]}
              colorsColls={["primary"]}
            />) : noLifeCycleEvents()}

        </CardBody>
      </Card>
    </>)

  }



  const agentSettings = () => {

    return (<>
    
    <Card>
        <CardBody>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <div style={{padding: "30px", fontSize: "15px"}}>Lifespan</div>
            <div style={{padding: "30px", fontSize: "15px"}}>Goal</div>
            <div style={{padding: "30px", fontSize: "15px"}}>Open Browser</div>
            <div style={{padding: "30px", fontSize: "15px"}}>Follow Operation</div>
            <div style={{padding: "30px", fontSize: "15px"}}>Tweet Operation</div>
            <div style={{padding: "30px", fontSize: "15px"}}>Reply Operation</div>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <div style={{padding: "30px", fontSize: "15px"}}>15-30 mins</div>
            <div style={{padding: "30px", fontSize: "15px"}}>Based on Prompt</div>
            <div style={{padding: "30px", fontSize: "15px"}}>true</div>
            <div style={{padding: "30px", fontSize: "15px"}}>false</div>
            <div style={{padding: "30px", fontSize: "15px"}}>true</div>
            <div style={{padding: "30px", fontSize: "15px"}}>true</div>
          </GridItem>
        </GridContainer>
        </CardBody>
      </Card>
    </>)
  }

  const timeCardScreen = () => {
    return (
      <>
        <div style={{ alignItems: "center", justifyContent: "center", padding: "0px", marginTop: "-50px" }}>
          <Heading
            textAlign="center"
            title="Bird"
            className={classes.buttonLink}
            style={{ padding: "0px", margin: "0px", margin: "0px!important" }}
          // category={currentUserData?.apikey}
          />
        </div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              headerColor="success"
              // customFunction={customFunctionDP}
              tabs={[
                {
                  tabName: "Agent Lifecycle",
                  tabIcon: TimerIcon,
                  tabContent: (
                    <>
                      {lifeCycle()}
                    </>
                  ),
                },
                {
                  tabName: "Agent Configuration",
                  tabIcon: SettingsIcon,
                  tabContent: (
                    <>
                      {agentSettings()}
                    </>
                  ),
                },
                // {
                //   tabName: "Task Details",
                //   tabIcon: DashboardIcon,
                //   tabContent: (
                //     <>
                //       {agentTaskDetails()}
                //     </>
                //   ),
                // },
              ]}
            >
            </CustomTabs>
          </GridItem>
        </GridContainer>


      </>
    )
  }
  return (
    <div>
      {timeCardScreen()}
    </div>
  );
}
